/* eslint-disable no-console */
import MainStackLayout from '../../layout/MainStackLayout';
import '../../services/api';
import { ImeiConfirmation, SubHeader, getTypeByDomain, getCarrierByDomain} from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import ActionTypes from '../../store/actionTypes.ts';
import { useCentralStore } from '../../store/appContext.tsx';
import CancelSrPopup from '../myclaims/CancelSrPopup.tsx';
import { getGTMData, getRedirectUrl } from '../../utils/utills.tsx';
import { logGTMEvent } from '../../services/api';
import { GTM_EVENT } from '../../utils/constant.tsx';

function ConfirmationUpdateDevice() {

useEffect(() => {
    let GTMData = getGTMData(GTM_EVENT.UPDATE_DEVICE_CONFIRMATION_SCREEN_EVENT, globalState?.state?.currentAgreementData,
      getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
      logGTMEvent(GTMData);
  }, [])

const ImeiConfirmationScrips = Scripts.UpdateDeviceDetails.ImeiConfirmation
  const globalState = useCentralStore();
  // const navigate = useNavigate();
  const [openCancelPopup, setOpenCancelPopup] = useState(false);

  

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };
  console.log(setLoading);

  const onCancelClick = () => {
    // setOpenCancelPopup(!openCancelPopup);
    window.location.href = getRedirectUrl(getTypeByDomain());
  };

  
  const handleSubmit = () => {
    window.location.href = getRedirectUrl(getTypeByDomain());
  }

  return (
    <Box>
      <Box
        w={{ base: 'full', lg: '80%' }}
        margin="auto"
        pb={{ base: 0, lg: '150px' }}
        id={'myasurion-profile-dashboard'}
        paddingTop={'54px'}
      >
        <MainStackLayout gap={0} spacing={0}>
          <SubHeader
            backText={''}
            allowBackButtonClick={false}
            showCancelButton={false}
            showBackButton={false}
            cancelText={Scripts.CancelSR.cancelBtnTextCancel}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            p={'10px'}
          >
            <ImeiConfirmation
              title={ImeiConfirmationScrips.title}
              descText={ImeiConfirmationScrips.descText}
              btnSubmitLBL={ImeiConfirmationScrips.btnSubmitLBL}
              onClickSubmit={() => handleSubmit()}
             />
          </Box>
          
        </MainStackLayout>
        <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
      </Box>
    </Box>
  );
}

export default ConfirmationUpdateDevice;
