import MainStackLayout from '../layout/MainStackLayout';
import {
  ConfirmDeviceKey,
  LFLStockKey,
  ResumeFlowDataKey,
  createServiceOrderDataKey,
  interactionLineKey,
  processIncidentKey,
  replacementDataKey
} from '../modules/config/constants';
import { srf } from '../types/types';
import { camelize } from '../utils/helper';
import {
  CustomerSupportFooter,
  DeviceSpecification,
  SubHeader,
  useSessionStorage,
  getTextScript,
  getCarrierByDomain,
  getTypeByDomain
} from '@MGPD/myasurion-shared';
import { Box, Divider } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import { useState } from 'react';
import { createServiceOrder, logGTMEvent } from '../services/api';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import CancelSrPopup from './myclaims/CancelSrPopup';
import Scripts from '../Scripts/asurion-script.json';
import Translationkeys from '../Scripts/translationsKeys.json';
import { getGTMData, isSwap, saveError, showErrorPage } from '../utils/utills';
import { getCurrencySymbol } from '../utils/localization-helper';
import expertLeft from '/images/carriers/asurion/expert-default-left.png';
import expertMid from '/images/carriers/asurion/expert-default-mid.png';
import expertRight from '/images/carriers/asurion/expert-default-right.png';
import expertDesktop from '/images/carriers/asurion/expert-desktop.png';
import ChatOverlay from './ChatOverlay';
import { GTM_EVENT } from '../utils/constant';

// interface serviceRequest {
//   CustomerCaseId: string;
//   CustomerCaseNumber: number;
//   CustomerCaseStatus: string;
//   ServiceRequest: {
//     ServiceRequestId: string;
//     ServiceRequestNumber: number;
//     ServiceRequestStatus: string;
//   };
// }

function DeviceSelection() {
  const globalState = useCentralStore();
  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };
  
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [confirmedDevice, setConfirmedDevice] = useState<Array<srf>>();
  const [sortedColor, setSortedColor] = useState<string>();
  const [sortedStorage, setSortedStorage] = useState<string>();
  const [selectedColor, SetSelectedColor] = useState<string>();
  const [selectedStorage, SetSelectedStorage] = useState<string>();
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [SRFText, setSRFText] = useState('');
  const [question, setQuestion] = useState<string>('');

  const srffeeStarts = globalState?.state.selectedReplacementDevice.srf.split('$')?.[1];
  const [interactionLineId] = useSessionStorage<any>(interactionLineKey, null);
  const [ReplacementData] = useSessionStorage<any>(replacementDataKey, null);
  const [processIncidentResponse] = useSessionStorage<any>(processIncidentKey, null);
  const [ProcessIncidentResponse] = useSessionStorage<any>(processIncidentKey, null);

  const [, setServiceOrderData] = useSessionStorage<any>(createServiceOrderDataKey, null);
  const [, setConfirmDeviceSession] = useSessionStorage<any>(ConfirmDeviceKey, null);
  const [, setLFLStockSession] = useSessionStorage<any>(LFLStockKey, null);
  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);

  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
  const IncidentType =
    ProcessIncidentResponse?.ServiceRequest?.IncidentType || R_ServiceRequests?.IncidentType;
  const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
  const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;

  const navigate = useNavigate();
  const enabledButton: boolean = !selectedColor || !selectedStorage ? false : true;
  let AssetFamily: string = globalState?.state.selectedReplacementDevice.familyName;
  let image: string = globalState?.state.selectedReplacementDevice.img;
  let familyDevices = ReplacementData.filter((val: any) => {
    return val?.HorizonItemInformation?.AssetFamily === AssetFamily;
  });
  let make = AssetFamily.split(' ')[0]?.toUpperCase();
  let extractAssetCapacity: any = [
    ...(new Set(
      familyDevices.map(
        (item: any) =>
          item?.SCMItemInformation?.Capacity || item?.HorizonItemInformation?.AssetCapacity
      )
    ) || ''),
  ]; //?.sort((a, b) => a-b)
  const extractAssetColor: any = [
    ...new Set(familyDevices.map((item: any) => item?.SCMItemInformation?.Color)),
  ];

  useEffect(() => {
    getCurrency();
    getSRFText();    
  })

  useEffect(() => {
    let GTMData = getGTMData(GTM_EVENT.DEVICE_SELECTION_SCREEN_EVENT, globalState?.state?.currentAgreementData,
      getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
    logGTMEvent(GTMData);
  }, [])

  useEffect(() => {
    // filter color on basis of selected storage
    const filteredColorList =
      selectedStorage &&
      familyDevices
        ?.map((val: any) => {
          if (
            (val?.SCMItemInformation?.Capacity || val?.HorizonItemInformation?.AssetCapacity) ==
            selectedStorage
          ) {
            return val?.SCMItemInformation?.Color;
          } else {
            return null;
          }
        })
        ?.filter((value: any) => value !== null);

    filteredColorList && setSortedColor(filteredColorList);

    // filter storage on basis of selected color
    const filteredStorageList =
      selectedColor &&
      familyDevices
        ?.map((val: any) => {
          if (val?.SCMItemInformation?.Color == selectedColor) {
            return val?.SCMItemInformation?.Capacity || val?.HorizonItemInformation?.AssetCapacity;
          } else {
            return null;
          }
        })
        ?.filter((value: any) => value !== null);
    filteredStorageList && setSortedStorage(filteredStorageList);

    let ConfirmedDevice =
      selectedColor &&
      selectedStorage &&
      familyDevices?.filter((val: any) => {
        return (
          val?.SCMItemInformation?.Color == selectedColor &&
          (val?.SCMItemInformation?.Capacity || val?.HorizonItemInformation?.AssetCapacity) ==
            selectedStorage
        );
      });

    setLFLStockSession(ConfirmedDevice?.[0]?.IsInStock);
    setConfirmedDevice(ConfirmedDevice);
    setConfirmDeviceSession(ConfirmedDevice);
  }, [selectedStorage, selectedColor]);

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  }

  const getSRFText = async () => {
    const SRFText = await getTextScript(
      `${Translationkeys.Global.PageName}/${Translationkeys.Global.SRFLBL}`,
      Scripts.Global.SRFLBL
    );
    setSRFText(SRFText);
  };

  const srfFees: any = confirmedDevice?.[0]?.ServiceFee;
  const  ServiceRequest  = processIncidentResponse && processIncidentResponse?.ServiceRequest;
  // const ServiceRequestId = ServiceRequest?.ServiceRequestId || R_ServiceRequestId;

  const handleStorageClick = (val: any) => {
    if (selectedColor && !sortedStorage?.includes(val)) {
      return;
    } else {
      return SetSelectedStorage(val);
    }
  };
  const handleColorClick = (val: any) => {
    if (selectedStorage && !sortedColor?.includes(val)) {
      return;
    } else {
      return SetSelectedColor(val);
    }
  };

  const onNextClick = () => {
    setLoading(true);
    // Service Order API Call
    let CreateServiceOrderParameters = {
      InteractionLineId: interactionLineId,
      ApprovedServiceFeeChange: false,
      ServiceOrder: {
        FulfillmentMethodType: 'ADVEXCH',
        ServiceRequestId: ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
        CustomerCaseId: ProcessIncidentResponse?.CustomerCaseId || R_CustomerCaseId,
        ServiceOrderLines: {
          ServiceOrderLine: [
            {
              ServiceOrderLineType: 'PHN',
              Quantity: 1,
              AssetCatalogId: confirmedDevice?.[0]?.HorizonItemInformation?.AssetCatalogItemId,
              VendorItemId: confirmedDevice?.[0]?.SCMItemInformation?.DAXItemId,
              Priority: 'MNDTRY',
              VenderItemType: confirmedDevice?.[0]?.SCMItemInformation?.ItemType,
              VendorData: {
                IsLikeForLike: false,
                IsSimCard: false,
                IsInStock: confirmedDevice?.[0]?.IsInStock,
              },
            },
          ],
        },
      },
    };
    createServiceOrder(CreateServiceOrderParameters, interactionLineId)
      .then((data) => {
        globalState?.dispatch({ type: ActionTypes.SET_SERVICE_ORDER_DATA, payload: data });
        globalState?.dispatch({ type: ActionTypes.SRF, payload: srfFees });
        setLoading(false);
        setServiceOrderData(data);
        let GTMData = getGTMData(GTM_EVENT.SERVICE_ORDER_EVENT, globalState?.state?.currentAgreementData, getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)                
        logGTMEvent(GTMData);
        if (make === 'APPLE' && isSwap(IncidentType)) {
          navigate('/fmip', { replace: true });
        } else {
          navigate('/terms', { replace: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching Service Order:', error);
        saveError(error, globalState);
        showErrorPage(navigate)
      });
  };

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  }
  
  const onBackClick = () => {
    window.history.back()
  }

  return (
    <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'} w='full'>
      <MainStackLayout gap={0} spacing={0}>
        <Box w={{ base: 'full', lg: '80%'}} margin={{ base: '0', lg: '0 auto' }}>
          <SubHeader
            onClick={onBackClick}
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={true}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}>
          </SubHeader>
        </Box>
        <Box w={{ base: 'full', lg: '80%'}} margin={{ base: '0', lg: '0 auto' }}>
          <Box id={'myasurion-profile-avatar'} w={'full'} h={'auto'} p={'0px 16px'}>
            {SRFText && 
              <DeviceSpecification
                texts={SRFText || "Service request fee"}
                textCapacity="Capacity"
                textColor="Colour"
                deviceContent={[
                  {
                    assetFamily: camelize(AssetFamily?.toLowerCase())?.replace(/iphone/gi, 'iPhone'),
                    img: image,
                    assetCapacity: sortedStorage || extractAssetCapacity,
                    color: sortedColor || extractAssetColor,
                    srf: srfFees ? `${currencySymbol}${srfFees}` : srffeeStarts ? `${currencySymbol}${srffeeStarts}` : `${currencySymbol} 0`,
                  },
                ]}
                nextButtonProps={{
                  label: 'Next',
                  onClick: onNextClick,
                }}
                selectedColor={selectedColor || ''}
                selectedStorage={selectedStorage || ''}
                enableButton={enabledButton}
                onColorClick={(val: any) => handleColorClick(val)}
                onStorageClick={(val: any) => handleStorageClick(val)}
              ></DeviceSpecification>
            }
          </Box>
          <Box mb={'100px'} display={{ base: 'block', lg: 'none' }}>
            <CustomerSupportFooter
              keywords={[
                'Like for like',
                SRFText || 'Service request fee',
                'Storage capacity',
                'Wipe my phone',
              ]}
              title="Need help?"
              onSendQuestion={(qq) => {
                setQuestion(qq);
              }}
              heading="Hey 👋 I’m Anna, your Tech specialist."
              subheading="Our team is here to help!"
              searchTitle="You might be searching for this..."
              doAnimate={true}
              images={[
                {
                  position: 'left',
                  url: expertLeft,
                  view: 'mobile',
                },
                {
                  position: 'middle',
                  url: expertMid,
                  view: 'mobile',
                },
                {
                  position: 'right',
                  url: expertRight,
                  view: 'mobile',
                },
                {
                  position: 'middle',
                  url: expertDesktop,
                  view: 'desktop',
                },
              ]}
            />
          </Box>
          <Box>
            <Divider />
          </Box>
        </Box>
      </MainStackLayout>
      <ChatOverlay question={question} onChatClose={() => setQuestion('')} />
      <CancelSrPopup
        closeCancelPopup={setOpenCancelPopup}
        openCancelPopup={openCancelPopup}/>
    </Box>
  );
}

export default DeviceSelection;
