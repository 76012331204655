import Scripts from '../../Scripts/asurion-script.json';
import AddressLineKeys from '../../Scripts/translationsKeys.json';
import { CityListData, InWarrantyDataKey, VASCityListData } from '../../modules/config/constants';
import { addressStandardize, getCitiesByPostalCode, getCityListOnLoad, getStatePostalCodeOnCity, // getCities,
getappointmentslotsApi, getaspstorelistAPi, getaspstorelistTOMAPi, getscheduledavailabilityTOMApi } from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { getAddressFields, getPostalCodeLength, getStateByCityFeature, hasQAS, hasTCAT } from '../../utils/featuresUtills';
import { Regex, getCountryCode } from '../../utils/helper';
import { isAfter4PMSlot, isDelivery, isReplacement, isSwap, saveError, showErrorPage } from '../../utils/utills';
import { StoreSelection, TextScript, getTextScript, useSessionStorage, useTextScript } from '@MGPD/myasurion-shared';
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import Geocode from 'react-geocode';
import { useNavigate } from 'react-router-dom';

import translationsKeys from '../../Scripts/translationsKeys.json';
import ChangeAddress from '../ChangeAddress';
import { AddressLinePopupProps } from '../../types/types';
import { ChangeAddressFieldConfig, AddressError } from '../../types/types.ts';

interface popupProps {
  addressPopup: AddressLinePopupProps;
  setAddressPopup: (args: AddressLinePopupProps) => void;
  srDeliveryType: any;
  isPickup: boolean;
  claimData: any;
  isWalkIn: boolean;
  setShippingMethodRes: any;
  updateWalkinDateTime?: (date: string, time: string) => void;
  callshippingmethodapi?: (address: any) => void;
  callshippingmethodV2Api?: (address: any) => void;
  callTCATAddressParseAPI?: (address: any, handleAddNewAddressFlow: any) => void;
  ServiceRequestId?: string;
  isVAS?: boolean;
}

const AddressLinePopup: React.FC<PropsWithChildren<popupProps>> = ({
  addressPopup,
  setAddressPopup,
  srDeliveryType,
  isPickup,
  claimData,
  isWalkIn,
  setShippingMethodRes,
  updateWalkinDateTime,
  callshippingmethodapi,
  callshippingmethodV2Api,
  callTCATAddressParseAPI,
  ServiceRequestId,
  isVAS = false
}) => {
  const cancelRef = useRef<any>();
  const navigate = useNavigate();
  let { isOpen } = useDisclosure();
  const globalState = useCentralStore();
  const { isTranslationsLoading, language } = useTextScript();
  const [storeList, setStoreList] = useState([]);
  const [showAddUpdateError, setShowAddUpdateError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [InWarrantyData] = useSessionStorage<any>(InWarrantyDataKey, null);
  const [statesData, setStatesData] = useState<string[]>([]);
  const [cities, setCities] = useState([]);
  const [citiesData, setCitiesData] = useSessionStorage<any>(CityListData, null);
  const [VASCitiesData, setVASCitiesData] = useSessionStorage<any>(VASCityListData, null);
  const [district, setDistrict] = useState([]);
  const [provience, setProvience] = useState<string[]>([]);
  const [headingText, setHeadingText] = useState('');
  const [headingTextWalkIn, setHeadingTextWalkIn] = useState('');

  const [loading, setLoading] = useState(false);

  const [TCATAddressError, setTCATAddressError] = useState<AddressError>({
    showError: false,
    errorMessage: ''
  });

  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;
  const agreementData1: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const serviceRequest = claimData?.ServiceRequests?.[0];
  const incidentType = claimData?.IncidentType;
  const getDeliveryType = srDeliveryType && srDeliveryType();
  const isTOM = InitializeResponse?.ClientName === 'Asurion_TechCare';
  const _locationCoordinates = globalState?.state?.locationCoordinates;
  const assetData1 = agreementData1?.Assets?.Asset?.filter(
    (a: any) => a?.AssetInstance === 'ENROLLED'
  )?.[0];
  const getStateByCityFeatureFlag :any= getStateByCityFeature(agreementData1?.ClientOffer?.ClientOfferName)
  const Make = assetData1?.Make?.Name?.toUpperCase();
  const hasDTAddOnAcquired = claimData?.IsDataTransfer;
  const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode;

  const postalCodeLength = getPostalCodeLength(agreementData1?.ClientOffer?.ClientOfferName);

  const [fields, setFields] = useState(getAddressFields(agreementData1?.ClientOffer?.ClientOfferName))

  const clearValidations = () => {
    setFields(getAddressFields(agreementData1?.ClientOffer?.ClientOfferName));
    setDistrict([]);
    setProvience([]);

    // clear TCAT error
    TCATAddressError.showError = false;
    TCATAddressError.errorMessage = "";
    setTCATAddressError(TCATAddressError)
  }

  const onClose = () => {
    setAddressPopup({open: false, addressFieldName: ""});
    setShowAddUpdateError(false);
    setAddressError(false);
    clearValidations();
  };

  isOpen = addressPopup?.open;

  function checkIfEmpty(strEntry: any) {
    if (strEntry) {
      return false;
    } else {
      return true;
    }
  }

  const getHeadingText = async(script:any, fullfillmentType:string) =>  {
    const returnedText: string = await getTextScript(
      `${translationsKeys.AddressLinePopup.PageName}/${fullfillmentType}`,
      script,
      language,
      []
    );
    setHeadingText(returnedText);
  }

  const getHeadingTextWalIn = async(script:any, IncidentType:string) =>  {
    const returnedText: string = await getTextScript(
      `${translationsKeys.AddressLinePopup.PageName}/${IncidentType}`,
      script,
      language,
      []
    );
    setHeadingTextWalkIn(returnedText);
  }

  const headerContent = () => {
    if (addressPopup?.addressFieldName === 'delivery' || isDelivery(getDeliveryType)) {
      getHeadingText(Scripts?.LogisticsScripts?.EnterNewDeliveryAddressLBL, 'DeliveryHeader');
    } else if (addressPopup?.addressFieldName === 'cleaning') {
      getHeadingText(Scripts?.RefreshLogisticsScripts?.EnterCleaningAddress, translationsKeys.AddressLinePopup.EnterCleaningAddress);
    } else if (addressPopup?.addressFieldName === 'pickUpAdd') {
      getHeadingText(Scripts?.RefreshLogisticsScripts?.EnterNewPickUpAddress, translationsKeys.AddressLinePopup.EnterNewPickUpAddress);
    } else {
      getHeadingText(Scripts?.RefreshLogisticsScripts?.EnterNewReturnAddress, translationsKeys.AddressLinePopup.EnterNewReturnAddress);
    }
    // if (isPickup && isPUR(getDeliveryType)) {
    //   getHeadingText('Enter New Pick-Up Address', 'PURHeader');
    // } else {
    //   getHeadingText('Enter New Return Address', 'defaultHeader');
    // }
  };

  const headerContentWalkIn = () => {
    if (isSwap(incidentType) || isReplacement(incidentType)) {
      getHeadingTextWalIn('Select preferred location below', 'swapReplacementHeader');
    } else {
      getHeadingTextWalIn(Scripts?.LogisticsScripts?.SelectPreferredStoreLBL, 'repairHeader');
    }
  };

  const GeoCode = (newAddress: string) => {
    Geocode.setApiKey(import.meta.env.VITE_GOOGLE_API_KEY);
    Geocode.setLanguage('en');
    Geocode.setRegion('SGP');
    Geocode.setLocationType('ROOFTOP');

    Geocode.fromAddress(newAddress).then(
      (response) => {
        const { lat, lng } = response?.results?.[0]?.geometry?.location;
        globalState?.dispatch({
          type: ActionTypes.SET_LOCATION_COORDINATES,
          payload: { lat, lng },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const callAddressStandardizeApi = (payload: any) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      addressStandardize(payload, interactionData?.InteractionLine?.InteractionLineId)
        .then((data) => {
          setLoading(false);
          if (data) {
            console.log('correctedAdd:', data);
            resolve(data);
          } else {
            reject('No data received from API');
          }
        })
        .catch((error) => {
          setShowAddUpdateError(true);
          setLoading(false);
          console.log(`error is ${error}`);
          reject(error);
        });
    });
  };

  const getAddress1 = () => {
    const addressLine1 = fields.filter((item: any) => {
      return item.mapTo === 'Address1';
    });

    return addressLine1.map((address: { value: any; }) => address.value).join(' ')
  }

  const getAddress2 = () => {
    const addressLine2 = fields.filter((item: any) => {
      return item.mapTo === 'Address2';
    });

    return addressLine2.map((address: { value: any; }) => address.value).join(' ')
  }

  const getAddress3 = () => {
    const addressLine3 = fields.filter((item: any) => {
      return item.mapTo === 'Address3';
    });

    return addressLine3.map((address: { value: any; }) => address.value).join(' ')
  }

  const getCity = () => {
    return getFieldByFieldName('District')?.value
  }

  const getStateProvinceCode = () => {
    return getFieldByFieldName('Province')?.value
  }

  const getPostalCode = () => {
    return getFieldByFieldName('PostalCode')?.value
  }

  const handleAddNewAddressFlow = async (obj: any) => {
    let newAddress = `${obj?.Address1} ${obj?.Address2} ${obj?.Address3} ${obj?.City} ${obj?.StateProvinceCode} ${obj?.PostalCode}`;
    GeoCode(newAddress);
    if (isDelivery(getDeliveryType)) {
      await globalState?.dispatch({
        type: ActionTypes.SET_DELIVERY_ADDRESS,
        payload: obj,
      });
      callshippingmethodapi && callshippingmethodapi(obj);
    } else if (isPickup) {
      globalState?.dispatch({
        type: ActionTypes.SET_PICKUP_ADDRESS,
        payload: obj,
      });
      callshippingmethodV2Api && callshippingmethodV2Api(obj)
    } else {
      globalState?.dispatch({
        type: ActionTypes.SET_RETURN_ADDRESS,
        payload: obj,
      });
    }
    onClose();
  }

  const AddNewAddressClick = async () => {
    if(!hasQAS(agreementData1?.ClientOffer?.ClientOfferName)){
      
      let obj = {
        Address1: getAddress1() ?? '',
        Address2: getAddress2() ?? '',
        Address3: getAddress3() ?? '',
        City: getCity(),
        StateProvinceCode: getStateProvinceCode(),
        CountryCode: getCountryCode(currencyCode),
        PostalCode: getPostalCode()
      }

      if(hasTCAT(agreementData1?.ClientOffer?.ClientOfferName) && callTCATAddressParseAPI){
        callTCATAddressParseAPI(obj, handleTCATAddressParseFlow);
      } else {
        handleAddNewAddressFlow(obj);
      }

    } else {
      const payloadParams = {
        QASParams: {
          ClientId: InitializeResponse?.ClientId,
          AgreementId: agreementData1?.AgreementId,
          Address: {
            Address1: getAddress1() ?? '',
            Address2: getAddress2() ?? '',
            Address3: getAddress3() ?? '',
            City: 'Singapore',
            StateProvinceCode: 'Singapore',
            CountryCode: getCountryCode(currencyCode),
            PostalCode: getPostalCode()
          },
        },
      };

      callAddressStandardizeApi(payloadParams)
        .then((data: any) => {
          if (data?.StandardizeAddressResponse) {
            const correctedAdd = data?.StandardizeAddressResponse?.AddressResult?.CorrectedAddress;
            if (correctedAdd) {
              const obj = {
                Address1: correctedAdd?.AddressLine1 || getAddress1(),
                Address2:
                  correctedAdd?.AddressLine2 || getAddress2(),
                Address3: correctedAdd?.AddressLine3,
                City: correctedAdd?.CityName || 'Singapore',
                StateProvinceCode: correctedAdd?.StateProvinceCode,
                CountryCode: correctedAdd?.CountryCode,
                PostalCode: correctedAdd?.PostalCode,
              };

              if (isDelivery(getDeliveryType)) {
                globalState?.dispatch({
                  type: ActionTypes.SET_DELIVERY_ADDRESS,
                  payload: obj,
                });
                const newAddress = `${obj?.Address1} ${obj?.Address3} ${obj?.Address2} ${obj?.PostalCode}`;
                GeoCode(newAddress);
              } else if (isPickup) {
                globalState?.dispatch({
                  type: ActionTypes.SET_PICKUP_ADDRESS,
                  payload: obj,
                });
                const newAddress = `${obj?.Address1} ${obj?.Address3} ${obj?.Address2} ${obj?.PostalCode}`;
                GeoCode(newAddress);
              } else {
                globalState?.dispatch({
                  type: ActionTypes.SET_RETURN_ADDRESS,
                  payload: obj,
                });
                const newAddress = `${obj?.Address1} ${obj?.Address3} ${obj?.Address2} ${obj?.PostalCode}`;
                GeoCode(newAddress);
              }
              onClose();
            } else {
              setAddressError(true);
            }
          } else {
            setShowAddUpdateError(true);
          }
        })
        .catch((err) => {
          setShowAddUpdateError(true);
          console.log('error:', err);
        });
    }
  };

  const handleStoreChange = (reponse: any) => {
    if (InWarrantyData && InWarrantyData?.ServiceProviderName) {
      // for IW claim show only last claim store details here
      let storeList = reponse?.filter(
        (val: any) => val.RepairStoreName === InWarrantyData?.ServiceProviderName
      );
      setStoreList(storeList);
    } else {
      setStoreList(reponse);
      if(isWalkIn && isSwap(incidentType)) callgetschedule(reponse[0]?.ServiceProviderId)
    }
  };

  useQuery(
    ['getaspstorelistData'],
    async () => {
      setLoading(true);
      if (isTOM || globalState?.state?.isMaxis) {
        return await getaspstorelistTOMAPi(
          _locationCoordinates?.lat,
          _locationCoordinates?.lng,
          Make,
          serviceRequest.ServiceRequestId,
          interactionData?.InteractionLine?.InteractionLineId
        );
      } else {
        return await getaspstorelistAPi(
          serviceRequest.ServiceRequestId,
          incidentType,
          interactionData?.InteractionLine?.InteractionLineId
        );
      }
    },
    {
      enabled: isWalkIn,
      onSuccess: (data: any) => {
        setLoading(false);
        console.log(data);
        const resp = data?.GetASPListResponse;
        handleStoreChange(resp);
      },
      onError: (error) => {
        console.error('error', error);
      },
    }
  );

  const handleOnSelect = (e: any) => {
    const storeAddress: any = storeList.filter((store: any) => store?.ServiceProviderId === e);
    globalState?.dispatch({
      type: ActionTypes.SET_DELIVERY_ADDRESS,
      payload: {
        Address1: storeAddress[0]?.AddressLine1 || '',
        Address2: '',
        Address3: '',
        AddressLine1: storeAddress[0]?.AddressLine1 || '',
        City: storeAddress[0]?.City || '',
        StateProvinceCode: storeAddress[0]?.State || '',
        CountryCode: storeAddress[0]?.Country || '',
        PostalCode: storeAddress[0]?.PostalCode || '',
        RepairStoreName: storeAddress[0]?.RepairStoreName || '',
        RepairStoreCode: storeAddress[0]?.RepairStoreCode || '',
        AssetMake: storeAddress[0]?.AssetMake || '',
        ServiceProviderId: storeAddress[0]?.ServiceProviderId || '',
      },
    });
    callgetschedule(e);
    onClose();
  };

  const callgetschedule = (storeId: any) => {
    setLoading(true);
    if (isTOM || globalState?.state?.isMaxis) {
      getscheduledavailabilityTOMApi(
        {
          GetScheduledAvailabilityReq: {
            CustomerCaseId: claimData?.CustomerCaseId,
            Department: 'AOC - TLC',
            ServiceProviderId: storeId,
            ServiceRequestId: serviceRequest.ServiceRequestId,
          },
        },
        interactionData?.InteractionLine?.InteractionLineId
      )
        .then((data) => {
          console.log(data);
          setLoading(false);
          const res = data?.availabilityData;
          const shipingMethods = res.map((item: any) => ({
            EstimatedDeliveryDate: item.date,
            DeliverySlots: item.startTime,
          }));
          if (hasDTAddOnAcquired) {
            shipingMethods.map((item: any) => {
              let slots = item?.DeliverySlots?.filter((slot: any) => {
                if (isAfter4PMSlot(slot)) return false;
                return true;
              });
              item.DeliverySlots = slots;
            });
          }
          setShippingMethodRes(shipingMethods);
          if(isWalkIn && isSwap(incidentType)){} else {
            updateWalkinDateTime && updateWalkinDateTime(
            shipingMethods?.[0]?.EstimatedDeliveryDate,
            shipingMethods?.[0]?.DeliverySlots?.[0]
          );
        }
        })
        .catch((error) => {
          setLoading(false);
          console.log(`error is ${error}`);
          saveError(error, globalState);
          showErrorPage(navigate);
        });
    } else {
      getappointmentslotsApi(
        {
          AppointmentSlotsRequest: {
            ServiceRequestId: serviceRequest.ServiceRequestId,
            ServiceProviderId: storeId,
          },
        },
        interactionData?.InteractionLine?.InteractionLineId
      )
        .then((data) => {
          setLoading(false);
          const res = data?.availabilityData;
          const shipingMethods = res.map((item: any) => ({
            EstimatedDeliveryDate: item.date,
            DeliverySlots: item.startTime,
          }));
          setShippingMethodRes(shipingMethods);
          updateWalkinDateTime && updateWalkinDateTime(
            shipingMethods?.[0]?.EstimatedDeliveryDate,
            shipingMethods?.[0]?.DeliverySlots?.[0]
          );
        })
        .catch((error) => {
          setLoading(false);
          console.log(`error is ${error}`);
        });
    }
  };

  useEffect(() => {
    if (!isTranslationsLoading) {
      headerContent();
      headerContentWalkIn();
      loadPlaceholderScripts();
    }

  }, [language, isTranslationsLoading])

  const loadPlaceholderScripts = async () => {
    let updatedFields = [];

    for (const field of fields) {

      const defaultPlaceholder = field?.placeholder;
      const defaultError = field?.errorMessage;

      const returnedText = await getTextScript(
        `${translationsKeys.Global.PageName}/${field?.name}`,
        defaultPlaceholder,
        language,
        []
      );
      
      const returnedTextError = await getTextScript(
        `${translationsKeys.Global.PageName}/${field?.name}Error`,
        defaultError,
        language,
        []
      );

      field.placeholder = returnedText;
      field.errorMessage = returnedTextError;

      updatedFields.push(field)
    }

    setFields(updatedFields);

  };

  const handleInputChange = (field: ChangeAddressFieldConfig, value: string) => {
    let isInvalid = false;
    const hasQASFeature = hasQAS(agreementData1?.ClientOffer?.ClientOfferName);

    if (field?.name === 'PostalCode' && !getStateByCityFeatureFlag) {
      if (!checkIfEmpty(value) && value?.length !== postalCodeLength) {
        isInvalid = true;
      } else if (checkIfEmpty(value)) {
        isInvalid = true;

        // clear city and state drop down if pincode empty
        if(!hasQASFeature){
          setDistrict([]);
          setProvience([]);
        }
      }

      if (!hasQASFeature && value?.length === postalCodeLength) {
        const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
        getCities(value, language);
      }

    } else if (!field?.isRequired) {
      if (!Regex.test(value)) {
        isInvalid = true;
      }
    } else {
      isInvalid = checkIfEmpty(value) || !Regex.test(value);
    }

    updateFields(field, value, isInvalid);
  };

  const getFieldByFieldName = (fieldName: string) => {
   
    return fields.find((item:any) => {
      return item.name === fieldName;
    });
    
  }

  const updateFields = (field: any, value: string, isInvalid: boolean) => {
    setFields((prevFields: any) =>
      prevFields.map((item: any) =>
        item.name === field.name
          ? {
              ...item,
              value: value,
              isInvalid: isInvalid,
            }
          : item
      )
    );
  }

  const isAnyRequiredFieldInvalid = (fields: any) => {
    for (const field of fields) {
      if (field.isRequired && field.isInvalid) {
        return true;
      }
      if (field.isRequired && (field.value === null || field.value === '')) {
        return true;
      }
      // if (formFields.District === '' || formFields.Province === '') {
      //   return true;
      // }
    }
    return false;
  };

  useEffect(() => {
    if (isVAS ? !VASCitiesData?.length : !citiesData?.length) {

      const serviceRequestId = serviceRequest?.ServiceRequestId || ServiceRequestId;
      const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
      getCitiesOnLoad(serviceRequestId, language);
    } else 
      setDistrict(isVAS ? VASCitiesData : citiesData)

  }, [agreementData1]);

  useEffect(() => {
    const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
    const postalCodeField = getFieldByFieldName("PostalCode");
    const districtField = getFieldByFieldName("District");
    const languageSuffix = language === 'en-US' ? '' : 'TL';

    if (
      postalCodeField?.value?.length === postalCodeLength &&
      districtField?.value &&
      !getStateByCityFeatureFlag
    ) {
      const filterCondition = (val: any) => val.PostalCode === postalCodeField?.value;

      const filteredStates = cities
        ?.filter(filterCondition)
        .filter((val: any) => val[`City${languageSuffix}`] === districtField?.value)
        .map((val: any) => val[`State${languageSuffix}`]);

      if(!filteredStates?.length){
        const field = getFieldByFieldName('PostalCode')
        updateFields(field, "", true)
      } else {
        const field = getFieldByFieldName('Province')
        updateFields(field, filteredStates[0], field?.isInvalid)
      }
      setProvience(filteredStates);
    }
  }, [district]);

  const getCities = (postalCode: string, language: string) => {

    setLoading(true);

    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const clientID = globalState?.state?.sessionResponse?.InitializeResponse?.ClientId;

    getCitiesByPostalCode(interactionLineId, postalCode, language, clientID).then((data)=> {
      setLoading(false);
      const citiesData = data?.GetCityStatebyZipCodeResponse;

      setCities(citiesData);

      const languageSuffix = language === 'en-US' ? '' : 'TL';
      const filterCondition = (val: any) => val.PostalCode ===postalCode;

      const filteredCities = citiesData?.filter(filterCondition).map((v: any) => v[`City${languageSuffix}`]);
      // const filteredStates = citiesData
      //   ?.filter(filterCondition)
      //   .filter((val: any) => val[`City${languageSuffix}`] === filteredCities[0])
      //   .map((val: any) => val[`State${languageSuffix}`]);

      if(!filteredCities?.length){
        const field = getFieldByFieldName('PostalCode')
        updateFields(field, "", true)
      } else {
        const postalCodeField = getFieldByFieldName("District");
        updateFields(postalCodeField, filteredCities[0], postalCodeField?.isInvalid);
      }

      setDistrict(filteredCities);
      setProvience([]);
      
    }).catch((error) => {
      setLoading(false);
      saveError(error, globalState);
      showErrorPage(navigate)
    })
  }

  const getCitiesOnLoad = (serviceRequestId: string, language: string) => {
    setLoading(true);
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const clientID = globalState?.state?.sessionResponse?.InitializeResponse?.ClientId;

    getCityListOnLoad(interactionLineId, serviceRequestId, language, clientID)
      .then((data:any) => {
        setLoading(false);
        const citiesData = data?.GetCityStatebyZipCodeResponse;
        setCities(citiesData);
        const languageSuffix = language === 'en-US' ? '' : 'TL';
        const filteredCities: any = citiesData?.map((v: any) => v[`City${languageSuffix}`]);

        if(isVAS)
          setVASCitiesData(filteredCities)
        else 
          setCitiesData(filteredCities);

        setDistrict(filteredCities);
     
      })
      .catch((error: any) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  //Approach 2 ---> get State and Postal Code on City Slected.

  const getPostalCodeStateOnCity = (city: string, language: string) => {

    setLoading(true);
    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const clientID = globalState?.state?.sessionResponse?.InitializeResponse?.ClientId;
    const serviceRequestId = serviceRequest?.ServiceRequestId || ServiceRequestId;

    getStatePostalCodeOnCity(interactionLineId, city, language, clientID, serviceRequestId).then((data)=> {
      setLoading(false);
      const StateData = data?.GetCityStatebyZipCodeResponse;
      const languageSuffix = language === 'en-US' ? '' : 'TL';
      const filteredStates = StateData?.map((val: any) => val[`State${languageSuffix}`]);
      
        setProvience(filteredStates);
        setStatesData(StateData)
      }
      ).catch((error :any) => {
      setLoading(false);
      saveError(error, globalState);
      showErrorPage(navigate)
    })
  }


  const handleSelectChange = (event:any, fieldName:any) => {
    const selectedValue = event.target.value;
    if (getStateByCityFeatureFlag) {
      if (fieldName === 'District') {
        if (selectedValue !== '') {
          let field = getFieldByFieldName(fieldName);
          updateFields(field, selectedValue, field?.isInvalid);
          const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
          getPostalCodeStateOnCity(selectedValue, language);
          const PostalCodeField = getFieldByFieldName('PostalCode');
          updateFields(PostalCodeField, '', PostalCodeField?.isInvalid);
        }
      } else if (fieldName === 'Province') {
        if (selectedValue !== '') {
          const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
          const languageSuffix = language === 'en-US' ? '' : 'TL';
          let field = getFieldByFieldName(fieldName);
          updateFields(field, selectedValue, field?.isInvalid); //set State/Province
          let selectedProvinceObj: any = statesData?.find((s: any) => {
            return s[`State${languageSuffix}`] === selectedValue;
          });
          const PostalCodeField = getFieldByFieldName('PostalCode');
          updateFields(PostalCodeField, selectedProvinceObj.PostalCode, PostalCodeField?.isInvalid); //set PostalCode
        }
      }
    } else {
      let field = getFieldByFieldName(fieldName);
      updateFields(field, selectedValue, field?.isInvalid);
    }
  };

  const handleTCATAddressParseFlow = (data: any, address: any) => {
    if (data && data.IsOK?.toUpperCase() === "Y") {
      TCATAddressError.showError = false;
      TCATAddressError.errorMessage = data?.Message
      setTCATAddressError(TCATAddressError)

      handleAddNewAddressFlow(address);
    } else {
      TCATAddressError.showError = true;
      TCATAddressError.errorMessage = data?.Message || 'address error'
      setTCATAddressError(TCATAddressError)
    }
  }

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => onClose()}
        isOpen={isOpen}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent margin={'12px'}>
          <AlertDialogHeader></AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody
            paddingX={'12px'}
            paddingBottom={'12px'}
            as={'b'}
            fontSize={'18px'}
            maxHeight="570px"
            overflowY="auto"
          >
            {isWalkIn ? (
              <Box>
                <Text as="b" fontSize={'md'}>
                 {headingTextWalkIn}
                </Text>
                <Box pt={2}>
                  <StoreSelection ASPStoreList={storeList} onSelect={handleOnSelect} />
                </Box>
              </Box>
            ) : (
              <Box>
                <>
                  <ChangeAddress
                    newAddressQuestion={headingText || ''}
                    fields={fields}
                    handleInputChange={handleInputChange}
                    selectedProvince={getFieldByFieldName('Province')?.value}
                    selectedDistrict={getFieldByFieldName('District')?.value}
                    provience={provience}
                    district={district}
                    handleSelectChange={handleSelectChange}
                    addressError={TCATAddressError}
                    programmeName={agreementData1?.ClientOffer?.ClientOfferName}
                  />

                </>
                {(showAddUpdateError || addressError) && (
                  <Box fontSize={'sm'} px={4} pb={1} textColor={'red'}>
                    {showAddUpdateError && (
                      <TextScript
                        text={'*Error in updating the address'}
                        translationKey={`${AddressLineKeys.AddressLine.PageName}/updateAddressErrorMsg`}
                      />
                    )}

                    {addressError && (
                      <TextScript
                        text={'*Please enter valid address'}
                        translationKey={`${AddressLineKeys.AddressLine.PageName}/invalidAddressErrorMsg`}
                      />
                    )}
                  </Box>
                )}
                <Flex mx={'auto'}>
                  <Button
                    isLoading={loading}
                    py={3}
                    px={5}
                    id={'addressConfirm'}
                    colorScheme="primary"
                    color="white"
                    mx={'auto'}
                    onClick={AddNewAddressClick}
                    isDisabled={isAnyRequiredFieldInvalid(fields)}
                  >
                    <TextScript
                      text={Scripts?.LogisticsScripts?.AddNewAddress}
                      translationKey={`${AddressLineKeys.AddressLine.PageName}/${AddressLineKeys.AddressLine.btnAddNewAddress}`}
                    />
                  </Button>
                </Flex>
              </Box>
            )}
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddressLinePopup;
