import PaymentScript from '../../Scripts/asurion-script.json';
import PaymentKeys from '../../Scripts/translationsKeys.json';
import {
  BGTokenKey,
  ResumeFlowDataKey,
  assetDataKey,
  btChargeOrderKey,
  btProcessPaymentKey,
  btSubmitOrderKey,
  deliveryTypeKey,
  processIncidentKey,
  serviceFeeKey,
  storeIdKey,
} from '../../modules/config/constants';
import {
  chargeOrderApi,
  getSecurityTokenApi,
  processPaymentApi,
  submitOrderApi,
} from '../../services/api';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { getFeeInclusiveTaxText } from '../../utils/helper';
import { getCurrencySymbol } from '../../utils/localization-helper'
import {
  callLoggerApi,
  convertDateToUTC,
  isBatteryReplacement,
  isPUR,
  isWalkIn,
  saveError,
  showErrorPage,
} from '../../utils/utills';
import {
  Creditcard,
  TextScript,
  useAccount,
  useConfig,
  useSessionStorage,
  getTextScript
} from '@MGPD/myasurion-shared';
import { Env, PaymentClient } from '@backoffice/fast-payments-client-js-sdk';
import { Box, Button, Flex, Link, RadioGroup, Text } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { headers } from '../../services/headers';
import PaymentOptions from './PaymentOptions';
import PaymentOptionPopup from '../myclaims/PaymentOptionPopup';

const defaultCCFields = {
  cardNumber: '',
  expDate: '',
  cvv: '',
  name: '',
};

function IngenicoPayment() {
  const globalState = useCentralStore();
  const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode; 
  const [isOpen, setIsOpen]  = useState<boolean>(false);   
  const [paymentOptions, ] = useState<any>([ PaymentScript?.Payment?.ccOption, PaymentScript?.Payment?.codOption]) 
  const [paymentError, setPaymentError] = useState<string>('');  
  const [isOpenPayment, setOpenPayment] = useState<boolean>(false);
  const SlectedPaymentMethod = globalState?.state?.selectedPaymentMethod;
  const [account] = useAccount({ firstname: 'firstName', lastname: 'lastName', email: 'email' });
  const [CCFields, setCCFields] = useState<any>(defaultCCFields);
  const [CCValidations, setCCValidations] = useState({
    isInvalidInputCCNumberValue: false,
    isInvalidInputExpiryValue: false,
    isInvalidInputCVVValue: false,
    isInvalidInputCCNameValue: false,
  });
  const [placeholders, setPlaceholders] = useState({
    CreditCardNumber: '',
    ExpirationDate: '',
    CreditCardName: '',
    cvvNumber: '',
    CreditCardNumberError: '',
    ExpirationDateError: '',
    CreditCardNameError: '',
    cvvNumberError: '',
  });
  const [currencySymbol, setCurrencySymbol] = useState('')
  
  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const [securityTokenData, setSecurityTokenData] = useSessionStorage<any>(BGTokenKey, null);
  const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
  const [assetData] = useSessionStorage<any>(assetDataKey, null);
  const [, setSubmitOrderResponse] = useSessionStorage<any>(btSubmitOrderKey, null);
  const [, setChargeOrderResponse] = useSessionStorage<any>(btChargeOrderKey, null);
  const [, setProcessPaymentResponse] = useSessionStorage<any>(btProcessPaymentKey, null);
  const [srDeliveryType] = useSessionStorage<any>(deliveryTypeKey, null);
  const [StoreId] = useSessionStorage<any>(storeIdKey, null);
  const [walkingDate] = useSessionStorage<any>('storeDate', null);
  const [walkingTime] = useSessionStorage<any>('storeTime', null);
  const agreementData: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

  const _locationCoordinates = globalState?.state?.locationCoordinates;

  const isPickup = isPUR(srDeliveryType);

  useEffect(() => {
    setCCFields(defaultCCFields);
    loadPlaceholderScripts();
  }, []);

  const showLoader = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };
  const sdkLibLoader = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.SDK_LIB_LOADER, payload: val });
  };

  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
  const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
  const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;
  const R_ServiceOrderId = R_ServiceRequests?.ServiceOrder?.ServiceOrderId;
  const R_ServiceOrderNumber = R_ServiceRequests?.ServiceOrder?.ServiceOrderNumber;
  const R_IncidentType = R_ServiceRequests?.IncidentType;

  const [ServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
  const LFLSRFFees =
    ServiceFeeData &&
    ServiceFeeData?.filter((item: any) => item?.IncidentType === R_IncidentType?.toUpperCase())?.[0]
      ?.Fees?.TotalAmount;
  
  const initializeData = globalState?.state?.sessionResponse?.InitializeResponse;
  const interactionData = globalState?.state?.sessionResponse?.Interaction;

  const IncidentType = serviceRequestData?.ServiceRequest?.IncidentType || R_IncidentType;
  const isRepair =
    IncidentType?.toLowerCase() === 'fault repair' ||
    IncidentType?.toLowerCase() === 'device repair';

  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary } = colors;

  const assetData1 = agreementData?.Assets?.Asset?.filter(
    (a: any) => a?.AssetInstance === 'ENROLLED'
  )?.[0];

  const _ServiceOrderId =
    globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderId ||
    R_ServiceOrderId;
  const _ServiceOrderNumber =
    globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderNumber ||
    R_ServiceOrderNumber;
  const incType =
    globalState?.state?.processIncData?.ServiceRequest?.IncidentType || R_IncidentType;

  const processIncData = globalState?.state?.processIncData;

  const R_SRF =
    ResumeFlowData?.ServiceFee?.ServiceFee &&
    parseFloat(ResumeFlowData?.ServiceFee?.ServiceFee)?.toFixed(2);

  const isAnyFieldEmptyOrInvalid = (fields: any, invalid: any) => {
    let invalidValues = Object.values(invalid);
    for (const fieldName in fields) {
      if (fields[fieldName] === '' || !invalidValues.every((v: any) => v === false)) {
        return false;
      }
    }
    return true;
  };
  const enableButton = isAnyFieldEmptyOrInvalid(CCFields, CCValidations);

  const [totalSRFee, setTotalSRFee] = useState<any>(globalState?.state?.srf || R_SRF);

  useEffect(() => {
    if (globalState?.state?.chargeNightDeliveryFee) {
      setTotalSRFee(Number(globalState?.state?.nightDeliveryFee));
    } else {
      setTotalSRFee(
        (
          Number(globalState?.state?.srf || R_SRF || LFLSRFFees) +
          Number(globalState?.state?.nightDeliveryFee) +
          Number(globalState?.state?.doorStepAddOnData.fee)
        )?.toFixed(2)
      );
    }
  }, [
    globalState?.state?.nightDeliveryFee,
    R_SRF,
    LFLSRFFees,
    globalState?.state?.doorStepAddOnData.fee,
  ]);

  const [] = useSessionStorage<any>(btChargeOrderKey, null);

  const navigate = useNavigate();

  function generateUniqueId() {
    return uuid();
  }

  const callGetSecurityToken = () => {
    let GetSecurityTokenRequest = {
      UseRecurringBillingProgram: true,
      version: '4.5',
      Culture: 'th-TH',
      CurrencyCode: 'THB',
      FirstName: account?.firstname,
      LastName: account?.lastname,
      Phone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
      Email: account?.email,
      InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
      AgreementRequestId: undefined,
      AgreementId: agreementData?.AgreementId,
      BillingProfileId: generateUniqueId().replace(/-/g, '')?.toUpperCase(),
      FeeType: 'SWAPFEE',
    };
    showLoader(true);
    getSecurityTokenApi(
      { GetSecurityTokenRequest },
      interactionData?.InteractionLine?.InteractionLineId
    )
      .then((data) => {
        setSecurityTokenData(data?.GetSecurityTokenResponse);      
        showLoader(false);
      })
      .catch((error) => {
        showLoader(false);
        setPaymentError('Token failed!');
        saveError(error, globalState);
      });
  };

 
  const encryptCreditCard = async () => {
    const { encryptionKey, token } = securityTokenData;
    const ReceiverAddress = agreementData?.Address;

    const env = import.meta.env.VITE_ENV === 'prod' ? Env["prod-apac"] : Env['qa-apac'];
    const paymentClient = new PaymentClient({ env: env });
    showLoader(true);
    sdkLibLoader(true);

    const handleError = (error:any) => {
      callLoggerApi(error, globalState);
      saveError(error, globalState);
      setPaymentError(error.message);
      showLoader(false);
      return Promise.reject(error);
    };

    return Promise.all([
      paymentClient.addSession(token, {
        appName: 'ENRPORTAL',
        encryptionKey: encryptionKey,
        currency: 'THB',
      }),
      await paymentClient.addCreditCardInfo({
        number: CCFields.cardNumber,
        expiration: {
          year: CCFields.expDate.split('/')[1],
          month: CCFields.expDate.split('/')[0],
        },
        securityCode: CCFields.cvv,
      })
      .catch(handleError),
      await paymentClient
        .addBillingContactInfo({
          name: {
            first: CCFields.name.split(' ')?.[0],
            last: CCFields.name.split(' ')?.[1] || CCFields.name.split(' ')?.[0],
          },
          address: {
            country: ReceiverAddress?.CountryCode,
            zip: ReceiverAddress?.PostalCode,
          },
        })
        .catch(handleError),
    ])
      .then(() => {
        showLoader(true);
        return paymentClient.processPayment()
        .catch(handleError)
      })
      .then(() => {
        sdkLibLoader(false);
        showLoader(false);
        callChargOrder('');
      })
      .catch(() => {
        handleError
        sdkLibLoader(false);
      });
  };

  // useEffect(() => {
  //   const sdkLibFailure = globalState?.state?.sdkLibFailure;
  //   if (sdkLibFailure) {
  //     switch (sdkLibFailure) {
  //       case 'BG-1002':
  //         setPaymentError('Invalid expiration date.');
  //         break;
  //       case 'BG-1015':
  //         setPaymentError(
  //           'Payment was declined due to insufficient funds or restriction on the card. Please choose a different payment card.'
  //         );
  //         break;
  //       case 'BG-1000':
  //         setPaymentError('Transaction declined by card processor or issuing bank.');
  //         break;
  //       default:
  //         setPaymentError('Authentication Failed');
  //         break;
  //     }
  //   }
  // }, [globalState?.state?.sdkLibFailure]);

  const ChargeOrder = (paymentMethod: string) => {
    if (paymentMethod === PaymentScript?.Payment?.codOption) {
      return {
        PaymentMethodType: 'COD',
        ChargeOrderStatus: 'PREAUTH',
        PCIToken: '',
        CardHolderName: '',
      };
    } else {
      return {
        PaymentMethodType: 'CRE',
        ChargeOrderStatus: 'PREAUTH',
        AddressId: agreementData?.billingAddress?.AddressId,
        AdditionalChargeAuth: 'false',
        PNRAmount: null,
        IncidentType: 'Swap',
        ChargeOrderCardBrand: 'visa',
        ChargeOrderCardType: 'CREDIT',
        CardCheckNumber: 123,
        CardHolderFirstName: '',
        CardHolderLastName: '',
      };
    }
  };

  const callCreateRepairRequest = async () => {
    showLoader(true);
    let CreateRepairRequestParams = {
      InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
      CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
      ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
      ServiceOrderId: _ServiceOrderId,
      RepairRequestType: isPickup ? 'PUR' : 'WALKIN',
      ServiceProviderId: isWalkIn(srDeliveryType) ? StoreId : undefined,
      AssetMake: 'APPLE',
      Longitude: _locationCoordinates?.lng,
      Lattitude: _locationCoordinates?.lat,
      AppointmentDate: isWalkIn(srDeliveryType)
        ? convertDateToUTC(
            `${walkingDate} ${walkingTime}`,
            'YYYY-MM-DD HH:mm:ss',
            initializeData?.TimeZone
          )
        : globalState?.state?.pickupDate,
    };
    try {
      const repairrequestResponse = await axios.post(
        `/swap/claim/api/repairrequest/create`,
        { CreateRepairRequestParams },
        {
          headers: {
            ...headers,
            'Asurion-interactionlineid': interactionData?.InteractionLine.InteractionLineId,
          },
        }
      );
      console.log('repairrequest repairrequestResponse', repairrequestResponse);
      callSubmitOrder();
    } catch (error) {
      showLoader(false);
      saveError(error, globalState);
      showErrorPage(navigate);
    }
  };

  const CreateChargeOrderParameters = (paymentMethod: string) => {
    let CreateChargeOrderParameters: any = {
      InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
      ClientId: interactionData?.ClientId,
      ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
      AgreementId: agreementData?.AgreementId,
      ServiceRequestId: serviceRequestData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
      CustomerCaseId: serviceRequestData?.CustomerCaseId || R_CustomerCaseId,
      AssetCategoryId: null,
      AssetMakeId: null,
      Transactionid: generateUniqueId().replace(/-/g, '')?.toUpperCase(),
      MobileNumber: assetData?.MobileDeviceNumber,
      ChargeOrder: ChargeOrder(paymentMethod),
    };
    if (globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.ccOption) {
      CreateChargeOrderParameters['BillingProfileId'] = generateUniqueId()
        .replace(/-/g, '')
        ?.toUpperCase();
      CreateChargeOrderParameters['CustomerCaseNumber'] =
        processIncData?.CustomerCaseNumber || ResumeFlowData?.CustomerCaseNumber;
      CreateChargeOrderParameters.ClientOfferId = agreementData?.ClientOffer?.ClientOfferId;
      CreateChargeOrderParameters.ServiceOrderId = _ServiceOrderId;
      CreateChargeOrderParameters.Transactionid = securityTokenData?.token;
    }
    return CreateChargeOrderParameters;
  };

  const callChargOrder = (paymentMethod: string) => {
    showLoader(true);
    const payload = CreateChargeOrderParameters(paymentMethod);
    chargeOrderApi(
      { CreateChargeOrderParameters: payload, ServiceOrderId: _ServiceOrderId },
      _ServiceOrderId,
      interactionData?.InteractionLine?.InteractionLineId
    )
      .then((data) => {
        showLoader(false);
        const ChargeOrderResponse = data?.CreateChargeOrderResults?.ChargeOrder;
        setChargeOrderResponse(ChargeOrderResponse);
        if (isRepair) {
          callCreateRepairRequest();
        } else {
          callProcessPayment(ChargeOrderResponse, ChargeOrder('')?.PaymentMethodType, ChargeOrder);
        }
      })
      .catch((err) => {
        showLoader(false);
        setPaymentError('Payment failed');
        saveError(err, globalState);
        showErrorPage(navigate);
      });
  };

  const getPaymentInfo = () => {
    if (globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.codOption) {
      return {
        PaymentMethodType: 'COD',
      };
    } else {
      return {
        PaymentMethodType: 'CRE',
        PCIToken: '',
        CardType: 'CREDIT',
        CardBrand: 'VISA',
        CardCheckNumber: '123',
        AccountHolder: {
          FirstName: 'firstname',
          LastName: 'lastname',
          CompanyName: '',
          IdentificationType: 'NATIONALID',
          IdentificationNumber: '',
        },
      };
    }
  };

  const callProcessPayment = (ChargeOrder: any, _payment_method: any, _chargOrderPayload: any) => {
    const paymentInformation = getPaymentInfo();
    const ProcessPaymentParameters: any = {
      ChargeOrderId: ChargeOrder?.ChargeOrderId,
      ServiceOrderId: _ServiceOrderId,
      ApplicationId: initializeData?.ApplicationId,
      ClientId: initializeData?.ClientId,
      InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
      ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
      ServiceOrderNumber: _ServiceOrderNumber?.toString(),
      AuthorizeAdditionalCharges: false,
      ValidateAddress: false,
      ValidateCardSecurityCode: false,
      IpAddressForFraud: interactionData?.SourceIpAddress,
      FulfillmentOption: 'RPLCMNT',
      PaymentInformation: paymentInformation,
      Address: agreementData?.Address,
      skipBillingProfile: true,
      ContactDetails: {
        phone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
        workPhone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
        cellPhone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
        email: account?.email,
      },
    };
    showLoader(true);
    processPaymentApi(
      { ProcessPaymentParameters },
      _ServiceOrderId,
      ChargeOrder?.ChargeOrderId,
      interactionData?.InteractionLine?.InteractionLineId
    )
      .then((response) => {
        setProcessPaymentResponse(response?.ProcessPaymentResults);
        callSubmitOrder();
      })
      .catch((error) => {
        showLoader(false);
        setPaymentError('Payment failed');
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  const callSubmitOrder = async () => {
    showLoader(true);
    const SubmitServiceOrderParameters = {
      ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
      InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
      ServiceOrderId: _ServiceOrderId,
    };
    // navigate to final page and then call submit API in background
    showLoader(false);
    submitOrderApi({ SubmitServiceOrderParameters })
      .then((response: any) => {
        navigate('/claimconfirmation', { replace: true });
        console.error('inner try submitorder');
        setSubmitOrderResponse(response?.data?.SubmitServiceOrderResults);
      })
      .catch((error) => {
        console.error('inner catch', error);
        callLoggerApi(error, globalState);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  const handleChange = (e: any) => {
    setPaymentError('');        
    globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_METHOD, payload: e });    
    if (e === PaymentScript?.Payment?.codOption) {
      setIsOpen(true)           
    } else if (e === PaymentScript?.Payment?.ccOption && !securityTokenData) {   
      globalState?.dispatch({ type: ActionTypes.SHOW_PAYMENT_OPTIONS, payload: false });   
      callGetSecurityToken();
    } else {
      globalState?.dispatch({ type: ActionTypes.SHOW_PAYMENT_OPTIONS, payload: false });
      return ;
    }
  };

  const PaymentTotal = () => {
    return (
      <Box mt="10px" w={{ base: 'full', lg: '50%' }}>
        <hr />
        <Text my={4} fontWeight={700} fontSize={'18px'}>
          <TextScript
            text={PaymentScript?.Payment?.paymentBreakdown}
            translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.PaymentBreakdown}`}
          />
        </Text>
        {!globalState?.state?.chargeNightDeliveryFee && (
          <Flex mb={3} direction="row" justifyContent="space-between">
            {isBatteryReplacement(incType) && (
              <Text>
                <TextScript
                  text={PaymentScript.Payment.srf}
                  translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.srf}`}
                  dynamicValues={['Battery Replacement']}
                />
              </Text>
            )}
            {!isBatteryReplacement(incType) && (
              <Text>
                <TextScript
                  text={PaymentScript.Payment.srf}
                  translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.srf}`}
                  dynamicValues={['Service Request']}
                />
              </Text>
            )}
            <Text>${globalState?.state?.srf || R_SRF || Number(LFLSRFFees)?.toFixed(2)}</Text>
          </Flex>
        )}
        <hr
          style={{
            border: 'none',
            borderTop: '2px dotted #adaaaa',
          }}
        />
        <Flex my={2} direction="row" justifyContent="space-between">
          <Text>
            <TextScript
              text={PaymentScript?.Global?.Subtotal}
              translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.Subtotal}`}
            />
          </Text>
          <Text>            
            {`${currencySymbol}${totalSRFee}`}
          </Text>
        </Flex>
        <Flex mb={2} direction="row" justifyContent="space-between">
          <Text fontWeight={700}>
            <TextScript
              text={PaymentScript?.Global?.Total}
              translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.Total}`}
            />
          </Text>
          <Text fontWeight={700}>
          {`${currencySymbol}${totalSRFee}`}
          </Text>
        </Flex>
      </Box>
    );
  };

  const loadPlaceholderScripts = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
    const placeholdersData: any = {};
    const fieldNames = [
      'CreditCardNumber',
      'ExpirationDate',
      'CreditCardName',
      'cvvNumber',
    ];

    for (const fieldName of fieldNames) {
      const defaultPlaceholder = getDefaultPlaceholder(fieldName);
      const defaultError = getDefaultErrorMsgs(fieldName);

      const returnedText = await getTextScript(
        `${PaymentKeys.Payment.PageName}/${fieldName}`,
        defaultPlaceholder
      );
      const returnedTextError = await getTextScript(
        `${PaymentKeys.Payment.PageName}/${fieldName}Error`,
        defaultError
      );
      const errorKey = `${fieldName}Error`;
      placeholdersData[fieldName] = returnedText;
      placeholdersData[errorKey] = returnedTextError;
    }

    setPlaceholders(placeholdersData);
  };

  const getDefaultPlaceholder = (fieldName: string) => {
    switch (fieldName) {
      case 'CreditCardNumber':
        return 'Credit card number';
      case 'ExpirationDate':
        return 'Expiration date';
      case 'CreditCardName':
        return 'Credit card name';
      case 'cvvNumber':
        return 'CVV';
      default:
        return `${fieldName}`;
    }
  };

  const getDefaultErrorMsgs = (fieldName: string) => {
    switch (fieldName) {
      case 'CreditCardNumber':
        return 'Invalid card number';
      case 'ExpirationDate':
        return 'Invalid expiry date';
      case 'CreditCardName':
        return 'Invalid card name';
      case 'cvvNumber':
        return 'Invalid CVV';
      default:
        return `Invalid ${fieldName}`;
    }
  };

  const handleYesClick = () => { 
    setIsOpen(false)    
    callChargOrder(SlectedPaymentMethod || '')    
    }

  return (
    <>
      <Flex
        position="relative"
        pb={isOpenPayment ? '320px' : '110px'}
        direction="column"
        w={{ base: 'full', lg: globalState?.state?.selectedPaymentMethod === '' ? '50%' : '70%' }}
        margin={'auto'}
      >
        {(SlectedPaymentMethod === '' || SlectedPaymentMethod === PaymentScript?.Payment?.codOption)&& (          
          <Text fontSize="32px" mb={'16px'} textAlign={{ base: 'start', lg: 'center' }}>
            <TextScript
              text={PaymentScript?.Payment?.paymentOptionHeading}
              translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.PaymentOptionHeading}`}
            />
          </Text>
        )}

        {SlectedPaymentMethod && SlectedPaymentMethod === PaymentScript?.Payment?.ccOption && (
          <Text fontSize={'2xl'}>
            <TextScript
              text={PaymentScript?.Payment?.paymentHeading}
              translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.PaymentHeading}`}
            />
          </Text>
        )}
        <RadioGroup
          onChange={(e) => {
            handleChange(e);            
          }}
          onClick={() =>  SlectedPaymentMethod === PaymentScript?.Payment?.codOption ? handleChange(SlectedPaymentMethod): null}
          value={SlectedPaymentMethod}
          alignSelf={SlectedPaymentMethod === '' ? 'center' : ''}
        >
          { globalState?.state?.showoptions && paymentOptions.map((option: any) => {
            return <PaymentOptions optn={option} />
          })}
          
          {SlectedPaymentMethod === PaymentScript?.Payment?.ccOption && (
            <Box>
              <Creditcard
                CCNumber={{
                  placeholder: placeholders.CreditCardNumber,
                  key: 'CreditCardNumber',
                  errormsg: placeholders.CreditCardNumberError,
                }}
                ExpiryDate={{
                  placeholder: placeholders.ExpirationDate,
                  key: 'ExpirationDate',
                  errormsg: placeholders.ExpirationDateError,
                }}
                inputCVV={{
                  placeholder: placeholders.cvvNumber,
                  key: 'cvvNumber',
                  errormsg: placeholders.cvvNumberError,
                }}
                CCName={{
                  placeholder: placeholders.CreditCardName,
                  key: 'CreditCardName',
                  errormsg: placeholders.CreditCardNameError,
                }}
                getAllCCdata={(x, y) => {
                  setCCFields(y);
                  setCCValidations(x);
                }}
              ></Creditcard>
            </Box>
          )}
                    
            { globalState?.state.showoptions && SlectedPaymentMethod === PaymentScript.Payment.codOption && 
            <PaymentOptionPopup onYesClick={() => handleYesClick()} openPopup={isOpen} closePopup={() => setIsOpen(false)}/>}
        </RadioGroup>

        {SlectedPaymentMethod && (
          <Box>
            <Text color={'red'}>{paymentError}</Text>
          </Box>
        )}
      </Flex>
      <Box
        mt={3}
        py={4}
        position="fixed"
        bottom="0"
        w={'100%'}
        bg={'#fff'}
        style={{
          boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.08)',
          transform: 'translate(-50%, 0%)',
        }}
        left={'50%'}
      >
        <Flex
          direction="column"
          w={{ base: '100%', lg: '69%' }}
          paddingX={'10px'}
          marginX={{ base: '0', lg: 'auto' }}
        >
          <Flex direction="row" justifyContent="space-between">
            {SlectedPaymentMethod !== '' && SlectedPaymentMethod !== PaymentScript?.Payment?.codOption && (
              <>
                <Flex alignItems={'end'}>
                  <Box>
                    <Text fontWeight={700} fontSize={'14px'}>
                      <TextScript
                        text={`${PaymentScript?.Payment?.totalFee} ${getFeeInclusiveTaxText(
                          currencyCode
                        )}`}
                        translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.TotalFeeincGST}`}
                      />
                    </Text>
                    <Text fontSize={'24px'} fontWeight={700}>
                    {`${currencySymbol}${totalSRFee}`}
                    </Text>
                  </Box>
                  {globalState?.state?.selectedPaymentMethod !== '' && (
                    <Link
                      fontSize={'14px'}
                      color={primary}
                      textDecoration={'underline'}
                      onClick={() => setOpenPayment(!isOpenPayment)}
                      pl={'10px'}
                      pb={2}
                    >
                      {isOpenPayment && (
                        <Text>
                          <TextScript
                            text={PaymentScript?.Payment?.lblBreakdown}
                            translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.breakdown}`}
                            dynamicValues={[PaymentScript?.Payment?.closeBreakdown]}
                          />
                        </Text>
                      )}
                      {!isOpenPayment && (
                        <Text>
                          <TextScript
                            text={PaymentScript?.Payment?.lblBreakdown}
                            translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.breakdown}`}
                            dynamicValues={[PaymentScript?.Payment?.viewBreakdown]}
                          />
                        </Text>
                      )}
                    </Link>
                  )}
                </Flex>
                {globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.ccOption && (
                  <Button
                    variant="outline"
                    h={'auto'}
                    paddingX={'30px'}
                    _hover={{ bg: 'green.400', color: 'white' }}
                    onClick={() => (enableButton ? encryptCreditCard() : undefined)}
                    style={{
                      cursor: enableButton ? '' : 'not-allowed',
                      background: enableButton ? primary : 'gray',
                      color: enableButton ? 'white' : 'lightGray',
                    }}
                  >
                    <TextScript
                      text={PaymentScript?.Global?.Submit}
                      translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.Submit}`}
                    />
                  </Button>
                )}
              </>
            )}
          </Flex>
          {isOpenPayment && <PaymentTotal />}
        </Flex>
      </Box>
    </>
  );
}

export default IngenicoPayment;
