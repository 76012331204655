/* eslint-disable no-console */
import { SubHeader, TextScript, getTypeByDomain, useConfig } from '@MGPD/myasurion-shared';
import { Box, Button, Card, CardBody, HStack, Image, SimpleGrid, Tag, Text, VStack } from '@chakra-ui/react';
//import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';

//Local Images for Category
import laptopimg from '../../icons/Laptop.png';
import laundryclosetimg from '../../icons/LaundryCloset.png';
import smartwatchimg from '../../icons/Smartwatches.png';
import tabletimg from '../../icons/Tablet.png';
import tvimg from '../../icons/Television.png';
import vaccumCleanerimg from '../../icons/VaccumCleaner.png';
import beautyCareimg from '../../icons/BeautyCare.png';
import dehumidifierHeaterimg from '../../icons/Dehumidifier.png';
import massagechairimg from '../../icons/HealthExercise.png';
import kitchenAppliancesimg from '../../icons/KitchenAppliances.png';
import ExistingCategoryPopup from './ExistingCategoryPopup';
import gameconsolesimg from '../../icons/GameConsoles.png';
import headphonesimg from '../../icons/Headphones.png';
import earphonesimg from '../../icons/Earphones.png';
import phoneimg from '../../icons/Phone.png'
import { useCentralStore } from '../../store/appContext';
import useContentfulImage from '../../hooks/useContentfulImage';
import { CONTENTFUL_SLUG } from '../../utils/constant';
import { DeviceCategory, DeviceType } from '../../types/types';
import { getRedirectUrl, isChineseDefaultLanguage, saveError, showErrorPage } from '../../utils/utills';
import ActionTypes from '../../store/actionTypes';
import { getCategoryListApi, getInventoryData, getRegisterDeviceListApi } from '../../services/api';
import { allowAnchorDeviceRegistration, showDeviceCategoryFromCode } from '../../utils/featuresUtills';
import MainStackLayout from '../../layout/MainStackLayout';
import CNScripts from '../../Scripts/asurion-script-chinese.json'

function DeviceCategoryList() {
  const navigate = useNavigate();
  const globalState = useCentralStore();
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;

  const laptop = useContentfulImage(CONTENTFUL_SLUG.LAPTOP);
  const laundryCloset = useContentfulImage(CONTENTFUL_SLUG.LAUNDRYCLOSET);
  const massageEquipment = useContentfulImage(CONTENTFUL_SLUG.MASSAGEEQUIPMENT);
  const kitchenAppliances = useContentfulImage(CONTENTFUL_SLUG.KITCHENAPPLIANCES);
  const beautyCare = useContentfulImage(CONTENTFUL_SLUG.BEAUTYCARE);
  const dehumidifierHeater = useContentfulImage(CONTENTFUL_SLUG.DEHUMIDIFIERHEATER);
  const purifierVaccum = useContentfulImage(CONTENTFUL_SLUG.PURIFIERVACCUM);
  const mobile = useContentfulImage(CONTENTFUL_SLUG.MOBILE);
  const tablet = useContentfulImage(CONTENTFUL_SLUG.TABLET);
  const tv = useContentfulImage(CONTENTFUL_SLUG.TV);
  const gameConsoles = useContentfulImage(CONTENTFUL_SLUG.GAMECONSOLE);
  const healthexercise = useContentfulImage(CONTENTFUL_SLUG.HEALTHEXERCISE);
  const headphones = useContentfulImage(CONTENTFUL_SLUG.HEADPHONES);
  const phones = useContentfulImage(CONTENTFUL_SLUG.PHONE)

  const CategoryList: any = [
    {
      categoryNameDisplay: '衣物整潔', //Laundry and closet
      categoryName: '衣物整潔',
      image: laundryCloset.imageUrl,
      localimage: laundryclosetimg,
      superCategory: ['生活大家電', '生活家電', '全部'], //Largehousehold, householdappliances
      selected: false,
      key:'LaundryCloset'
    },
    {
      categoryNameDisplay: '按摩舒壓', // 'Massage Equipment',
      categoryName: '按摩舒壓',
      image: massageEquipment.imageUrl,
      localimage: massagechairimg,
      superCategory: ['生活大家電', '生活家電', '健康運動', '全部'], //Largehoushold, houseAppliances, HealthExercise
      selected: false,
      key:'MassageEquipment'
    },
    {
      categoryNameDisplay: '廚房家電', //'Kitchen Appliances',
      categoryName: '廚房家電',
      image: kitchenAppliances.imageUrl,
      localimage: kitchenAppliancesimg,
      superCategory: ['生活大家電', '生活家電', '全部'], //Largehousehold, householdappliances
      selected: false,
      key:'KitchenAppliances'
    },
    {
      categoryNameDisplay: '美容美髮', //'Beauty care',
      categoryName: '美容美髮',
      image: beautyCare.imageUrl,
      localimage: beautyCareimg,
      superCategory: ['生活家電', '全部'], //householdappliances
      selected: false,
      key: 'BeautyCare'
    },
    {
      categoryNameDisplay: '除溼保暖', //'Dehumidifier & Heater',
      categoryName: '除溼保暖',
      image: dehumidifierHeater.imageUrl,
      localimage: dehumidifierHeaterimg,
      superCategory: ['生活家電', '全部'], //householdappliances
      selected: false,
      key: 'DehumidifierHeater'
    },
    {
      categoryNameDisplay: '清淨除塵', //'Purifier & Vacuum cleaner',
      categoryName: '清淨除塵',
      image: purifierVaccum.imageUrl,
      localimage: vaccumCleanerimg,
      superCategory: ['生活家電', '全部'], //householdappliances
      selected: false,
      key: 'VaccumCleaner'
    },
    {
      categoryNameDisplay: '手機裝置', //'Mobile Devices',
      categoryName: '手機裝置',
      image: mobile.imageUrl,
      localimage: tabletimg,
      superCategory: ['行動裝置', '全部'], //Mobile Devices
      selected: false,
      key: 'MobileDevices'
    },
    {
      categoryNameDisplay: '平板電腦', //'Tablet',
      categoryName: '平板電腦',
      image: tablet.imageUrl,
      localimage: tabletimg,
      superCategory: ['行動裝置', '全部'], //Mobile Devices
      selected: false,
      key: 'Tablet'
    },
    {
      categoryNameDisplay: '筆記電腦', //'Laptop',
      categoryName: '筆記電腦',
      image: laptop.imageUrl,
      localimage: laptopimg,
      superCategory: ['行動裝置', '全部'], //Mobile Devices
      selected: false,
      key: 'Laptop'
    },
    {
      categoryNameDisplay: '健康運動', //'Health & Exercise',
      categoryName: '健康運動',
      image: healthexercise.imageUrl,
      localimage: smartwatchimg,
      superCategory: ['健康運動', '全部'], //healthExcercise
      selected: false,
      key: 'HealthExercise'
    },
    {
      categoryNameDisplay: '穿戴裝置', //'Wearble',
      categoryName: '穿戴裝置',
      image: healthexercise.imageUrl,
      localimage: smartwatchimg,
      superCategory: [ '穿戴裝置', '全部'], //Wearble
      selected: false,
      key: 'Wearables'
    },
    {
      categoryNameDisplay: '電視娛樂', //'TV',
      categoryName: '電視影音',
      image: tv.imageUrl,
      localimage: tvimg,
      superCategory: ['影音娛樂', '全部'], //Entertainment
      selected: false,
      key: 'Tv'
    },
  ];

  const CategorlistAnchorDeviceSmartSupport: any=[
    {
      categoryNameDisplay: 'Phone',
      categoryName: 'Phone',
      image: phones.imageUrl,
      localimage: phoneimg ,
      selected: false,
      key:'Phone'
    },
    {
      categoryNameDisplay: 'Tablet',
      categoryName: 'Tablet',
      image: tablet.imageUrl,
      localimage: tabletimg,
      selected: false,
      key:'Tablet'
    },
  ]

  let deviceTypes: any = [
    { typeName: '全部', selected: true, typeNameDisplay:'全部', key: 'All' },
    { typeName: '生活大家電', selected: false, typeNameDisplay: '生活大家電', key: 'LargeHouseholdAppliances'},
    { typeName: '生活家電', selected: false ,typeNameDisplay: '生活家電', key: 'HouseholdAppliances'},
    { typeName: '行動裝置', selected: false, typeNameDisplay: '行動裝置', key: 'MobileDevices' },
    { typeName: '穿戴裝置', selected: false, typeNameDisplay: '穿戴裝置', key: 'WearableDevices' }, //Wearble Devices
    { typeName: '健康運動', selected: false, typeNameDisplay: '健康運動', key: 'HealthExercise' }, //Health Exercise
    { typeName: '影音娛樂', selected: false,typeNameDisplay: '影音娛樂', key: 'Entertainment' }, //Entertainment Devices
  ];

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [deviceCategoryList, setDeviceCategoryList] = useState<Array<DeviceCategory>>();
  const [deviceTypeList, setDeviceTypeList] = useState<Array<DeviceType>>(deviceTypes);
  const [selectedFilter, setSelectedFilter] = useState<Array<DeviceType>>([
    { typeName: '全部', selected: true, typeNameDisplay: '全部',key:'All' },
  ]);
  const [selectedDeviceCategory, setSelectedDeviceCategory] = useState<Array<DeviceCategory>>();
  const [, setRegisterDeviceListResponse] = useState();
  const [, setCategoryListResponse] = useState<any>();
  const [showDeviceCategoryFromCodeFlag, setShowDeviceCategoryFromCodeFlag] = useState();
  const [categoryList, setCategoryListtoShow] = useState<Array<DeviceCategory>>();
  const [existingCategories,setExistingCategories] = useState<any>()
  const [openCancelPopup, setOpenCancelPopup] = useState(false);

  const DeviceCategoryListScripts = Scripts.AddDevice.DeviceCategoryList;
  const DeviceCategoryListKeys = translationsKeys.AddDevice.DeviceCategoryList;

  const pageName = DeviceCategoryListKeys.PageName;

  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const isChinese = isChineseDefaultLanguage();
  const agreementData: any =
   globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const assetsData = agreementData?.Assets?.Asset?.filter((a:any) => { return a.AssetInstance === 'CUSVERIFY'});
  const clientOfferId = agreementData?.ClientOffer?.ClientOfferId;
  const IsSmartSupport4Plus = agreementData?.IsSmartSupport4Plus ;
  const isBYOD = agreementData?.isBYODCustomer
  
  // const toPascalCase = (str :any) => (str.match(/[a-zA-Z0-9]+/g) || []).map((w:any) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join('');

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const callDeviceCategoryListApi = () => {
    setLoading(true);
    getCategoryListApi(clientOfferId, interactionData)
      .then((data: any) => {
        globalState?.dispatch({
          type: ActionTypes.SET_GET_CATEGORY_RESPONSE,
          payload: data,
        });
        setLoading(false);
        if (IsSmartSupport4Plus) {
          let existingCategories = assetsData?.map((a:any) => a.AssetCategory?.AssetCategoryName?.toUpperCase())
          setExistingCategories(existingCategories)
          let filterRemaingCategory;
          // if (existingCategories?.length > 0) {
          //   filterRemaingCategory = data?.filter(
          //     (c: any) =>  {return !existingCategories.includes(c.CategoryName.toUpperCase())}
          //   );
          //   setCategoryListResponse(filterRemaingCategory);
          // } else {
            filterRemaingCategory =  data; 
            setCategoryListResponse(data);
          // }
          let CategoryListFromApi: any = [];
          if (filterRemaingCategory) {
            filterRemaingCategory?.map((a: any) =>{
              const images = getImagesFromContentFul(a.CategoryName)
              CategoryListFromApi?.push({
                categoryNameDisplay: a?.CategoryName, 
                categoryName: a?.CategoryName,
                image: images,
                localimage: getImagesFromLocal(a.CategoryName),   //Always add Image Name as CategoryName
                selected: false,
                key: a?.CategoryName,
              })
             } );
          }
          setDeviceCategoryList(CategoryListFromApi);
        } else {
          setCategoryListResponse(data);
        }
      })
      .catch((error: any) => {
        saveError(error, globalState);
        showErrorPage(navigate);
        setLoading(false);
      });
  };

  const getRegisterDeviceParams = () => {
    const getRegisterDeviceParameters = {
      GetRegisteredDeviceParameters: {
        ClientOfferId: clientOfferId,
        AssetCategoryName: selectedDeviceCategory?.[0]?.categoryName,
      },
    };
    return getRegisterDeviceParameters;
  };

  const callGetRegisterDeviceApi = () => {
    setLoading(true);
    getRegisterDeviceListApi(
      getRegisterDeviceParams(),
      interactionData?.InteractionLine?.InteractionLineId,
      agreementData?.AgreementId
    )
      .then((res: any) => {
        setLoading(false);
        setRegisterDeviceListResponse(res);
        globalState?.dispatch({
          type: ActionTypes.SET_REGISTER_DEVICE_RESPONSE,
          payload: res,
        });
        globalState?.dispatch({
          type: ActionTypes.SET_SELECTED_CATEGORY,
          payload: { selectedDeviceCategory },
        });
        navigate('/addNewDevice', { replace: true });
      })
      .catch((error) => {
        saveError(error, globalState);
        showErrorPage(navigate);
        setLoading(false);
      });
  };

  useEffect(() => {
    if(agreementData?.ClientOffer?.ClientOfferName){
    if(showDeviceCategoryFromCode(agreementData?.ClientOffer?.ClientOfferName)){
    const filteredCategoryList = categoryList?.filter((val: any) => {
      return val.superCategory?.includes(selectedFilter[0]?.typeName);
    });
    setDeviceCategoryList(filteredCategoryList);}}
  }, [selectedFilter, categoryList?.[0]?.image,agreementData?.ClientOffer?.ClientOfferName]);

  useEffect(() => {
   if(agreementData?.ClientOffer?.ClientOfferName){
    setShowDeviceCategoryFromCodeFlag(showDeviceCategoryFromCode(agreementData?.ClientOffer?.ClientOfferName));
    if(!showDeviceCategoryFromCode(agreementData?.ClientOffer?.ClientOfferName) && agreementData?.ClientOffer?.ClientOfferId && (!isBYOD)){
     callDeviceCategoryListApi()
    } else if((isBYOD && allowAnchorDeviceRegistration(agreementData?.ClientOffer?.ClientOfferName))){
      setCategoryListtoShow(CategorlistAnchorDeviceSmartSupport)
      setDeviceCategoryList(CategorlistAnchorDeviceSmartSupport);
    }else if(showDeviceCategoryFromCode(agreementData?.ClientOffer?.ClientOfferName) && !IsSmartSupport4Plus){
     setCategoryListtoShow(CategoryList)
  }
  }
  },[agreementData?.ClientOffer?.ClientOfferName])

  const getImagesFromContentFul = (CategoryName:any) => {
    switch (CategoryName?.toUpperCase()) {
      case 'LAPTOP':
       return laptop.imageUrl

      case 'HEADPHONES':
        return headphones.imageUrl
      
      case 'TABLET':
        return tablet.imageUrl

      case 'GAME CONSOLES':
        return gameConsoles.imageUrl
      
      case 'EARPHONES':
        return headphones.imageUrl
      
      case 'SMARTWATCHES':
        return healthexercise.imageUrl
      
      }
   }
  
   const getImagesFromLocal = (CategoryName:any) => {
    switch (CategoryName?.toUpperCase()) {
      case 'LAPTOP':
       return laptopimg

      case 'HEADPHONES':
        return headphonesimg
      
      case 'TABLET':
        return tabletimg

      case 'GAME CONSOLES':
        return gameconsolesimg
      
      case 'EARPHONES':
        return earphonesimg
      
      case 'SMARTWATCHES':
        return smartwatchimg
      
      }
   }

  const onCancelClick = () => {
    window.location.href = getRedirectUrl(getTypeByDomain());
  };

  const onDeviceTypeClick = (deviceType: DeviceType) => {
    setIsDisabled(true);
    const selectedOptions: any = deviceTypeList?.map((item: DeviceType) => {
      if (deviceType.typeName === item.typeName) {
        if (item.selected) {
          item.selected = true;
        } else {
          item.selected = !item.selected;
        }
      } else {
        item.selected = false;
      }
      return item;
    });
    const selectedFilter = selectedOptions?.filter((a: any) => {
      return a.selected == true;
    });
    setSelectedFilter(selectedFilter);
    setDeviceTypeList(selectedOptions);
  };

  const onDeviceCategoryClick = (category: any) => {
    const selectedOptions = deviceCategoryList?.map((item: DeviceCategory) => {
      if (category === item.categoryName) {
        item.selected = !item.selected;
        setIsDisabled(!item.selected);
      } else {
        item.selected = false;
      }
      return item;
    });
    const selectedCategory = selectedOptions?.filter((a) => {
      return a.selected == true;
    });
    setSelectedDeviceCategory(selectedCategory);
  };

  const OnExistingCategoryClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  }

  const callInventoryDataApi = () => {
    const isPhone = selectedDeviceCategory?.[0]?.categoryName?.toUpperCase() === 'PHONE'
    setLoading(true);
    getInventoryData(clientOfferId, interactionData,isPhone)
    
    .then((data: any) => {
         globalState?.dispatch({
           type: ActionTypes.SET_REGISTER_DEVICE_RESPONSE,
           payload: data,
         });
         setLoading(false);
         //setInventoryResponse(data);
         navigate('/deviceDetailsNew', { replace: true });
        })
        .catch((error:any) => {
         saveError(error, globalState);
         showErrorPage(navigate);
         setLoading(false);
        });
      };

  const handleSubmit = () => {
     if(showDeviceCategoryFromCode(agreementData?.ClientOffer?.ClientOfferName)){
      callGetRegisterDeviceApi();
     }
     else{
       if(isBYOD && allowAnchorDeviceRegistration(agreementData?.ClientOffer?.ClientOfferName)){
      globalState?.dispatch({
        type: ActionTypes.SET_SELECTED_CATEGORY,
        payload: { selectedDeviceCategory },
      })
      callInventoryDataApi();
    }else{
      globalState?.dispatch({
        type: ActionTypes.SET_SELECTED_CATEGORY,
        payload: { selectedDeviceCategory },
      });
      navigate('/thingsToNote', { replace: true });
     }
    }
  };

  const onBackClick = () => {
    window.history.back()
  }

  return (
    <Box>
      <Box w={{ base: 'full', lg: '80%' }} margin="auto" pt={'50px'}>
        <MainStackLayout gap={0} spacing={0}>
          <SubHeader
            onClick={onBackClick}
            backText={isChinese ? CNScripts?.Global.Back : Scripts.Global.Back}
            allowBackButtonClick={false}
            showCancelButton={false}
            showBackButton={true}
            cancelText={Scripts.CancelSR.cancelBtnTextCancel}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            p={'1rem'}
          >
            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
              <TextScript
                text={isChinese? CNScripts.AddDevice.DeviceCategoryList.header :DeviceCategoryListScripts.header}
                translationKey={`${pageName}/${DeviceCategoryListKeys.header}`}
              />
            </Text>
            {showDeviceCategoryFromCodeFlag &&
            <VStack overflow={'auto'} >
              <HStack my={'4'} spacing={{ lg: 4, base: 2 }} overflowX="auto" sx={{ '::-webkit-scrollbar': { display: 'none' } }} width={'100%'}>
                {deviceTypeList?.map((type) => (
                  <Tag
                    flexShrink="0"
                    size={{ base: 'md', lg: 'lg' }}
                    borderWidth={type.selected ? '2px' : '1px'}
                    borderColor={type.selected ? '#000000' : '#A5AAAF'}
                    key={type.typeName}
                    bg={'white'}
                    color={colors.bodyTextColor}
                    fontWeight={'bold'}
                    px={{ base: '2', lg: '4' }}
                    py={{ base: '1', lg: '2' }}
                    _hover={{ bg: '#A5AAAF' }}
                    cursor={'pointer'}
                    onClick={() => {
                      onDeviceTypeClick(type);
                    }}
                  >
                    <TextScript
                            text={type?.typeNameDisplay}
                            translationKey={`${pageName}/${DeviceCategoryListKeys.typeName}-${type?.key}`}
                          />
                  </Tag>
                ))}
              </HStack>
            </VStack>
            }

            <SimpleGrid my={'4'} spacing={{ base: 2, lg: 4 }} columns={{ base: 2, lg: (deviceCategoryList?.length === 2) ? 2 : 3 }}>
              {deviceCategoryList?.map((val, index) => {
                return (
                  <center key={index}>
                    <Card
                      borderWidth={val.selected ? '2px' : '1px'}
                      borderColor={val.selected ? colors.bodyTextColor : '#A5AAAF'}
                      borderRadius={'8px'}
                      onClick={() => {existingCategories?.includes(val?.categoryName?.toUpperCase()) ?
                        OnExistingCategoryClick():
                        onDeviceCategoryClick(val.categoryName);
                      }}
                      cursor={'pointer'}
                      _hover={{ bg: '#A5AAAF' }}
                      bg={existingCategories?.includes(val?.categoryName?.toUpperCase()) ? '#A5AAAF': '#FFFFFF'}
                    >
                      <CardBody py={'4'}>
                        <Image
                          height={'74px'}
                          my={'2'}
                          marginX={'auto'}
                          src={val?.image || val?.localimage}
                          alt="your model"
                        />
                        <Text>
                          <TextScript
                            text={val?.categoryNameDisplay}
                            translationKey={`${pageName}/${DeviceCategoryListKeys.category}-${val?.key}`}
                          />
                        </Text>
                      </CardBody>
                    </Card>
                  </center>
                );
              })}
            </SimpleGrid>

            <Box width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
              <Button
                mt={'5'}
                width={'full'}
                textAlign={'center'}
                colorScheme="primary"
                bg={isDisabled ? 'gray.200' : colors.primary}
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                disabled={isDisabled}
                onClick={() => (isDisabled ? undefined : handleSubmit())}
              >
                <TextScript
                  text={isChinese? CNScripts.Global.Continue :Scripts.Global.Continue}
                  translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Continue}`}
                />
              </Button>
            </Box>
          </Box>
        </MainStackLayout>
        <ExistingCategoryPopup closePopup={setOpenCancelPopup} openPopup={openCancelPopup} />
      </Box>
    </Box>
  );
}

export default DeviceCategoryList;