/* eslint-disable no-console */
import HomeIcon from '../../icons/HomeIcon';
import MySRIcon from '../../icons/MySRIcon';
import NewSRIcon from '../../icons/NewSRIcon';
import {
  ConfirmDeviceKey,
  InWarrantyDataKey,
  LFLStockKey,
  ResumeFlowDataKey,
  assetDataKey,
  claimDetailsResponseKey,
  currentAgreementKey,
  interactionLineKey,
  processIncidentKey,
  serviceFeeKey,
} from '../../modules/config/constants';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import { getDeviceImageByModel } from '../../utils/get-device-images';
import { camelize } from '../../utils/helper';
import { getProgrammeName } from '../../utils/localization-helper';
import { clearSessionData, getRedirectUrl, isBatteryReplacement, isChineseDefaultLanguage, isCleaningService, isDPClaim, isDeviceRefresh, isDeviceRepair, isMalfunction, isReplacement, isScreenRepair, isScreenRepairBatReplacement, isScreenRepairWarranty, isSwap, isWalkIn, saveError, showErrorPage, getGTMData } from '../../utils/utills';
// import { BackButton } from '@MGPD/myasurion-shared';
import {
  CustomerSupportFooter,
  TextScript,
  getAuthTokens,
  getCarrierByDomain,
  getCountryByCarrier,
  getTypeByDomain,
  useAccount,
  useConfig,
  // useFeatureIsOn,
  useSessionStorage,
} from '@MGPD/myasurion-shared';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Image,
  Link,
  List,
  ListItem,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MyClaimsScript from '../../Scripts/asurion-script.json'
import MyClaimsScriptCN from '../../Scripts/asurion-script-chinese.json'
import MyClaimKeys from '../../Scripts/translationsKeys.json';
import { claimDetailsApi, logGTMEvent, servicefeeApi } from '../../services/api';
import DeviceCard from './ClaimCard';
import FaqList from './FaqList';
import axios from 'axios';
import expertLeft from '/images/carriers/asurion/expert-default-left.png';
import expertMid from '/images/carriers/asurion/expert-default-mid.png';
import expertRight from '/images/carriers/asurion/expert-default-right.png';
import expertDesktop from '/images/carriers/asurion/expert-desktop.png';
import ChatOverlay from '../ChatOverlay';
import { DOMAIN, GTM_EVENT, WARRANTY_TYPE } from '../../utils/constant';
import { callServiceFee, hasMultiDeviceSupport, hideIWButton, showFAQSection } from '../../utils/featuresUtills';

const MyClaims: React.FC<PropsWithChildren> = () => {
  const globalState: any = useCentralStore();
  const navigate = useNavigate();
  const domainType = getTypeByDomain();
  const disableApplLoad = getCarrierByDomain()?.toLowerCase() === 'starhub'//useFeatureIsOn('disableApplLoad');

  const [, setProcessIncidentData] = useSessionStorage<any>(processIncidentKey, null);
  const [claimDetailsResponseData] = useSessionStorage<any>(claimDetailsResponseKey, null);

  const [isDesktop] = useState(window.matchMedia('(min-width: 850px)').matches);
  const Agreement =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  
  const assetData = Agreement?.Assets?.Asset?.filter(
    (a: any) => a.AssetInstance === 'ENROLLED'
  )?.[0]

  const [, setCurrentAgreementData] = useSessionStorage<any>(currentAgreementKey, null);
  const [, setServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
  //  const [,setCompletedSession] = useSessionStorage<any>('completedSession', null);
  const [, setResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const [, setInWarrantyData] = useSessionStorage<any>(InWarrantyDataKey, null);
  const [, setSelectedAssetData] = useSessionStorage<any>(assetDataKey, null);
  const [sessionData] = useSessionStorage<any>('data', '');
  const [, setLFLStockSession] = useSessionStorage<any>(LFLStockKey, null);
  const [clickPlan, setClickPlan] = useState(false);
  const [clickSR, setClickSR] = useState(true);

  const [claimDetails, setClaimDetails] = useState(false);
  // const [workingClaimDetails, setworkingClaimDetails] = useState([]);
  const [, setworkingClaimDetails] = useState([]);

  const [ongoingTom, setOngoingTom] = useState([]);
  const [ongoing, setOngoing] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [canceled, setCancelled] = useState([]);
  const [programmeNameLBL, setProgrammeNameLBL] = useState('');
  const [question, setQuestion] = useState<string>('');

  const [, setInteractionLineData] = useSessionStorage<any>(interactionLineKey, null);
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

  const ShippingOrderStatuses = ['READY', 'BORD', 'WBKO'];
  const RepairStatuses = ['COMPLETED', 'RESERVED'];

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const isTOM =
    globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === 'Asurion_TechCare';

  const isChinese = isChineseDefaultLanguage();

  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { bannerGradient } = colors;
  const { primary } = colors;

  const authtoken = getAuthTokens();
  const carrier = getCarrierByDomain();
  const cc = getCountryByCarrier(carrier);
  const myAsurionBaseUrl = import.meta.env.VITE_CONFIG_API_BASE_URL;

  const headers = {
    token: authtoken.token,
    'x-api-key': import.meta.env.VITE_X_API_KEY,
    'x-asurion-client': carrier,
    'x-asurion-cc': cc.code,
    authorization: authtoken.authorization,
    id: authtoken.userId,
  };

  useEffect(() => {    
    const GTMData = getGTMData(GTM_EVENT.MYCLAIMS_SCREEN_EVENT, globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0], 
      getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
      logGTMEvent(GTMData)
  }, []);

  useEffect(() => {
    if (Agreement) {
      globalState?.dispatch({ type: ActionTypes.SET_CURRENT_AGREEMENT_DATA, payload: Agreement });
    }
  }, [Agreement]);


  useEffect(() => {
    setInteractionLineData(interactionLineId);
    setCurrentAgreementData(Agreement);
  }, [globalState, Agreement]);

  const handlePlan = () => {
    setClickPlan(true);
    setClickSR(false);
  };
  const handleSR = () => {
    setClickPlan(false);
    setClickSR(true);
  };

  const callClaimDetailsApi = (AgreementId: string, ClientAccountId: string) => {
    setLoading(true);
    claimDetailsApi(ClientAccountId, AgreementId, interactionLineId)
      .then((res) => {
        setLoading(false);
        setClaimDetails(true);
        const CustomerCases = res?.CustomerCaseResults?.CustomerCases;
        const cancledClaims = CustomerCases.filter((claim: any) =>
          claim.CustomerCaseStatus.toLowerCase().includes('cncl')
        );
        const completedClaims = CustomerCases.filter(
          (claim: any) =>
            claim.CustomerCaseStatus.toLowerCase().includes('cmpl') &&
            (claim?.ShippingOrderStatus?.toLowerCase()?.includes('ship') ||
              claim?.ServiceRequests?.[0]?.ShippingOrder?.ShippingStatus?.toLowerCase()?.includes(
                'ship'
              ))
        );

        const ongoingClaims = CustomerCases.filter((claim: any) =>
        ['working', 'new', 'reopen'].includes(claim?.CustomerCaseStatus?.toLowerCase())
          // claim.CustomerCaseStatus.toLowerCase().includes('working') || claim.CustomerCaseStatus.toLowerCase().includes('new')
          // || claim.CustomerCaseStatus.toLowerCase().includes('reopen')
        );
        setworkingClaimDetails(ongoingClaims);

        const inTransitClaim = CustomerCases.filter(
          (claim: any) =>
            ShippingOrderStatuses.some(
              (status) => claim?.ShippingOrderStatus?.toUpperCase() === status
            ) && !claim?.CustomerCaseStatus?.toLowerCase()?.includes('cncl')
        );

        setOngoing([...ongoingClaims, ...inTransitClaim] as []);
        setOngoingTom([...ongoingClaims, ...inTransitClaim] as []);
        setCompleted(completedClaims);
        // setCompletedSession(completedClaims)
        setCancelled(cancledClaims);
      })
      .catch((error) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };


  useEffect(() => {
    const AgreementId = Agreement?.AgreementId;
    const ClientAccountId = Agreement?.ClientAccount?.ClientAccountId;
    const claimDetailsResponse: any =
      !globalState?.state?.chargeNightDeliveryFee &&
      (globalState?.state?.claimDetailsCustomerCaseResults || claimDetailsResponseData ||
        globalState?.state?.sessionResponse?.CustomerCaseResults?.CustomerCases);
    if (claimDetailsResponse?.length > 0) {
      setClaimDetails(true);
      const CustomerCases = claimDetailsResponse;
      const cancledClaims = CustomerCases.filter((claim: any) =>
        claim.CustomerCaseStatus.toLowerCase().includes('cncl')
      );
      const completedClaims = CustomerCases.filter(
        (claim: any) =>
          claim.CustomerCaseStatus.toLowerCase().includes('cmpl') &&
          (claim?.ShippingOrderStatus?.toLowerCase()?.includes('ship') ||
            claim?.ServiceRequests?.[0]?.ShippingOrder?.ShippingStatus?.toLowerCase()?.includes(
              'ship'
            ))
      );

      const ongoingClaims = CustomerCases.filter((claim: any) =>
      ['working', 'new', 'reopen'].includes(claim?.CustomerCaseStatus?.toLowerCase())      
        // claim.CustomerCaseStatus.toLowerCase().includes('working') || claim.CustomerCaseStatus.toLowerCase().includes('new')
      );
      setworkingClaimDetails(ongoingClaims);

      const inTransitClaim = CustomerCases.filter(
        (claim: any) =>
          ShippingOrderStatuses.some(
            (status) => claim?.ShippingOrderStatus?.toUpperCase() === status
          ) && !claim?.CustomerCaseStatus?.toLowerCase()?.includes('cncl')
      );

      let allOngoingClaims = [...ongoingClaims, ...inTransitClaim];

      if(isTOM || globalState?.state?.isMaxis){
         if(domainType === DOMAIN.REPAIR){
          allOngoingClaims = allOngoingClaims.filter((claim:any) => {
            return !isDPClaim(claim?.IncidentType, isIW_OEM(claim))
          })
         } else {
          allOngoingClaims = allOngoingClaims.filter((claim:any) => {
            return isDPClaim(claim?.IncidentType, isIW_OEM(claim))
          })
         }
      }

      setOngoing(allOngoingClaims as []);
      setOngoingTom([...ongoingClaims, ...inTransitClaim] as []);
      // setOngoing([...ongoingClaims, ...inTransitClaim] as []);
      setCompleted(completedClaims);
      // setCompletedSession(completedClaims)
      setCancelled(cancledClaims);
    } else if (AgreementId && ClientAccountId) {
      callClaimDetailsApi(AgreementId, ClientAccountId);
    }
  }, [globalState?.state?.sessionResponse]);

  const callServiceFeeApi = (AgreementId:any, AssetId:any) => {
    servicefeeApi(AgreementId, AssetId, interactionLineId)
        .then((res) => {
          setServiceFeeData(res);
        })
        .catch((error) => {
          setLoading(false);
          saveError(error, globalState);
          showErrorPage(navigate);
        });
  }

  useEffect(() => {
    const AgreementId = Agreement?.AgreementId;
    const AssetId = Agreement?.Assets?.Asset?.filter(
      (asset: any) => asset?.AssetInstance === 'ENROLLED'
    )?.[0]?.AssetId;
    // setLoading(true);
    if (AgreementId) {
      getProgrammeNameLbl();
      if(callServiceFee(Agreement?.ClientOffer?.ClientOfferName))
        callServiceFeeApi(AgreementId, AssetId);
    }
  }, [Agreement]);

  const setResumeProcessIncidentData = (data: any) => {
    const ProcessIncidentData = {
      CustomerCaseNumber: data?.CustomerCaseNumber,
      CustomerCaseStatus: data?.CustomerCaseStatus,
      CustomerCaseId: data?.CustomerCaseId,
      ServiceRequest: {
        ServiceRequestId: data?.ServiceRequests?.[0]?.ServiceRequestId,
        ServiceRequestNumber: data?.ServiceRequests?.[0]?.ServiceRequestNumber,
        ServiceRequestType: data?.ServiceRequests?.[0]?.ServiceRequestType,
        ServiceRequestStatus: data?.ServiceRequests?.[0]?.ServiceRequestStatus,
        AgreementId: data?.AgreementId,
        AssetId: data?.AssetID,
        WarrantyType: data?.WarrantyType,
        IncidentType: data?.IncidentType,
      },
      Eligibility: [
        {
          WarrantyType: data?.WarrantyType,
        },
      ],
      Holds: data?.holds,
    };
    setProcessIncidentData(ProcessIncidentData);
  };

  const getProgrammeNameLbl = async () => {
    const programmeName = await getProgrammeName(Agreement?.ClientOffer?.ClientOfferName);
    console.log('programmeName', programmeName);
    setProgrammeNameLBL(programmeName);

  };

  const isIWResume = (data: any) => {
    return data?.WarrantyType === WARRANTY_TYPE.IW || data?.WarrantyType === WARRANTY_TYPE.IW_OEM;
  };

  const isIW_OEM = (data: any) => {
    return data?.WarrantyType === WARRANTY_TYPE.IW_OEM;
  };

  const [account] = useAccount({ email: 'email' });


  const callFindAgreement = async (mdn: any, imei: any, data: any, idx: any) => {
    try {
      setLoading(true);
      // retryWrapper(axios, { retry_time: 2 });
      const response = await axios.post(
        `${myAsurionBaseUrl}/online/findagreement`,
        { mdn: mdn, imei: imei, email: account?.email, data: data, idx: idx },
        { headers }
      );
      setLoading(false);
      await callSession(response?.data?.body?.agreementId);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };
  

  const callSession = async (AgreementId:any) => {
    try {
      // retryWrapper(axios, { retry_time: 2 })
      let response = await axios.get(`${myAsurionBaseUrl}/online/session`, { headers });
      // response.data.body.InitializeResponse.ApplicationId = "6C6ACEB78E5411E789240A113F1B8C0E"
      globalState?.dispatch({
        type: ActionTypes.SET_SESSION_RESPONSE,
        payload: response.data.body,
      });
      const Agreement = response.data.body?.FindAgreementsResults?.Agreements?.Agreement.filter((val:any) => val.AgreementId === AgreementId)[0];
      globalState?.dispatch({ type: ActionTypes.SET_CURRENT_AGREEMENT_DATA, payload: Agreement });
      setCurrentAgreementData(Agreement);
      let assetData = Agreement?.Assets.Asset.filter((a: any) => a.AssetInstance === 'ENROLLED')?.[0];

      // for multi device support get selected device from session
      if(hasMultiDeviceSupport(Agreement?.ClientOffer?.ClientOfferName) && sessionStorage.getItem('dsMdn')){
        // get selected asset details stored in session
        const selectedAsset: any = JSON.parse(sessionStorage.getItem('dsMdn') || '');
        assetData = Agreement?.Assets.Asset.filter(
          (a: any) => a.AssetId === selectedAsset?.assetId
        )[0];
      }

      if(callServiceFee(Agreement?.ClientOffer?.ClientOfferName))
        await callServiceFeeApi(Agreement?.AgreementId, assetData.AssetId);
      setSelectedAssetData(assetData);
      return response.data.body;
    } catch (error) {
      setLoading(false)
      console.log('error', error);
    }
  }

  const handleResume = async(data: any) => {
    if(['new', 'reopen'].includes(data?.CustomerCaseStatus?.toLowerCase())) {
      // window.location.href = getRedirectUrl(getTypeByDomain())
      navigate('/?type=swap', { replace: true });
    } else {
      clearSessionData();
      sessionStorage.removeItem(ConfirmDeviceKey);
      setResumeProcessIncidentData(data);
      setResumeFlowData(data);
      const serviceOrderStatus = data?.ServiceRequests[0].ServiceOrder?.ServiceOrderStatus
      const deviceIsInStock = serviceOrderStatus?.toUpperCase() !== 'BORD';
      { serviceOrderStatus && setLFLStockSession(deviceIsInStock) };
      const claimData = data?.ServiceRequests?.[0]?.EnrolledDevice
      const mdn = claimData?.MDN;
      const imei = claimData?.IMEI;
      await callFindAgreement(mdn, imei, sessionData, '');

      if (isSwap(data?.IncidentType)) {
        if (data.ResumeStatus?.toLowerCase() === 'oem') {
          navigate('/oemwarranty', { replace: true });
        }
        if (data.ResumeStatus?.toLowerCase() === 'replacement') {
          navigate('/replacement', { replace: true });
        }
        if (data.ResumeStatus?.toLowerCase() === 'fmip') {
          if (data?.IsWBKODeviceAvailable) {
            // for back order we will start with device selection
            navigate('/replacement', { replace: true });
          } else if (
            data?.ServiceRequests?.[0]?.EnrolledDevice?.Make?.Name.toLowerCase() === 'apple'
          ) {
            navigate('/fmip', { replace: true });
          } else {
            navigate('/terms', { replace: true });
          }
        }
        if (data.ResumeStatus?.toLowerCase() === 'delivery') {
          navigate('/deliveryoptions', { replace: true });
        }
      }
      else if (isMalfunction(data?.IncidentType)) {
        if (data.ResumeStatus?.toLowerCase() === 'replacement') {
          if (data?.WarrantyType === "IW-OEM")
            navigate('/repair', { replace: true });
          else
            navigate('/replacement', { replace: true });
        } else if (data.ResumeStatus?.toLowerCase() === 'repairpage') {
          navigate('/repair', { replace: true });
        }
        if (data.ResumeStatus?.toLowerCase() === 'fmip') {
          if (data?.IsWBKODeviceAvailable) {
            // for back order we will start with device selection
            navigate('/replacement', { replace: true });
          } else if (
            data?.ServiceRequests?.[0]?.EnrolledDevice?.Make?.Name.toLowerCase() === 'apple'
          ) {
            navigate('/fmip', { replace: true });
          } else {
            navigate('/deliveryoptions', { replace: true });
          }
        }
        if (data.ResumeStatus?.toLowerCase() === 'delivery') {
          navigate('/deliveryoptions', { replace: true });
        }
      }
      else if (isReplacement(data?.IncidentType)) {
        if (data.ResumeStatus?.toLowerCase() === 'lostmode') {
          if (data?.ServiceRequests?.[0]?.EnrolledDevice?.Make?.Name.toLowerCase() === 'apple') {
            navigate('/lostmode', { replace: true });
          } else {
            navigate('/replacement', { replace: true });
          }
        }
        if (data.ResumeStatus?.toLowerCase() === 'replacement') {
          navigate('/replacement', { replace: true });
        }
        if (data.ResumeStatus?.toLowerCase() === 'tnc') {
          if (data?.IsWBKODeviceAvailable)
            // for back order we will start with device selection
            navigate('/replacement', { replace: true });
          else navigate('/terms', { replace: true });
        }
        if (data.ResumeStatus?.toLowerCase() === 'delivery') {
          navigate('/deliveryoptions', { replace: true });
        }
        if (data.ResumeStatus?.toLowerCase() === 'awaiting video declaration') {
          navigate('/videodeclaration', { replace: true });
        }
        if (data.ResumeStatus?.toLowerCase() === 'awaiting hold resolution') {
          navigate('/reviewhold', { replace: true });
        }
      }
      else if (
        isDeviceRefresh(data?.IncidentType) ||
        isDeviceRepair(data?.IncidentType) ||
        isBatteryReplacement(data?.IncidentType) ||
        isScreenRepair(data?.IncidentType) ||
        isScreenRepairBatReplacement(data?.IncidentType) ||
        isScreenRepairWarranty(data?.IncidentType)
      ) {
        if (data.ResumeStatus?.toLowerCase() === 'repairpage') {
          if (isIWResume(data)) navigate('/repair', { replace: true });
          else navigate('/terms', { replace: true });
        }
        if (data.ResumeStatus?.toLowerCase() === 'fmip') {
          if (data?.ServiceRequests?.[0]?.EnrolledDevice?.Make?.Name.toLowerCase() === 'apple') {
            navigate('/fmip', { replace: true });
          } else {
            navigate('/terms', { replace: true });
          }
        }
        if (data.ResumeStatus?.toLowerCase() === 'tnc') {
          navigate('/terms', { replace: true });
        }
        if (data.ResumeStatus?.toLowerCase() === 'delivery') {
          navigate('/deliveryoptions', { replace: true });
        }
      }

      if (data?.IsHoldExist) {
        const DocHoldExist =
          data?.holds?.filter(
            (hold: any) =>
              hold?.HoldType?.toUpperCase() === 'DOCUMENT' &&
              hold.HoldStatus?.toUpperCase() === 'OPEN'
          )?.length > 0;

        const VideoHoldExist =
          data?.holds?.filter(
            (hold: any) =>
              hold?.HoldType?.toUpperCase() === 'VIDEO' && hold.HoldStatus?.toUpperCase() === 'OPEN'
          )?.length > 0;

        if (VideoHoldExist || data?.ResumeStatus?.toLowerCase() === 'awaiting video declaration') {
          navigate('/videodeclaration', { replace: true });
        } else if (DocHoldExist) {
          navigate('/econfirmation', { replace: true });
        } else if (data?.ResumeStatus?.toLowerCase() === 'awaiting hold resolution') {
          navigate('/reviewhold', { replace: true });
        }
      }
    }
  };

  const handleInwarranty = (data: any) => {
    // save last claim fulfillment options and service request id
    const ServiceRequests = data?.ServiceRequests[0];
    const AppointmentDetails = data?.AppointmentDetails;
    const IncidentType = data?.IncidentType;

    const FulfillmentOption = ServiceRequests?.FulfillmentOption;
    const isWalkin = isWalkIn(FulfillmentOption);
    const ServiceProviderId = isWalkin ? '' : undefined;
    const ServiceProviderName = isWalkin ? AppointmentDetails?.ServiceProviderName : undefined;

    if(isMalfunction(IncidentType) && data?.WarrantyType === WARRANTY_TYPE.IW_OEM){
      window.location.href = '/swap?type=device-repair';
    } else {
      const inWarrantyData = {
        FulfillmentOption,
        ServiceProviderId,
        ServiceProviderName,
        IncidentType,
      };
  
      setInWarrantyData(inWarrantyData);
      navigate('/howcanwehelpWarranty', { replace: true });
    }
  };

  const callClaimDetailsAPI = () => {
    const AgreementId = Agreement?.AgreementId;
    const ClientAccountId = Agreement?.ClientAccount?.ClientAccountId;
    callClaimDetailsApi(AgreementId, ClientAccountId);
  };

  const getStatus = (claim: any) => {
    if (
      ShippingOrderStatuses.includes(claim?.ShippingOrderStatus) ||
      (claim?.RepairStatus && !RepairStatuses?.includes(claim?.RepairStatus))
    ) {
      if (claim?.ServiceRequests[0]?.ServiceOrder?.ServiceOrderStatus?.toUpperCase() === 'BORD') {
        return 'Back Order';
      } else {
        return 'Submitted';
      }
    } else if (
      claim?.ServiceRequests[0]?.ServiceOrder?.ServiceOrderStatus?.toUpperCase() === 'BORD'
    ) {
      return 'Back Order';
    } else {
      return 'Draft';
    }
  };

  const getCapacity = (claim : any) => {
    let capacity;

    if (claim?.ServiceRequests[0]?.ReplacedDevice &&
      Object?.keys(claim?.ServiceRequests[0]?.ReplacedDevice?.Model)?.length) {
      capacity = claim?.ServiceRequests[0]?.ReplacedDevice?.AssetCatalog?.Capacity +
        (claim?.ServiceRequests[0]?.ReplacedDevice?.AssetCatalog?.Capacity == '1' ? 'TB' : 'GB')
    } else {
      capacity = claim?.ServiceRequests[0]?.EnrolledDevice?.AssetCatalog?.Capacity +
        (claim?.ServiceRequests[0]?.EnrolledDevice?.AssetCatalog?.Capacity == '1' ? 'TB' : 'GB')
    }
    
    return capacity;
  }

  const getModelName = (claim: any) => {
    if(isCleaningService(claim?.IncidentType)){
      return getModelNameForVAS(claim);
    } else {
      if (
        claim?.ServiceRequests[0]?.ReplacedDevice &&
        Object?.keys(claim?.ServiceRequests[0]?.ReplacedDevice?.Model)?.length
      ) {
        const modelName = `${camelize(
          claim?.ServiceRequests[0]?.ReplacedDevice?.Model?.Family?.toLowerCase()
        )?.replace(/iphone/gi, 'iPhone')} 
        ${hasMultiDeviceSupport(Agreement?.ClientOffer?.ClientOfferName) ? '' : getCapacity(claim) }
        ${camelize(claim?.ServiceRequests[0]?.ReplacedDevice?.AssetCatalog?.Color?.toLowerCase())}`;
        return modelName;
      } else {
        const modelName = `${camelize(
          claim?.ServiceRequests[0]?.EnrolledDevice?.Model?.Family?.toLowerCase()
        )?.replace(/iphone/gi, 'iPhone')} 
        ${hasMultiDeviceSupport(Agreement?.ClientOffer?.ClientOfferName) ? '' : getCapacity(claim) }
        ${claim?.ServiceRequests[0]?.EnrolledDevice?.AssetCatalog?.Color ? camelize(claim?.ServiceRequests[0]?.EnrolledDevice?.AssetCatalog?.Color?.toLowerCase()) : ''}`;
        
        return modelName;
      }
    }
  };

  const getModelNameForVAS = (claim: any) => {
    const serviceRequestDevice = claim?.ServiceRequests[0]?.EnrolledDevice;
    return serviceRequestDevice?.AssetCatalog?.Name || serviceRequestDevice?.Model?.Name;
  }

  const getDeviceImage = (claim: any) : ReactNode => {
    console.log('claim', claim);
    if(hasMultiDeviceSupport(Agreement?.ClientOffer?.ClientOfferName)) {
     return <></>
    } else {
      return <Image
        height={'80px'}
        marginTop={'22px'}
        alignSelf={'center'}
         src={getDeviceImageByModel(
           globalState?.state?.deviceImages,
           claim?.ServiceRequests[0]?.EnrolledDevice?.Model?.Family,
           '',
           80
         )}
      ></Image>
    }
  }

  const footerLinks = () => {
    if (isTOM) {
      return [];
    } else {
      return [
        'Data Security & Privacy',
        'Home data backup',
        'Connected Home',
        'Parental control',
        'Streaming',
      ];
    }
  };

  const footerTitle = isTOM ? ' ' : 'You might be searching for this...';

  const scrollToSRSection = () => {
    globalState?.dispatch({
      type: ActionTypes.SCROLL_TO_SR,
      payload: true,
  });
  }

  return (
    <>
      <Box
        h={'full'}
        mt={'60px'}
        fontFamily={'Apercu Pro'}
        maxWidth={{ base: 'full', lg: '70%' }}
        mx={'auto'}
      >
        <Box w={'full'} mt={'60px'} p={{ base: '16px 8px', lg: '16px 0px' }}>
          <Button
            variant={'link'}
            as={Link}
            colorScheme="black"
            // href={getRedirectUrl(getTypeByDomain())}
            onClick={() => navigate(getRedirectUrl(getTypeByDomain()))}
            leftIcon={<ArrowBackIcon />}
          >
            <Text>
              <TextScript
                text={isChinese ? MyClaimsScriptCN.MyClaims.btnDeviceCare : MyClaimsScript?.MyClaims.btnDeviceCare}
                translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.btnDeviceCare}`}
              />
            </Text>
          </Button>
        </Box>

        {/* Mobile view  */}
        <Box display={{ base: 'block', lg: 'none' }}>
          <Box
            h="82px"
            w={'full'}
            m={'0'}
            px={4}
            pb="16px"
            bg={
              bannerGradient ||
              'linear-gradient(90deg, #03F182 0%, #53E6AF 52.08%, #9CDBD9 100%), linear-gradient(225deg, #8223D2 33.33%, #6B8BFF 100%)'
            }
            display={'flex'}
            justifyContent="center"
            alignItems="flex-end"
          >
            {programmeNameLBL && <Text fontWeight="bold" fontSize="2xl">
              {programmeNameLBL}
            </Text>}
          </Box>
          <Flex justifyContent={'center'} w={'full'}>
            <Box
              w={'full'}
              p={4}
              onClick={handlePlan}
              borderBottom={`${clickPlan ? '2px solid black' : ''}`}
            >
              <Text textAlign={'center'} fontSize={'16px'} fontWeight={400}>
                {/* <Link href="/portal/plan"> */}
                <Link
                  href={disableApplLoad ? '#' : '/portal/plan'}
                  onClick={() => disableApplLoad ? navigate('/portal/plan') : undefined}
                >
                  {/* {'Plan overview'} */}
                  <TextScript
                    text={isChinese ? MyClaimsScriptCN.MyClaims.lblPlanOverview : MyClaimsScript.MyClaims.lblPlanOverview}
                    translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.linkPlanoverview}`}
                  />
                </Link>
              </Text>
            </Box>
            <Box
              w={'full'}
              borderBottom={`${clickSR ? '2px solid black' : ''}`}
              p={4}
              onClick={handleSR}
            >
              <Text textAlign={'center'} fontSize={'16px'} fontWeight={700}>
                {/* {'My service requests'} */}
                <TextScript
                  text={isChinese ? MyClaimsScriptCN.MyClaims.lblMyServiceRequest : MyClaimsScript.MyClaims.lblMyServiceRequest}
                  translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.MyServiceRequests}`}
                />
              </Text>
            </Box>
          </Flex>
          <hr />
          {clickSR && claimDetails && ongoing?.length == 0 && ongoingTom?.length == 0 && (
            <Box backgroundColor={'#F0F0F5'} p={4} mx={4} mt={9}>
              <Link 
                // href={getRedirectUrl(getTypeByDomain())} 
                onClick={() => disableApplLoad ? (navigate(getRedirectUrl(getTypeByDomain())), scrollToSRSection()) : window.location.href = getRedirectUrl(getTypeByDomain())}
                _hover={{ textDecoration: 'none' }}
              >
                <Button
                  variant="outline"
                  display={'flex'}
                  justifyContent={'center'}
                  marginX={'auto'}
                  bg={primary}
                  textColor={'white'}
                  width={{ base: 'full', lg: '50%' }}
                  borderRadius={'8px'}
                >
                  <TextScript
                    text={isChinese ? MyClaimsScriptCN.MyClaims.lblStartSR : MyClaimsScript.MyClaims.lblStartSR}
                    translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.btnStartNewServiceRequest}`}
                  />
                </Button>
              </Link>
            </Box>
          )}

          {clickSR && (
            <Box mt={9} padding={2}>
              <Accordion allowToggle fontFamily={'Apercu Pro'} defaultIndex={[0]}>
                {ongoing.length > 0 && (
                  <AccordionItem backgroundColor={'#ffffff'}>
                    <AccordionButton backgroundColor={'#ffffff'}>
                      <Text
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontSize={'22px'}
                        fontWeight={400}
                        padding={4}
                      >
                        <TextScript
                          text={isChinese ? MyClaimsScriptCN.MyClaims.lblOngoing : MyClaimsScript.MyClaims.lblOngoing}
                          translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.tabOngoing}`}
                        />
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Box
                        id={'myasurion-srswap-howcanwehelp-2'}
                        marginX={isDesktop ? '20%' : '1%'}
                      >
                        <Flex flexDirection={'column'}>
                          {ongoing?.map((claim: any, key: any) => {
                            return (
                              <DeviceCard
                                key={key}
                                data={claim}
                                callClaimDetailsAPI={() => callClaimDetailsAPI()}
                                warranty=""
                                assetId={claim?.AssetId}
                                plan={`ID: ${claim?.CustomerCaseNumber} `}
                                mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1]}) ${claim?.ServiceRequests[0]?.EnrolledDevice?.MDN || assetData?.MobileDeviceNumber}`}
                                model={['new', 'reopen'].includes(claim?.CustomerCaseStatus?.toLowerCase()) ? assetData?.AssetCatalog?.AssetCatalogName : getModelName(claim)}
                                imei={claim?.ServiceRequests[0].EnrolledDevice?.IMEI}
                                status={getStatus(claim)}
                                handleResume={(data: any) => {
                                  handleResume(data);
                                  return;
                                }}
                                imageEl={ getDeviceImage(claim) }
                              />
                            );
                          })}
                        </Flex>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                )}
                {completed.length > 0 && (
                  <AccordionItem backgroundColor={'#ffffff'}>
                    <AccordionButton backgroundColor={'#ffffff'} padding={4}>
                      <Text
                        padding={4}
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontSize={'22px'}
                        fontWeight={400}
                      >
                        <TextScript
                          text={isChinese ? MyClaimsScriptCN.MyClaims.lblCompleted : MyClaimsScript.MyClaims.lblCompleted}
                          translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.tabCompleted}`}
                        />
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel pb={4}>
                      <Box
                        id={'myasurion-srswap-howcanwehelp-2'}
                        marginX={isDesktop ? '20%' : '1%'}
                      >
                        <Flex flexDirection={'column'}>
                          {completed?.map((claim: any, index: number) => {
                            return (
                              <DeviceCard
                                key={index}
                                data={claim}
                                hideWarrantyButton={ongoing?.length > 0 || ongoingTom?.length > 0 || hideIWButton(Agreement?.ClientOffer?.ClientOfferName)}
                                warranty={claim?.IsInWarranty && index === 0 ? 'Valid' : 'Expired'}
                                assetId={claim?.AssetId}
                                plan={`ID: SR${claim?.CustomerCaseNumber} `}
                                mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1]}) ${claim?.ServiceRequests[0]?.EnrolledDevice?.MDN}`}
                                model={getModelName(claim)}
                                imei={claim?.ServiceRequests[0]?.EnrolledDevice?.IMEI}
                                status={'Completed'}
                                handleInwarranty={() => {
                                  handleInwarranty(claim);
                                  return;
                                }}
                                imageEl={ getDeviceImage(claim) }
                              ></DeviceCard>
                            );
                          })}
                        </Flex>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                )}
                {canceled.length > 0 && (
                  <AccordionItem backgroundColor={'#ffffff'}>
                    <AccordionButton backgroundColor={'#ffffff'} padding={4}>
                      <Text
                        padding={4}
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontSize={'22px'}
                        fontWeight={400}
                      >
                        <TextScript
                          text={isChinese ? MyClaimsScriptCN.MyClaims.lblCancelled : MyClaimsScript.MyClaims.lblCancelled}
                          translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.tabCancelled}`}
                        />
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel >
                      <Box
                        id={'myasurion-srswap-howcanwehelp-2'}
                        marginX={isDesktop ? '20%' : '1%'}
                      >
                        <Flex flexDirection={'column'}>
                          {canceled?.map((claim: any, key: any) => {
                            return (
                              <DeviceCard
                                key={key}
                                data={claim}
                                warranty=""
                                assetId={claim?.AssetId}
                                plan={`ID: SR${claim?.CustomerCaseNumber} `}
                                // mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1]}) ${claim?.ServiceRequests[0]?.EnrolledDevice?.MDN}`}
                                // model={getModelName(claim)}
                                mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1]}) ${claim?.ServiceRequests[0]?.EnrolledDevice?.MDN || assetData?.MobileDeviceNumber}`}
                                model={['new', 'reopen'].includes(claim?.CustomerCaseStatus?.toLowerCase()) ? assetData?.AssetCatalog?.AssetCatalogName : getModelName(claim)}
                                imei={claim?.ServiceRequests[0]?.EnrolledDevice?.IMEI}
                                status={'Cancelled'}
                                imageEl={ getDeviceImage(claim) }
                              ></DeviceCard>
                            );
                          })}
                        </Flex>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                )}
              </Accordion>
            </Box>
          )}
        </Box>
        {/* Desktop View */}
        <Box display={{ base: 'none', lg: 'block' }}>
          <Flex direction="row" marginTop={'1rem'} display={{ base: 'none', lg: 'flex' }}>
            <Box w="30%">
              <List spacing={3}>
                <ListItem>
                  <Link 
                  href={disableApplLoad ? '#' : '/portal/plan'}
                  onClick={() => disableApplLoad ? navigate('/portal/plan') : undefined}
                  _hover={{ textDecoration: 'none' }}>
                    <Box
                      p={3}
                      w={'220px'}
                      _hover={{ bg: '#F0F0F5', borderRadius: '8px', fontWeight: 'bold' }}
                    >
                      <HomeIcon height={'24px'} width={'24px'} />
                      <Box as={'span'} px={2}>
                        {/* {'Plan overview'} */}
                        <TextScript
                          text={isChinese ? MyClaimsScriptCN.MyClaims.lblPlanOverview : MyClaimsScript.MyClaims.lblPlanOverview}
                          translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.linkPlanoverview}`}
                        />
                      </Box>
                    </Box>
                  </Link>
                </ListItem>
                <ListItem>
                  <Box bg={'#F0F0F5'} p={3}  borderRadius={'8px'} display="inline-block">
                    <MySRIcon height={'24px'} width={'24px'} />
                    <Link px={2} _hover={{ textDecoration: 'none' }} fontWeight={'bold'}>
                      <TextScript
                        text={isChinese ? MyClaimsScriptCN.MyClaims.lblMyServiceRequest : MyClaimsScript.MyClaims.lblMyServiceRequest}
                        translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.MyServiceRequests}`}
                      />
                    </Link>
                  </Box>
                </ListItem>
              </List>
            </Box>
            <Box w={'60%'} px={2}>
              {programmeNameLBL && <Text fontWeight="bold" fontSize="2.5rem" style={{ whiteSpace: 'nowrap' }}>
                {programmeNameLBL}
              </Text>}
              <Text fontSize={'1.5rem'}>
                <TextScript
                  text={isChinese ? MyClaimsScriptCN.MyClaims.lblMyServiceRequest : MyClaimsScript.MyClaims.lblMyServiceRequest}
                  translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.MyServiceRequests}`}
                />
              </Text>
              <Box pt={2}>
                {clickSR && claimDetails && (
                  <Tabs colorScheme="black">
                    <TabList borderColor={'black'} display={'inline-flex'}>
                      <Tab _selected={{ fontWeight: 'bold' }} style={{ whiteSpace: 'nowrap' }}>
                        <TextScript
                          text={isChinese ? MyClaimsScriptCN.MyClaims.lblOngoing : MyClaimsScript.MyClaims.lblOngoing}
                          translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.tabOngoing}`}
                        />
                      </Tab>
                      <Tab _selected={{ fontWeight: 'bold' }} style={{ whiteSpace: 'nowrap' }}>
                        <TextScript
                          text={isChinese ? MyClaimsScriptCN.MyClaims.lblCompleted : MyClaimsScript.MyClaims.lblCompleted}
                          translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.tabCompleted}`}
                        />
                      </Tab>
                      <Tab _selected={{ fontWeight: 'bold' }} style={{ whiteSpace: 'nowrap' }}>
                        <TextScript
                          text={isChinese ? MyClaimsScriptCN.MyClaims.lblCancelled : MyClaimsScript.MyClaims.lblCancelled}
                          translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.tabCancelled}`}
                        />
                      </Tab>
                    </TabList>
                    <TabIndicator
                      mt="-4.5px"
                      height="4px"
                      bg="black"
                      borderRadius="1px"
                      w={'200px'}
                    />
                    <TabPanels>
                      <TabPanel>
                        <Box id={'myasurion-srswap-howcanwehelp-2'}>
                          <Flex flexDirection={'column'}>
                            <Box
                              id={'myasurion-devicecard'}
                              borderRadius={'8px'}
                              minW={'545px'}
                              minH={'124px'}
                              borderWidth={'1px'}
                              borderStyle={'solid'}
                              borderColor={'gray.300'}
                              my={5}
                            >
                              <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                px={'15px'}
                                py={'10px'}
                              >
                                <Box>
                                  {/* <Text fontSize={'1.5rem'}>
                                    <TextScript
                                      text={isChinese ? MyClaimsScriptCN.MyClaims.lblRepairReplace : MyClaimsScript.MyClaims.lblRepairReplace}
                                      translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.needToRepairorReplaceDevice}`}
                                    />
                                  </Text> */}
                                  <Box pt={5}>
                                    <Link 
                                    // href={getRedirectUrl(getTypeByDomain())} 
                                    onClick={() => disableApplLoad ? (navigate(getRedirectUrl(getTypeByDomain())), scrollToSRSection()) : window.location.href = getRedirectUrl(getTypeByDomain())}
                                    _hover={{ textDecoration: 'none' }}>
                                      <Button
                                        variant="outline"
                                        display={'flex'}
                                        justifyContent={'center'}
                                        bg={primary}
                                        textColor={'white'}
                                        px={5}
                                        py={3}
                                        borderRadius={'8px'}
                                        isDisabled={ongoing?.length > 0 ? true : false}
                                      >
                                        <TextScript
                                          text={isChinese ? MyClaimsScriptCN.MyClaims.lblStartSR : MyClaimsScript.MyClaims.lblStartSR}
                                          translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.btnStartNewServiceRequest}`}
                                        />
                                      </Button>
                                    </Link>
                                  </Box>
                                </Box>
                                <Box>
                                  <NewSRIcon />
                                </Box>
                              </Box>
                            </Box>
                            {ongoing?.map((claim: any, key: any) => {
                              return (
                                <DeviceCard
                                  key={key}
                                  data={claim}
                                  callClaimDetailsAPI={() => callClaimDetailsAPI()}
                                  warranty=""
                                  assetId={claim?.AssetId}
                                  plan={`ID: SR${claim?.CustomerCaseNumber} `}
                                  // mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1]}) ${claim?.ServiceRequests[0]?.EnrolledDevice?.MDN}`}
                                  // model={getModelName(claim)}
                                  mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1]}) ${claim?.ServiceRequests[0]?.EnrolledDevice?.MDN || assetData?.MobileDeviceNumber}`}
                                  model={['new', 'reopen'].includes(claim?.CustomerCaseStatus?.toLowerCase()) ? assetData?.AssetCatalog?.AssetCatalogName : getModelName(claim)}
                                  imei={claim?.ServiceRequests[0].EnrolledDevice?.IMEI}
                                  status={getStatus(claim)}
                                  handleResume={(data: any) => {
                                    handleResume(data);
                                    return;
                                  }}
                                  imageEl={ getDeviceImage(claim) }
                                />
                              );
                            })}
                          </Flex>
                        </Box>
                      </TabPanel>
                      <TabPanel>
                        <Box id={'myasurion-srswap-howcanwehelp-2'}>
                          <Flex flexDirection={'column'}>
                            {completed?.length === 0 && (
                              <Text py={5}>
                                <TextScript
                                  text={isChinese ? MyClaimsScriptCN.MyClaims.lblNoCompletedClaim : MyClaimsScript.MyClaims.lblNoCompletedClaim}
                                  translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.NoCompletedClaim}`}
                                />
                              </Text>
                            )}
                            {completed?.map((claim: any, index: number) => {
                              return (
                                <DeviceCard
                                  key={index}
                                  data={claim}
                                  hideWarrantyButton={ongoing?.length > 0 || ongoingTom?.length > 0 || hideIWButton(Agreement?.ClientOffer?.ClientOfferName)}
                                  warranty={
                                    claim?.IsInWarranty && index === 0 ? 'Valid' : 'Expired'
                                  }
                                  assetId={claim?.AssetId}
                                  plan={`ID: SR${claim?.CustomerCaseNumber} `}
                                  mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1]}) ${claim?.ServiceRequests[0]?.EnrolledDevice?.MDN}`}
                                  model={getModelName(claim)}
                                  imei={claim?.ServiceRequests[0]?.EnrolledDevice?.IMEI}
                                  status={'Completed'}
                                  handleInwarranty={() => {
                                    handleInwarranty(claim);
                                    return;
                                  }}
                                  imageEl={ getDeviceImage(claim) }
                                ></DeviceCard>
                              );
                            })}
                          </Flex>
                        </Box>
                      </TabPanel>
                      <TabPanel>
                        <Box id={'myasurion-srswap-howcanwehelp-2'}>
                          <Flex flexDirection={'column'}>
                            {canceled?.length === 0 && (
                              <Text py={5}>
                                <TextScript
                                  text={isChinese ? MyClaimsScriptCN.MyClaims.lblNoCanceledClaim : MyClaimsScript.MyClaims.lblNoCanceledClaim}
                                  translationKey={`${MyClaimKeys.MyClaims.PageName}/${MyClaimKeys.MyClaims.NoCanceledClaim}`}
                                />
                              </Text>
                            )}
                            {canceled?.map((claim: any, key: any) => {
                              return (
                                <DeviceCard
                                  key={key}
                                  data={claim}
                                  warranty=""
                                  assetId={claim?.AssetId}
                                  plan={`ID: SR${claim?.CustomerCaseNumber} `}
                                  // mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1]}) ${claim?.ServiceRequests[0]?.EnrolledDevice?.MDN}`}
                                  // model={getModelName(claim)}
                                  mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1]}) ${claim?.ServiceRequests[0]?.EnrolledDevice?.MDN || assetData?.MobileDeviceNumber}`}
                                  model={['new', 'reopen'].includes(claim?.CustomerCaseStatus?.toLowerCase()) ? assetData?.AssetCatalog?.AssetCatalogName : getModelName(claim)}
                                  imei={claim?.ServiceRequests[0]?.EnrolledDevice?.IMEI}
                                  status={'Cancelled'}
                                  imageEl={ getDeviceImage(claim) }
                                ></DeviceCard>
                              );
                            })}
                          </Flex>
                        </Box>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                )}
              </Box>
              {(globalState?.state?.sessionResponse && clickSR && showFAQSection(Agreement?.ClientOffer?.ClientOfferName)) && <FaqList ClientOfferName={Agreement?.ClientOffer?.ClientOfferName} />}
            </Box>
          </Flex>
        </Box>
        {(globalState?.state?.sessionResponse && clickSR && showFAQSection(Agreement?.ClientOffer?.ClientOfferName)) && (
          <Box display={{ base: 'block', lg: 'none' }}>
            <FaqList ClientOfferName={Agreement?.ClientOffer?.ClientOfferName} />
          </Box>
        )}
      </Box>
      <Box marginTop={'2.5rem'}>
        <CustomerSupportFooter
          keywords={footerLinks()}
          title="Need help?"
          heading={isTOM ? 'Need help?' : 'Hey 👋 I’m Anna, your Tech specialist.'}
          subheading={isTOM ? 'You might be searching for this...' : 'Our team is here to help!'}
          searchTitle={footerTitle}
          onSendQuestion={(qq) => {
            setQuestion(qq);
          }}
          doAnimate={true}
          images={[
            {
              position: 'left',
              url: expertLeft,
              view: 'mobile',
            },
            {
              position: 'middle',
              url: expertMid,
              view: 'mobile',
            },
            {
              position: 'right',
              url: expertRight,
              view: 'mobile',
            },
            {
              position: 'middle',
              url: expertDesktop,
              view: 'desktop',
            },
          ]}
        />
      </Box>
        <ChatOverlay question={question} onChatClose={() => setQuestion('')} />
    </>
  );
};
export default MyClaims;
