// import { useInteractionData } from '@MGPD/myasurion-shared';
import { getAuthTokens, getCarrierByDomain, getCountryByCarrier } from '@MGPD/myasurion-shared';
import axios from 'axios';
// import { v4 as uuid } from 'uuid';
// const [interactionData, ] = useInteractionData(null);
import { headers } from './headers';
import { isRetryEligibile } from '../utils/utills';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

// const headers = {
//   'asurion-channel': 'online',
//   'asurion-client': 'starhub',
//   'asurion-enduser': 'online',
//   'asurion-lineofbusiness': 'MOBILITY',
//   'asurion-region': 'APAC-SEA',
//   'asurion-application': 'myasurion',
//   'asurion-correlationid': uuid()?.replace(/-/g, '')?.toUpperCase(),
//   // 'Asurion-interactionlineid': JSON.parse(window.sessionStorage.interactionData).InteractionId
// };

export const getOnlineSession = async () => {
  const carrier = getCarrierByDomain();
  const cc = getCountryByCarrier(carrier);
  const myAsurionBaseUrl = import.meta.env.VITE_CONFIG_API_BASE_URL;
  const authtoken = getAuthTokens();
  const headers = {
    token: authtoken.token,
    'x-api-key': import.meta.env.VITE_X_API_KEY,
    'x-asurion-client': carrier,
    'x-asurion-cc': cc.code,
    authorization: authtoken.authorization,
    id: authtoken.userId,
  };

  try {
    retryWrapper(axios, {retry_time: 2})
    return axios.get(`${myAsurionBaseUrl}/online/session`, { headers })
    .then(response => {
      return response.data.body;
    })
  } catch (error) {
    console.log(error);
  }
};

export const getDeviceList = async () => {
  const carrier = getCarrierByDomain();
  const cc = getCountryByCarrier(carrier);
  const myAsurionBaseUrl = import.meta.env.VITE_CONFIG_API_BASE_URL;
  const authtoken = getAuthTokens();
  const headers = {
    token: authtoken.token,
    'x-api-key': import.meta.env.VITE_X_API_KEY,
    'x-asurion-client': carrier,
    'x-asurion-cc': cc.code,
    authorization: authtoken.authorization,
    id: authtoken.userId,
  };

  try {
    return axios.get(`${myAsurionBaseUrl}/account/devices`, { headers })
    .then(response => {
      return response.data.body;
    })
  } catch (error) {
    console.log(error);
  }
};

export const retryWrapper = (axios: any, options: any) => {
  const max_time = options.retry_time;
  // const retry_status_code = options.retry_status_code;
  let counter = 0;
  axios.interceptors.response.use(null, (error: any) => {
    const config = error.config
    if ((config?.method?.toUpperCase() === "GET" || isRetryEligibile(config?.url)) && counter < max_time) {
      counter++
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(axios(config))
        }, 500);
      })
    }
    return Promise.reject(error)
  })
}

export const servicefeeApi = async (
  agreementId: string,
  assetId?: string,
  InteractionLineId?: string
) => {
  try {
    return axios.get(
      `/swap/claim/api/v2/servicefees?agreementId=${agreementId}&assetId=${assetId}&multiplefees=true`,
      {
        headers: { ...headers, 'Asurion-interactionlineid': InteractionLineId },
      }
    ).then(response => {
      return response.data?.ServiceFee;
    })
  } catch (error) { }
};

export const createServiceRequestApi = async (interactionLineId: string, agreementId: string) => {
  try {
    return axios.post(
      '/swap/claim/api/startservicerequest',
      {
        CreateServiceRequestParams: {
          InteractionLineId: interactionLineId,
          AgreementId: agreementId,
        },
      },
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response?.data?.CustomerCase;
    });
  } catch (error) { }
};

// const callReplacementApi = (ServiceRequestId:string, interactionLineId:string) => {
//   getReplacementDevice( ServiceRequestId,
//     interactionLineId)
//   .then((res) => {
//     console.log("ReplacementResponse:",res)
//   })
//   .catch((error) => {
//     console.log('ReplacementApiError:', error);
//   });
// }

export const processIncidentApi = async (
  interactionLineId: string,
  serviceRequestId: string,
  customerCaseId: string,
  clientOfferId: string,
  mdn: string,
  agreementId: string,
  customerId: string,
  incidentType: string,
  FailureDescriptiveText: string,
  incidentDate: string,
  assetCatalogId: string,
  assetId:string,
  imei: string,
  Triage?: Object,
  ScreenRepairQA?: Array<any>
) => {
  try {
    return axios.post(
      '/swap/claim/api/processincident',
      {
        ProcessIncidentParameters: {
          InteractionLineId: interactionLineId,
          ServiceRequestId: serviceRequestId,
          CustomerCaseId: customerCaseId,
          ClientOfferId: clientOfferId,
          MobileNumber: mdn,
          Incident: {
            AgreementId: agreementId,
            CustomerId: customerId,
            FailureDescriptiveText: FailureDescriptiveText,
            IncidentType: incidentType,
            IncidentDate: incidentDate,
            Triage: Triage
          },
          AssetDetails: {
            AssetCatalogId: assetCatalogId,
            SerialNumber: imei,
            AssetId: assetId
          },
          ScreenRepairQA: ScreenRepairQA,
        },
      },
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      // for async processincident call setting here seperately
      sessionStorage.setItem('processIncident', JSON.stringify(response?.data.CustomerCase));
      // call replacement api before RPM page to minimise the load time
      // if (
      //   response?.data.CustomerCase.ServiceRequest.IncidentType.toUpperCase() === 'REPLACEMENT' ||
      //   response?.data.CustomerCase.ServiceRequest.IncidentType.toUpperCase() === 'SWAP'
      // ) {
      //   callReplacementApi(response?.data.CustomerCase.ServiceRequest.ServiceRequestId, interactionLineId);
      // }
      return response?.data.CustomerCase;
    });
  } catch (error) { }
};
// export const processIncidentIWApi = async (
//   interactionLineId: string,
//   serviceRequestId: string,
//   customerCaseId: string,
//   clientOfferId: string,
//   mdn: string,
//   agreementId: string,
//   customerId: string,
//   incidentType: string,
//   FailureDescriptiveText: string,
//   incidentDate: string,
//   assetCatalogId: string,
//   imei: string,
//   Triage?: Object,
//   ScreenRepairQA?: Array<any>
// ) => {
//   try {
//     return axios.post(
//       '/swap/claim/api/processincident',
//       {
//         ProcessIncidentParameters: {
//           InteractionLineId: interactionLineId,
//           ServiceRequestId: serviceRequestId,
//           CustomerCaseId: customerCaseId,
//           ClientOfferId: clientOfferId,
//           MobileNumber: mdn,
//           Incident: {
//             AgreementId: agreementId,
//             CustomerId: customerId,
//             FailureDescriptiveText: FailureDescriptiveText,
//             IncidentType: incidentType,
//             IncidentDate: incidentDate,
//             Triage: Triage
//           },
//           ScreenRepairQA: ScreenRepairQA,
//           AssetDetails: {
//             AssetCatalogId: assetCatalogId,
//             SerialNumber: imei,
//           },
//         },
//       },
//       {
//         headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
//       }
//     ).then(response => {
//       return response?.data.CustomerCase;
//     });
//   } catch (error) { }
// };

export const setFulfillmentMethodApi = async (
  InteractionLineId: string,
  FulfillmentOption: string,
  serviceRequestId: string
) => {
  try {
    return axios.post(
      `/swap/claim/api/fulfillmentoptions?servicerequestid=${serviceRequestId}`,
      {
        SetFulfillmentOptionParameters: {
          InteractionLineId: InteractionLineId,
          FulfillmentOption: FulfillmentOption,
        },
      },
      {
        headers: { ...headers, 'Asurion-interactionlineid': InteractionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getFulfillmentMethodApi = async (serviceRequestId: string, interactionLineId: string) => {
  try {
    return axios.get(
      `/swap/claim/api/fulfillmentoptions?servicerequestid=${serviceRequestId}`,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response?.data.FulfillmentOptionResults.FulfillmentOptionResult;
    })
  } catch (error) { }
};

export const fmipCheckEligibility = async (imei: string, interactionLineId: string, AgreementId: string) => {
  try {
    return axios.post(
      '/swap/claim/api/fmipcheckeligibility',
      {
        CheckEligibilityRequest: {
          Imei: imei,
          AgreementId: AgreementId
        },
      },
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};
export const getReplacementDevice = async (servicerequestid: string, interactionLineId: string) => {
  try {
    return axios.get(
      `/swap/claim/api/v5/replacement?servicerequestid=${servicerequestid}&IncludeAllReplacementCategory=true`,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      sessionStorage.setItem('replacementData', JSON.stringify(response?.data?.ReplacementEquipmentResults.ReplacementItems.ReplacementItem))
      return response.data;
    })
  } catch (error) {
    console.error(error);
  }
};
// export const getReplacementDeviceUpgrade = async () => {
//     try {
//         const response = await axios.get(
//             `/swap/claim/api/v4/replacement?servicerequestid=${servicerequestid}&replacementCategory='UP1'`,
//             {
//                 headers: headers
//             }
//         );
//         return response.data;
//     } catch (error) {
//         console.error(error);
//     }
// }
export const getReplacementDeviceDowngrade = async (servicerequestid: string) => {
  try {
    return axios.get(
      `/swap/claim/api/v4/replacement?servicerequestid=${servicerequestid}&replacementCategory='DOWN1'`,
      {
        headers: headers,
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const createServiceOrder = async (CreateServiceOrderParameters: any, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/claim/api/serviceorder',
      {
        CreateServiceOrderParameters,
      },
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getPCIToken = async (ReferenceId: string, CustomerCaseId: string) => {
  try {
    return axios.post(
      `/swap/claim/api/pcitoken`,
      {
        SecurityTokenParameters: {
          ReferenceId: ReferenceId,
          CustomerCaseId: CustomerCaseId,
        },
      },
      {
        headers: headers,
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const chargeOrderApi = async (payload: object, serviceOrderId: string, interactionLineId: string,) => {
  try {
    return axios.post(`/swap/claim/api/v2/chargeorder/${serviceOrderId}`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const chargeOrderApiBrainTree = async (payload: object, serviceOrderId: string, interactionLineId: string,) => {
  try {
    return axios.post(`/swap/claim/braintree/api/chargeorder/${serviceOrderId}`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const createRepairRequestApi = async (payload: object, interactionLineId: string,) => {
  try {
    return axios.post(`/swap/claim/api/repairrequest/create`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const processPaymentApi = async (payload: object, serviceOrderId: string, chargeorderId: string, interactionLineId: string,) => {
  try {
    return axios.post(`/swap/claim/api/v3/processpayment/${serviceOrderId}/${chargeorderId}`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};
export const submitOrderApi = async (payload: any) => {
  axios.post(
    `/swap/claim/api/submitorder/${payload?.SubmitServiceOrderParameters.ServiceOrderId}`,
    payload, { headers: { ...headers, 'Asurion-interactionlineid': payload?.SubmitServiceOrderParameters?.InteractionLineId }, }
  ).then(response => {
    return response.data;
  }).catch(error => {
    console.error(error);
  })
}

export const getClientToken = async (maid: string, interactionLineId: string, AgreementId: string) => {
  try {
    return axios.get(`/swap/claim/braintree/api/getclienttoken/${maid}?agreementid=${AgreementId}`, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

// Logistic Api's

export const shippingmethodsv2Api = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post('/swap/claim/api/shippingmethodsv2', payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getscheduledavailabilityApi = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post('/swap/claim/api/getscheduledavailability', payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getaspstorelistAPi = async (
  servicerequestid: string,
  incidentType: string,
  interactionLineId: string
) => {
  try {
    return axios.get(
      `/swap/claim/api/v2/getaspstorelist?servicerequestid=${servicerequestid}&incidenttype=${incidentType}`,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    )
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    console.error(error);
  }
};
export const cancelSRApi = async (serviceRequestId: string, payload: any, interactionLineId: string) => {
  const isTOM = import.meta.env.VITE_ASURION_CLIENT?.toLowerCase() === 'asurion_techcare' || false
  try {
    return axios.post(
      isTOM ? `/swap/claim/api/cancel/v2/${serviceRequestId}` : `/swap/claim/api/cancelservicerequest/${serviceRequestId}`,
      payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const btCreatePaymentMethod = async (
  createPaymentMethodParameters: object,
  serviceOrderId: string
) => {
  try {
    return axios.post(
      `/swap/claim/braintree/api/createpaymentmethod/${serviceOrderId}`,
      {
        createPaymentMethodParameters,
      },
      {
        headers: headers,
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getUpdateShippingDetails = async (payload: object, serviceOrderId: string, interactionLineId: string) => {
  return axios.put(`/swap/claim/api/shippingorder/${serviceOrderId}`, payload, {
    headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
  })
};

export const shippingmethodsv1Api = async (payload: object, serviceOrderId: string, interactionLineId: string) => {
  try {
    return axios.post(
      `/swap/claim/api/shippingmethods?serviceorderid=${serviceOrderId}`,
      payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const claimDetailsApi = async (ClientAccountId: string, AgreementId: string, interactionLineId: string) => {
  try {
    return axios.get(
      `/swap/claim/api/v2/details/${ClientAccountId}?agreementId=${AgreementId}`,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getSignedUrlFromS3 = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(`/swap/claim/api/filesystem/upload/v3`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export async function uploadToS3BucketBySignedURL(
  url: string,
  type: string,
  file: any
): Promise<any> {
  try {
    return axios.put(url, file, {
      headers: {
        Accept: 'application/json',
        'Content-Type': type,
      },
    }).then(response => {
      return response;
    });
  } catch (error) {
    throw error;
  }
}

export const getDownloadUrlFromS3 = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(`/swap/claim/api/filesystem/download/v2`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const uploadVideoDocument = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(`/swap/claim/api/updatereferencedocument`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const logGTMEvent = (event: any) => {
  window.dataLayer.push(event);
};

export const genratePDFDocument = async (payload: object, interactionLineId: string) => {
  console.log("payload:", payload)
  try {
    const response = await axios.post(`/swap/registration/api/pdfgeneration`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const uploadHoldDoc = async (payload: object, interactionLineId: string) => {
  console.log("payload:", payload)
  try {
    return axios.post(`/swap/registration/api/updateholddoc`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response?.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const loggerApiCall = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(`/swap/api/v1/logger`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const shippingmethodsv3Api = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      `/swap/claim/api/v3/shippingmethods`,
      payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const repairRequestSchedule = async (payload: object, interactionLineId: string) => {
  try {
    const response = await axios.post(`/swap/claim/api/integrationonline/v1/repairrequest/schedule`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateStatus = async (payload: object, interactionLineId: string) => {
  try {
    const response = await axios.post(`/swap/registration/api/updatestatus`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateDelivery = async (payload: object, interactionLineId: string) => {
  try {
    const response = await axios.post(`/swap/registration/api/updatedelivery`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateRepairRequest = async (payload: object, interactionLineId: string, agreementId: string) => {
  try {
    const response = await axios.post(`/swap/claim/api/updaterepairrequest?agreementid=${agreementId}`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getappointmentslotsApi = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/claim/api/v2/getappointmentslots', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const addressStandardize = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/claim/api/standardizeaddress', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const processOveragePayment = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/claim/api/processoveragepayment', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getaspstorelistTOMAPi = async (
  lat: string,
  long: string,
  make: string,
  servicerequestid: string,
  interactionLineId: string
) => {
  try {
    return axios.get(
      `/swap/claim/api/getaspstorelist/${lat}/${long}/${make}?servicerequestid=${servicerequestid}`,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    )
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    console.error(error);
  }
};

export const validateImei = async (imei: string, deviceType: any, interactionLineId: string) => {
  try {
    return axios.get(
      // `/swap/claim/api/v2/details/${ClientAccountId}?agreementId=${AgreementId}`,
      `/swap/registration/api/quote/get-device/${imei}?devicetype=${deviceType}`,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const createInquiry = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/exceptionmanagement/api/v2/inquiry', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getscheduledavailabilityTOMApi = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post('/swap/claim/api/getscheduledavailability', payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getPaymentDetails = async (clientAccountId: string, interactionLineId: string) => { //1EDE5C010A82055689EAAFDE6A146967
  try {
    return axios.get(
      `/swap/payment/api/details/${clientAccountId}?timestamp=${new Date().getTime()}`,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getSecurityTokenApi = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post('/swap/registration/api/payment/get-security-token', payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getPCISecurityTokenNew = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/registration/api/payment/get-security-token', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const addPaymentMethod = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/payment/api/paymentmethod', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

// export const getCities = async (interactionLineId: string) => {
//   try {
//     return axios.get('/swap/registration/api/masterdata/getcities', {
//       headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
//     }).then(response => {
//       return response.data;
//     });
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getCitiesByPostalCode = async (interactionLineId: string, postalCode: string, language: string, clientId: string) => {
  try {
    return axios.get(`/swap/claim/api/getcitystatebyzipcode/${postalCode}?languagecode=${language}&clientid=${clientId}`, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const payENRfee = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/payment/api/payenrfee', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const deletePaymentMethod = async (payload: object, interactionLineId: string) => { //interactionLineId: string
  try {
    return axios.delete(
      '/swap/payment/api/paymentmethod', {
        headers: {
          ...headers, 'Asurion-interactionlineid': interactionLineId,
        },
        data: payload
        
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getQuote = async (payload: any, interactionLineId: string) => {
  try {
    return axios.post(
      `/swap/registration/api/quote/get-quote`, payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const saveRenewalDetails = async (payload: any, agreementId: string, interactionLineId: string) => {
  try {
    return axios.post(
      `/swap/registration/api/agreement/renewal/savedetails/${agreementId}`, payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateDeviceSynnex = async (payload: any, interactionLineId: string) => {
  try {
    return axios.post(
      `/swap/claim/api/synnex/updatedevice`, payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const collectPayment = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/registration/api/payment/collect-payment', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateHold = async (payload: object, workQueueId: string, interactionLineId: string) => {
  try {
    return axios.post(`/swap/claim/api/exceptionmanagement/v1/holds/${workQueueId}`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response?.data;
    })
  } catch (error) {
    console.error(error);
  }
};

export const updateDeviceDetailsApi = async (payload: object, interactionLineId: string) => {
  try {
     return axios.post(`/swap/registration/api/updatedevice`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    })
    .then((response) => {
      return response?.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getCategoryListApi = async (clientOfferId: string, InteractionLineId?: string) => {
  try {
    return axios
      .get(`/swap/devicemanagement/api/getcategorylist/${clientOfferId}`, {
        headers: { ...headers, 'Asurion-interactionlineid': InteractionLineId },
      })
      .then((response) => {
        return response?.data?.GetAssetCategoryResponse;
      });
  } catch (error) {
    console.error(error);
  }
};

export const getRegisterDeviceListApi = async (payload: object, interactionLineId: string, agreementId: string,) => {
  try {
    return axios.post(`/swap/devicemanagement/api/v1/getregistereddevice?agreementId=${agreementId}`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    })
    .then(response => {
      return response.data.RegisteredDeviceData;
    });
  } catch (error) {
    console.error(error);
  }
};


export const getMakeModel = async (make: string, ClientID: any, interactionLineId: string) => {
  try {
    return axios.get(
      `/swap/claim/api/getmakemodel/${ClientID}${make !== '' ? `?makeid=${make}` : ''}`,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const TCATAddressParse = async (payload: object, interactionLineId: string, agreementId: string) => {
  try {
    return axios.post(`/swap/claim/api/tcat/parseaddress?agreementId=${agreementId}`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then((response) => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const TCATCScheduleJob = async (payload: object, interactionLineId: string, agreementId: string) => {
  try {
    return axios.post(`/swap/claim/api/tcat/add?agreementId=${agreementId}`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then((response) => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const TCATCDeleteJob = async (payload: object, interactionLineId: string, agreementId: string) => {
  try {
    return axios.post(`/swap/claim/api/tcat/delete?agreementId=${agreementId}`, payload, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then((response) => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const createInquiryV1 = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/claim/api/inquiry', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

 export const getCityListOnLoad = async (interactionLineId: string, serviceRequestId: string, language: string, clientId: string) => {
   try {
     return axios.get(`/swap/claim/api/v2/getcitystatebyzipcode?zipcode=&languagecode${language}&clientid=${clientId}&city=&servicerequestid=${serviceRequestId}`, {
       headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
     }).then(response => {
       return response.data;
     });
   } catch (error) {
     console.error(error);
   }
 };

 export const getStatePostalCodeOnCity = async (interactionLineId: string, city: string, language: string, clientId: string, serviceRequestId: string) => {
  try {
    return axios.get(`/swap/claim/api/v2/getcitystatebyzipcode?zipcode=&languagecode${language}&clientid=${clientId}&city=${city}&servicerequestid=${serviceRequestId}`, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getInventoryData = async (clientOfferId: string, InteractionLineId?: string, isPhone ?:boolean) => {
  try {
    const flagBaseURl = isPhone ?`/swap/devicemanagement/api/getinventorydata/${clientOfferId}?phone=1`:`/swap/devicemanagement/api/getinventorydata/${clientOfferId}`
    return axios
      .get(flagBaseURl, {
        headers: { ...headers, 'Asurion-interactionlineid': InteractionLineId },
      })
      .then((response) => {
        return response?.data?.InventoryData;
      });
  } catch (error) {
    console.error(error);
  }
};

export const deviceRegistration = async (payload: object, interactionLineId: string) => {
  try {
    return axios.post(
      '/swap/devicemanagement/v1/deviceregistration', payload,
      {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      }
    ).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getCleaningServicesDevices = async (interactionLineId: string, clientOfferId: string, agreementId: string) => {
  try {
    return axios.get(`/swap/devicemanagement/api/getcleaningservicedevicelist/${clientOfferId}?agreementId=${agreementId}`, {
      headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
    }).then(response => {
      return response.data;
    });
  } catch (error) {
    console.error(error);
  }
};
export const getLoginTokenApi = async() => {
  try {
      return axios.get(import.meta.env.VITE_CLOUDCHERRY_LOGINTOKEN_API, {
        headers: {
          'x-api-key': import.meta.env.VITE_CLOUDCHERRY_WRAPPER_API_KEY
        }
      }).then(response => {
        return response.data.access_token
      }).catch(error => {
        console.log("CloudCherry wrapper API - login error:", error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const getSurveyTokenApi = async(token: string, payload: any) => {
  try {
      return axios.post(import.meta.env.VITE_CLOUDCHERRY_SURVEYTOKEN_API, payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-api-key': import.meta.env.VITE_CLOUDCHERRY_WRAPPER_API_KEY
        }
      }).then(response => {
        return response.data.id
      }).catch(error => {
        console.log("CloudCherry wrapper API - SurveyToken error:", error)
      })
  } catch (error) {
    console.log(error)
  }
}


export const updateCleaningServices = async (payload: object, interactionLineId: string) => {
  try {
    return axios
      .post(`/swap/claim/api/updatecleaningservicedetails`, payload, {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      })
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    console.error(error);
  }
};

export const submitCleaningServices = async (payload: object, interactionLineId: string) => {
  try {
    return axios
      .post(`/swap/claim/api/submitcleaningservice`, payload, {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      })
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    console.error(error);
  }
};

export const cancelCleaningServices = async (payload: object, interactionLineId: string) => {
  try {
    return axios
      .post(`/swap/claim/api/v1/cancelcleaningservice`, payload, {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      })
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    console.error(error);
  }
};

export const createECPayPaymentRequest = async (payload: object, interactionLineId: string) => {
  try {
    return axios
      .post(`/swap/claim/ecpay/api/createpayment`, payload, {
        headers: { ...headers, 'Asurion-interactionlineid': interactionLineId },
      })
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    console.error(error);
  }
};
