import {
    getCarrierByDomain,
    getTypeByDomain,
    getUrlByTypeAndEnv,
    iMyAsurionPortalType,
    useFeature,
} from '@MGPD/myasurion-shared';
import { TextScript } from '@MGPD/myasurion-shared/components/localization';
import {
    AccountInfo,
    useAccount,
    // useFeatureIsOn,
    useIsDisabledFeature,
    useSessionStorage,
} from '@MGPD/myasurion-shared/hooks';
import { useConfig } from '@MGPD/myasurion-shared/modules/config';
import { Mixpanel } from '@MGPD/myasurion-shared/utils/mixpanel';
import { ArrowForwardIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    HStack,
    Icon,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Spinner,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { MouseEvent, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { smartScanTokenUrlKey } from '../modules/config/constants';
import { DeviceSelectorProps, MdnDevice } from '../types/types';
import { formatMdnString, millisToDate, processContentfulImageToWebp } from '../utils';
import DynamicDeviceCard, { DeviceCardImageElement } from '../wip/DynamicDeviceCard';
import { TextBold } from './ReusableComponents';
import { useNavigate } from 'react-router-dom';

const processClaimStatusTextKey = (
    claimStatus:
        | 'draft'
        | 'completed'
        | 'cancelled'
        | 'submitted'
        | 'approved'
        | 'backorder'
        | undefined,
    deviceRegistered?: boolean
): {
    claimStatusText: string;
    claimStatusKey:
        | 'ResumeRequest'
        | 'ManageRequest'
        | 'UpdateDeviceDetails'
        | 'UpdateAnchorDevice';
} => {
    let claimStatusText = 'Update Device Details',
        claimStatusKey:
            | 'ResumeRequest'
            | 'ManageRequest'
            | 'UpdateDeviceDetails'
            | 'UpdateAnchorDevice' = 'UpdateDeviceDetails';
    if (claimStatus === 'draft') {
        claimStatusText = 'Manage Request';
        claimStatusKey = 'ResumeRequest';
    }

    if (claimStatus === 'submitted' || claimStatus === 'approved' || claimStatus === 'backorder') {
        claimStatusText = 'Manage Request';
        claimStatusKey = 'ManageRequest';
    }

    if (claimStatus === 'completed' || claimStatus === 'cancelled') {
        claimStatusText = 'Update Device Details';
        claimStatusKey = 'UpdateDeviceDetails';
    }

    if (deviceRegistered !== undefined && deviceRegistered === false) {
        claimStatusText = 'Update Device Details';
        claimStatusKey = 'UpdateAnchorDevice';
    }

    return {
        claimStatusText,
        claimStatusKey,
    };
};

const buttonText = (
    t: 'byod' | 'ppe' | 'default',
    cs?: 'draft' | 'completed' | 'cancelled' | 'submitted' | 'approved' | 'backorder',
    deviceRegistered?: boolean
) => {
    const [smartScanTokenUrl] = useSessionStorage(smartScanTokenUrlKey, '');
    const getSmartScanToken: string = smartScanTokenUrl;

    if (t?.toLowerCase() === 'byod' || t?.toLowerCase() === 'ppe') {
        if (getSmartScanToken?.toString()?.toLowerCase()?.includes('error')) {
            return (
                <TextScript
                    text={getSmartScanToken?.replace('Error: ', '') ?? 'Device not found'}
                    translationKey={'DeviceSelector/DeviceNotFound'}
                />
            );
        }

        return (
            <TextScript
                text={'Start device assessment'}
                translationKey={'DeviceSelector/StartDeviceAssessment'}
            />
        );
    } else {
        const { claimStatusText, claimStatusKey } = processClaimStatusTextKey(cs, deviceRegistered);
        return (
            <TextScript
                text={claimStatusText}
                translationKey={`DeviceSelector/${claimStatusKey}`}
            />
        );
    }
};

const requestRedirectionDetails = ({
    warrantyType,
    carrier,
    incidentType,
    portalType,
}: {
    warrantyType?: 'IW' | 'IW-OEM';
    carrier: string;
    incidentType?: string;
    portalType: iMyAsurionPortalType;
}): { urlHref: string; isExternal: boolean } => {
    let portalToRedirect: 'device_protection' | 'repair' = 'device_protection';
    let isExternal = false;
    if (incidentType?.toLowerCase() === 'swap' || incidentType?.toLowerCase() === 'malfunction') {
        portalToRedirect = 'device_protection';
        // Check if warranty type is either IW or IW-OEM
        if (incidentType?.toLowerCase() === 'malfunction' && warrantyType === 'IW') {
            portalToRedirect = 'device_protection';
        }
        if (incidentType?.toLowerCase() === 'malfunction' && warrantyType === 'IW-OEM') {
            portalToRedirect = 'repair';
        }
        if (portalType === 'repair') {
            isExternal = true;
        }
    } else {
        portalToRedirect = 'repair';
        if (portalType === 'device_protection') {
            isExternal = true;
        }
    }
    const disableApplLoad = getCarrierByDomain()?.toLowerCase() === 'starhub'//useFeatureIsOn('disableApplLoad');
    let url = disableApplLoad ? '/myplansandservicerequests' : '/swap/myplansandservicerequests'
    const urlRef = getUrlByTypeAndEnv(carrier, portalToRedirect, import.meta.env.VITE_ENV) + url;

    return {
        urlHref: urlRef,
        isExternal: isExternal,
    };
};
export const DeviceCardDetailsElement = ({
    type = 'default',
    status,
    // showMore,
    // selectedValue,
    serviceRequestBtn,
    isLoading,
    agreementClaimStatus,
    isUpdateDeviceInProgress,
    isAgreementSuspended,
    incidentType,
    warrantyType,
    deviceRegistered,
}: // handleShowMore,
// isLoading,
{
    type: 'ppe' | 'byod' | 'default';
    status?: 'pending' | 'under review' | 'approved' | 'processing';
    showMore: boolean;
    selectedValue?: MdnDevice;
    serviceRequestBtn: any;
    handleShowMore: () => void;
    isLoading?: boolean;
    agreementClaimStatus?:
        | 'draft'
        | 'completed'
        | 'cancelled'
        | 'submitted'
        | 'approved'
        | 'backorder';
    isUpdateDeviceInProgress?: boolean;
    isAgreementSuspended?: boolean;
    incidentType?: string;
    warrantyType?: 'IW' | 'IW-OEM'; // DP and Repair
    deviceRegistered?: boolean;
}) => {
    // const { data } = useConfig();
    // const { theme } = data;
    // const { colors } = theme;
    const disableApplLoad = getCarrierByDomain()?.toLowerCase() === 'starhub'//useFeatureIsOn('disableApplLoad');
    
    const [smartScanTokenUrl] = useSessionStorage(smartScanTokenUrlKey, '');
    const getSmartScanToken: string = smartScanTokenUrl;
    const { onOpen, isOpen, onClose } = useDisclosure();
    const carrier = getCarrierByDomain();
    const portalType = getTypeByDomain();
    const { version: ctaVersion } = useFeature('cta');
    const navigate = useNavigate();

    const onClickHandler = ({
        claimStatus,
        onModalOpen,
    }: {
        event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>;
        claimStatus?: 'draft' | 'completed' | 'cancelled' | 'submitted' | 'approved' | 'backorder';
        onModalOpen?: () => void;
    }) => {
        const getRedirectionLocation = (type: 'device_protection' | 'repair') => {
            try {
                return getUrlByTypeAndEnv(
                    carrier,
                    type,
                    import.meta.env.VITE_ENV as unknown as 'sqa' | 'dev' | 'prod' | 'local'
                );
            } catch (error) {
                console.error(error);
                return false;
            }
        };

        if (type.toLowerCase() === 'ppe' && getSmartScanToken) {
            window.location.href = getSmartScanToken;
        }

        if (type?.toLowerCase() !== 'byod' && type?.toLowerCase() !== 'ppe') {
            if (claimStatus === 'draft') {
                // DP Portal and repair incident
                // Repair Portal and swap incident

                if (
                    incidentType &&
                    ((portalType === 'device_protection' &&
                        getRedirectionLocation('repair') &&
                        incidentType?.toLowerCase() !== 'swap' &&
                        incidentType?.toLowerCase() !== 'malfunction' &&
                        incidentType?.toLowerCase() !== 'replacement') ||
                        (portalType === 'device_protection' &&
                            getRedirectionLocation('repair') &&
                            incidentType?.toLowerCase() === 'malfunction' &&
                            warrantyType === 'IW-OEM') ||
                        (portalType === 'repair' &&
                            getRedirectionLocation('device_protection') &&
                            incidentType?.toLowerCase() === 'swap') ||
                        (portalType === 'repair' &&
                            getRedirectionLocation('device_protection') &&
                            incidentType?.toLowerCase() === 'malfunction' &&
                            warrantyType === 'IW'))
                ) {
                    onModalOpen && onModalOpen();
                } else {
                    disableApplLoad ? navigate('/myplansandservicerequests') : window.location.href = '/swap/myplansandservicerequests';
                }
            } else if (
                claimStatus === 'submitted' ||
                claimStatus === 'approved' ||
                claimStatus === 'backorder'
            ) {
                // DP Portal and repair incident
                // Repair Portal and swap incident
                if (
                    incidentType &&
                    ((portalType === 'device_protection' &&
                        getRedirectionLocation('repair') &&
                        incidentType?.toLowerCase() !== 'swap' &&
                        incidentType?.toLowerCase() !== 'malfunction' &&
                        incidentType?.toLowerCase() !== 'replacement') ||
                        (portalType === 'device_protection' &&
                            getRedirectionLocation('repair') &&
                            incidentType?.toLowerCase() === 'malfunction' &&
                            warrantyType === 'IW-OEM') ||
                        (portalType === 'repair' &&
                            getRedirectionLocation('device_protection') &&
                            incidentType?.toLowerCase() === 'swap') ||
                        (portalType === 'repair' &&
                            getRedirectionLocation('device_protection') &&
                            incidentType?.toLowerCase() === 'malfunction' &&
                            warrantyType === 'IW'))
                ) {
                    onModalOpen && onModalOpen();
                } else {
                    disableApplLoad ? navigate('/myplansandservicerequests') : window.location.href = '/swap/myplansandservicerequests';
                }
            } else {
                if (deviceRegistered === false) {
                    disableApplLoad ? navigate('/updateAnchorDevice') : window.location.href = '/swap/updateAnchorDevice';
                    // window.location.href = '/swap/updateAnchorDevice';
                } else {
                    disableApplLoad ? navigate('/updateDevice') : window.location.href = '/swap/updateDevice';
                    // window.location.href = '/swap/updateDevice';
                }
            }
        }
    };

    return (
        <>
            {status && status !== 'pending' && (
                <Box></Box>
                // <Box w="full" px={4} py={3} mb={2} bg="#F0F0F5">
                //     <TextBold>
                //         {status === 'processing' ? '<Pick-up/Delivery>' : 'SR submitted'} on{' '}
                //         {'MMM DD, YYYY '}
                //     </TextBold>
                //     <Text>
                //         {status === 'approved' || status === 'processing'
                //             ? 'Message goes here... Our courier will come to <pick-up/return/deliver> your device between 3pm–5pm today.'
                //             : 'Message goes here...'}
                //     </Text>
                // </Box>
            )}
            {status && status === 'under review' && (
                <Box w="full" px={4} py={3} mb={2} bg="#F0F0F5">
                    {status === 'under review' && (
                        <TextBold>Device Assessment is being reviewed</TextBold>
                    )}
                    {status === 'under review' && (
                        <Text>
                            Our team is reviewing your submission and will revert with a decision
                            shortly
                        </Text>
                    )}
                </Box>
            )}
            {/* {showMore && status === 'pending' && (
                <AdditionalDeviceInfoPanel
                    serviceRequestBtn={serviceRequestBtn}
                    selectedValue={selectedValue}
                    type={type}
                />
            )}
            {showMore && type === 'default' && (
                <AdditionalDeviceInfoPanel
                    serviceRequestBtn={serviceRequestBtn}
                    selectedValue={selectedValue}
                    type={type}
                />
            )} */}

            <Box w="full" mt="2" mb="4" p={4}>
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <ModalCloseButton />
                        </ModalHeader>
                        <ModalBody>
                            <Text fontWeight="bold">
                                <TextScript
                                    text={`${
                                        incidentType?.toLowerCase() === 'swap'
                                            ? 'Swap'
                                            : incidentType?.toLowerCase() === 'malfunction' &&
                                              warrantyType === 'IW'
                                            ? 'Malfunction'
                                            : 'Repair'
                                    } Request in Progress`}
                                    translationKey={`DeviceSelector/Popup_${incidentType}_ModalHeader`}
                                />
                            </Text>
                            <Text>
                                <TextScript
                                    text={`You have an existing ${
                                        incidentType?.toLowerCase() === 'swap'
                                            ? 'swap'
                                            : incidentType?.toLowerCase() === 'malfunction' &&
                                              warrantyType === 'IW'
                                            ? 'malfunction'
                                            : 'repair'
                                    } request which is in progress. To manage this, please login to`}
                                    translationKey={`DeviceSelector/Popup_${incidentType}_ModalBody`}
                                />{' '}
                                <Link
                                    href={
                                        requestRedirectionDetails({
                                            incidentType,
                                            carrier,
                                            warrantyType,
                                            portalType,
                                        }).urlHref
                                    }
                                    isExternal={
                                        requestRedirectionDetails({
                                            incidentType,
                                            carrier,
                                            warrantyType,
                                            portalType,
                                        }).isExternal
                                    }
                                    textDecoration="underline"
                                >
                                    <TextScript
                                        text={
                                            incidentType?.toLowerCase() === 'swap' ||
                                            (incidentType?.toLowerCase() === 'malfunction' &&
                                                warrantyType === 'IW')
                                                ? 'Asurion TechCare'
                                                : 'Repair Service'
                                        }
                                        translationKey={`DeviceSelector/${
                                            incidentType?.toLowerCase() === 'swap' ||
                                            (incidentType?.toLowerCase() === 'malfunction' &&
                                                warrantyType === 'IW')
                                                ? 'device_protection'
                                                : 'repair'
                                        }-Device care`}
                                    />
                                </Link>
                            </Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                {ctaVersion === 2 &&
                processClaimStatusTextKey(agreementClaimStatus)?.claimStatusKey ===
                    'UpdateDeviceDetails' ? null : (
                    <Skeleton isLoaded={!isLoading}>
                        <Flex justifyContent={'center'}>
                        <Button
                            {...serviceRequestBtn}
                            onClick={(e) => {
                                if (
                                    (!agreementClaimStatus ||
                                        agreementClaimStatus === 'cancelled') &&
                                    isUpdateDeviceInProgress
                                ) {
                                    e.preventDefault();
                                    return;
                                }
                                onClickHandler({
                                    event: e,
                                    claimStatus: agreementClaimStatus,
                                    onModalOpen: onOpen,
                                });
                            }}
                            isDisabled={
                                isAgreementSuspended ||
                                ((!agreementClaimStatus || agreementClaimStatus === 'cancelled') &&
                                    isUpdateDeviceInProgress) ||
                                (type?.toLowerCase() === 'ppe' && status === 'under review') ||
                                getSmartScanToken?.toString()?.toLowerCase()?.includes('error')
                            }
                            width={{ base: 'full', lg: '340px' }}
                            // minW="254px"
                        >
                            {buttonText(type, agreementClaimStatus, deviceRegistered)}
                        </Button>
                        </Flex>
                    </Skeleton>
                )}
            </Box>
            {/* {selectedValue && status !== 'under review' && (
                <Box
                    w="full"
                    textAlign="center"
                    py={3}
                    display={'flex'}
                    flexFlow="column nowrap"
                    alignItems="center"
                >
                    <Skeleton isLoaded={!isLoading} w="max-content">
                        <Text
                            onClick={handleShowMore}
                            fontWeight="bold"
                            textDecor="underline"
                            color={colors.accent}
                        >
                            {showMore ? 'Hide Details' : 'More Details'}
                        </Text>
                    </Skeleton>
                </Box>
            )} */}
        </>
    );
};

// @DevNote
// The Plan:
// If getDevices return [{ mdn: "0002", index: "1"},{ mdn: "0001", index: "0"}, { mdn: "0002", index: "0"}]
// This should return   [{ mdn: "0002", index: "1"},{ mdn: "0002", index: "0"}, { mdn: "0001", index: "0"}]
// Solution => return MDN order while sorting through indices
const customDeviceSort = (a: MdnDevice, b: MdnDevice) => {
    // if (a?.mdn < b?.mdn) return -1;
    // if (a?.mdn > b?.mdn) return 1;
    // if (a?.index && b?.index) {
    //     if (a?.index < b.index) return -1;
    //     if (a?.index > b.index) return 1;
    // }
    // return 0;
    if (a?.mdn === b?.mdn || a?.serialNo === b?.serialNo) {
        if (a?.index && b?.index) {
            return a.index - b.index;
        }
    }
    return 0;
};

const DeviceSelector = ({
    selectedValue,
    setSelectedValue,
    viewAllDeviceText,
    deviceList,
    isLoading,
    isAgreementProcessing = false,
    hasUpgrade = false,
    activeSRNumber,
    agreementClaimStatus,
    isAgreementSuspended,
    isDocuments = false,
    incidentType,
    warrantyType,
    isPremiumUser = false,
    agreementStatus,
    deviceRegistered,
    isSecondDeviceRegistered,
    devicecap,
    hideAddButton,
    ...props
}: DeviceSelectorProps) => {
    const disableApplLoad = getCarrierByDomain()?.toLowerCase() === 'starhub'//useFeatureIsOn('disableApplLoad');
    const navigate = useNavigate();
    const { isUpdateDeviceInProgress } = props;
    // images, titles and other branding should be coming from useConfig of shared
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { serviceRequestBtn } = theme?.components || {};
    const queryClient = useQueryClient();
    const [showMore, setShowMore] = useState<boolean>(false);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState<number>(0);
    const getDevicesData: MdnDevice[] = queryClient.getQueryData(['getDevicesData']) ?? deviceList;

    // const getSmartScanToken: string = queryClient.getQueryData(['smartScanToken']) ?? '';

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleMdnChange = (index: number) => {
        setShowMore(false);
        const selectedMdnDevice = deviceList
            ?.sort(customDeviceSort)
            ?.filter(
                (e, i, s) =>
                    i ===
                    s.findIndex((fmdn) =>
                        fmdn.serialNo ? fmdn?.serialNo === e?.serialNo : fmdn.mdn === e.mdn
                    )
            )[index];
        setSelectedValue(selectedMdnDevice);
        // setSelectedDeviceIndex(index);
    };

    const handleDeviceChange = (index: number) => {
        setShowMore(false);
        // const selectedMdn = deviceList
        //     ?.sort(customDeviceSort)
        //     ?.filter((e, i, s) => i === s.findIndex((fmdn) => fmdn.mdn === e.mdn));
        const selectedDevice = deviceList
            .filter((fdl) => fdl?.mdn === selectedValue?.mdn)
            .find((fd) => Number(fd?.index ?? 0) === index);
        setSelectedValue(selectedDevice);
    };

    const { devices }: Record<string, any> = theme;
    const [account] = useAccount<AccountInfo>({ profileId: '', userId: '' });
    const isViewAllPlansDisabled = useIsDisabledFeature('portal', 'btnViewAllPlans');
    useEffect(() => {
        // const selectedDeviceIndex: number = deviceList.findIndex(
        //     (device: MdnDevice) => selectedValue === device
        // );
        const deviceIndex: number = deviceList
            ?.sort(customDeviceSort)
            ?.filter(
                (e, i, s) =>
                    i ===
                    s.findIndex((fmdn) =>
                        fmdn.serialNo ? fmdn?.serialNo === e?.serialNo : fmdn.mdn === e.mdn
                    )
            )
            ?.findIndex((device: MdnDevice) =>
                device.serialNo
                    ? selectedValue?.serialNo === device?.serialNo
                    : selectedValue?.mdn === device.mdn
            );
        setSelectedDeviceIndex(deviceIndex);
        setShowMore(selectedValue?.type && selectedValue.status === 'pending' ? true : false);
    }, [selectedValue?.type, deviceList]);

    // const isSrDetailsEmpty: boolean = selectedValue?.srDetails
    //     ? Object.keys(selectedValue.srDetails).length === 0
    //     : false;
    // console.log(
    //     'isAgreementSuspended: ',
    //     isAgreementSuspended,
    //     '\nagreementClaimStatus: ',
    //     agreementClaimStatus,
    //     '\nselectedValue?.type: ',
    //     selectedValue?.type,
    //     '\nisAgreementProcessing: ',
    //     isAgreementProcessing,
    //     '\nselectedValue?.status: ',
    //     selectedValue?.status
    // );

    return (
        <Box id={'myasurion-portal-deviceselector'} w={{ base: 'full' }} p={0} alignSelf={'start'}>
            <HStack flex="true" spacing={0}>
                <HStack w="full" borderBottom={`2px solid #000`}>
                    <HStack
                        bg="black"
                        p={5}
                        flex={2}
                        justifyContent={{ base: 'center', lg: 'start' }}
                        alignContent="center"
                        borderBottom={`2px solid #000`}
                        borderTopRadius={{ base: 0, lg: '8px' }}
                    >
                        <Skeleton isLoaded={!isLoading} w={isLoading ? '133px' : 'auto'}>
                            <Menu>
                                <MenuButton
                                    defaultValue={selectedDeviceIndex}
                                    // placeholder="Select option"
                                    color="white"
                                    fontWeight="bold"
                                    textDecoration="underline"
                                    width="auto"
                                    disabled={
                                        !(
                                            selectedValue &&
                                            (selectedValue.mdn || selectedValue?.serialNo) &&
                                            !isLoading &&
                                            !isViewAllPlansDisabled
                                        ) //TODO: change feature flag
                                    }
                                >
                                    {selectedValue &&
                                    (selectedValue.mdn || selectedValue?.serialNo) &&
                                    !isLoading ? (
                                        selectedValue?.serialNo && !disableApplLoad ? (
                                            <TextScript
                                                text="My devices"
                                                translationKey="DeviceSelector/MyDevices"
                                            />
                                        ) : (
                                            formatMdnString(
                                                `${
                                                    selectedValue?.callingCode
                                                        ? `(${selectedValue.callingCode}) `
                                                        : selectedValue?.countryCallingCode ? `(${selectedValue.countryCallingCode}) ` : ''
                                                }${selectedValue?.mdn}`
                                            )
                                        )
                                    ) : (
                                        <Spinner />
                                    )}
                                    {!isViewAllPlansDisabled && //TODO: change feature flag
                                        selectedValue &&
                                        selectedValue.mdn &&
                                        !isLoading &&
                                        getDevicesData
                                            ?.sort(customDeviceSort)
                                            ?.filter(
                                                (e, i, s) =>
                                                    i ===
                                                    s.findIndex((fmdn) =>
                                                        fmdn.serialNo
                                                            ? fmdn?.serialNo === e?.serialNo
                                                            : fmdn.mdn === e.mdn
                                                    )
                                            )?.length > 1 && <ChevronDownIcon boxSize="18px" />}
                                </MenuButton>
                                {Array.isArray(getDevicesData) &&
                                    getDevicesData
                                        ?.sort(customDeviceSort)
                                        ?.filter(
                                            (e, i, s) =>
                                                i ===
                                                s.findIndex((fmdn) =>
                                                    fmdn?.serialNo
                                                        ? fmdn?.serialNo === e?.serialNo
                                                        : fmdn.mdn === e.mdn
                                                )
                                        )?.length > 1 && (
                                        <MenuList
                                            zIndex={
                                                selectedValue?.type?.toLowerCase() === 'ppe'
                                                    ? '999997'
                                                    : '99'
                                            }
                                        >
                                            {Array.isArray(getDevicesData) &&
                                                getDevicesData
                                                    ?.sort(customDeviceSort)
                                                    ?.filter(
                                                        (e, i, s) =>
                                                            i ===
                                                            s.findIndex((fmdn) =>
                                                                fmdn?.serialNo
                                                                    ? fmdn?.serialNo === e?.serialNo
                                                                    : fmdn.mdn === e.mdn
                                                            )
                                                    )
                                                    ?.map((device, index) => (
                                                        <MenuItem
                                                            key={`menu-item-devicelist-${index}`}
                                                            value={index}
                                                            onClick={() => handleMdnChange(index)}
                                                            style={
                                                                // device?.serialNo ===
                                                                //     selectedValue?.serialNo ||
                                                                (
                                                                    device?.serialNo
                                                                        ? device?.serialNo &&
                                                                          device.serialNo ===
                                                                              selectedValue?.serialNo
                                                                        : device?.mdn &&
                                                                          device.mdn ===
                                                                              selectedValue?.mdn
                                                                )
                                                                    ? {
                                                                          background:
                                                                              colors.primary,
                                                                          color:
                                                                              colors.onPrimary ??
                                                                              'black',
                                                                      }
                                                                    : {}
                                                            }
                                                            fontWeight={
                                                                device?.isAnchorDevice === true
                                                                    ? 'bold'
                                                                    : 'normal'
                                                            }
                                                        >
                                                            {device?.isAnchorDevice !==
                                                            undefined && getCarrierByDomain()?.toLowerCase() !== 'starhub' ? (
                                                                device?.isAnchorDevice === true ? (
                                                                    <>
                                                                        {'['}
                                                                        <TextScript
                                                                            text={'Primary'}
                                                                            translationKey={
                                                                                'Global/Primary'
                                                                            }
                                                                        />
                                                                        {`] `}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {'['}
                                                                        <TextScript
                                                                            text={'Secondary'}
                                                                            translationKey={
                                                                                'Global/Secondary'
                                                                            }
                                                                        />
                                                                        {`] `}
                                                                    </>
                                                                )
                                                            ) : (
                                                                ''
                                                            )}

                                                            {device?.serialNo
                                                                ? `${device?.make} ${device?.model}`
                                                                : formatMdnString(
                                                                      `${
                                                                          device.callingCode
                                                                              ? `(${device.callingCode}) `
                                                                              : device?.countryCallingCode ? `(${device?.countryCallingCode})` : ''
                                                                      }${device.mdn}`
                                                                  )}
                                                        </MenuItem>
                                                    ))}
                                        </MenuList>
                                    )}
                            </Menu>
                        </Skeleton>
                    </HStack>
                    {!isViewAllPlansDisabled && !isDocuments && (
                        <HStack
                            bg="white"
                            p={4}
                            flex={2}
                            justifyContent="end"
                            alignContent="center"
                            borderTopRadius={{ base: 0, lg: '8px' }}
                            w="full"
                        >
                            <Skeleton isLoaded={!isLoading} minH="29px">
                                <Text
                                    as={Link}
                                    // href={'/portal/plan'}
                                    onClick={() =>
                                        (Mixpanel.sendMixpanelEvent(
                                            'ASMA_Portal_DeviceSelector_ClickPlanDetailsRedirect',
                                            { userId: account.userId }
                                        ),
                                        navigate('/portal/plan'))
                                    }
                                    // href={'/swap/myplansandservicerequests'}
                                    color={colors.accent}
                                    textDecor="none"
                                    fontWeight={700}
                                    _selected={{
                                        color: 'white',
                                        bg: 'black',
                                        textDecor: 'none',
                                    }}
                                >
                                    <TextScript
                                        text={
                                            selectedValue?.status === 'approved'
                                                ? 'Plan details'
                                                : viewAllDeviceText ?? ''
                                        }
                                        translationKey={'DeviceSelector/viewAllPlanText'}
                                    />
                                    <ArrowForwardIcon ml={2} boxSize="16px" />
                                </Text>
                            </Skeleton>
                        </HStack>
                    )}
                </HStack>
            </HStack>
            <HStack p={{ base: 4, lg: 0 }} justifyContent="center">
                <Box
                    w="100%"
                    maxW={{ base: '', sm: '345px', lg: 'full' }}
                    borderRadius={{ base: 8, lg: 0 }}
                    zIndex={selectedValue?.type?.toLowerCase() === 'ppe' ? '99999' : 'unset'}
                    background={'white'}
                >
                    <Swiper
                        navigation={{
                            enabled: true,
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        modules={[Pagination, EffectFade, Navigation]}
                        effect="fade"
                        fadeEffect={{ crossFade: true }}
                        onNavigationNext={(e) => handleDeviceChange(e?.activeIndex)}
                        onNavigationPrev={(e) => handleDeviceChange(e?.activeIndex)}
                    >
                        {Array.isArray(getDevicesData) &&
                            getDevicesData
                                ?.filter((fgdd) =>
                                    fgdd?.serialNo
                                        ? fgdd?.serialNo === selectedValue?.serialNo
                                        : fgdd.mdn === selectedValue?.mdn
                                )
                                ?.map((mgdd, mgddi) => {
                                    selectedValue = mgdd;
                                    return (
                                        <SwiperSlide key={`${mgdd?.agreementId}-${mgddi}`}>
                                            <DynamicDeviceCard
                                                isLoading={isLoading}
                                                plan={selectedValue?.plan || ''}
                                                mdn={`${
                                                    selectedValue?.callingCode
                                                        ? `(${selectedValue?.callingCode}) `
                                                        : selectedValue?.countryCallingCode ? `(${selectedValue?.countryCallingCode})` : ''
                                                }${selectedValue?.mdn}`}
                                                model={selectedValue?.catalogName || selectedValue?.model || ''}
                                                imei={selectedValue?.imei || ''}
                                                make={selectedValue?.make || ''}
                                                serial={
                                                    selectedValue?.serialNumber ||
                                                    selectedValue?.serialNo ||
                                                    ''
                                                }
                                                warranty={`${
                                                    selectedValue?.startDate
                                                        ? millisToDate(
                                                              selectedValue?.startDate,
                                                              'zh-TW'
                                                          )
                                                        : ''
                                                } to ${
                                                    selectedValue?.endDate
                                                        ? millisToDate(
                                                              selectedValue?.endDate,
                                                              'zh-TW'
                                                          )
                                                        : ''
                                                }`}
                                                oemStartDate={
                                                    selectedValue?.oemStartDate
                                                        ? millisToDate(
                                                              selectedValue?.oemStartDate,
                                                              'zh-TW'
                                                          )
                                                        : ''
                                                }
                                                oemEndDate={
                                                    selectedValue?.oemEndDate
                                                        ? millisToDate(
                                                              selectedValue?.oemEndDate,
                                                              'zh-TW'
                                                          )
                                                        : ''
                                                }
                                                extendedWarrantyStartDate={
                                                    selectedValue?.extendedWarrantyStartDate
                                                        ? millisToDate(
                                                              selectedValue?.extendedWarrantyStartDate,
                                                              'zh-TW'
                                                          )
                                                        : ''
                                                }
                                                extendedWarrantyEndDate={
                                                    selectedValue?.extendedWarrantyEndDate
                                                        ? millisToDate(
                                                              selectedValue?.extendedWarrantyEndDate,
                                                              'zh-TW'
                                                          )
                                                        : ''
                                                }
                                                redirectUrl={selectedValue?.redirectUrl || ''}
                                                assetId={selectedValue?.assetId || ''}
                                                status={
                                                    isAgreementSuspended
                                                        ? 'suspended'
                                                        : agreementClaimStatus ||
                                                          (!selectedValue?.type
                                                              ? isAgreementProcessing
                                                                  ? 'processing'
                                                                  : selectedValue?.status
                                                              : selectedValue?.status)
                                                }
                                                type={selectedValue?.type || 'default'}
                                                imageEl={
                                                    <DeviceCardImageElement
                                                        imageUrl={
                                                            selectedValue?.imageUrl
                                                                ? processContentfulImageToWebp(
                                                                      selectedValue.imageUrl
                                                                  )
                                                                : getCarrierByDomain() ===
                                                                  'cht_home'
                                                                ? undefined
                                                                : processContentfulImageToWebp(
                                                                      devices.iphone13Pro
                                                                  )
                                                        }
                                                    />
                                                }
                                                detailsEl={
                                                    <DeviceCardDetailsElement
                                                        type={selectedValue?.type || 'default'}
                                                        showMore={showMore}
                                                        serviceRequestBtn={serviceRequestBtn}
                                                        handleShowMore={handleShowMore}
                                                        status={
                                                            !selectedValue?.type
                                                                ? isAgreementProcessing
                                                                    ? 'processing'
                                                                    : selectedValue?.status
                                                                : selectedValue?.status
                                                        }
                                                        selectedValue={selectedValue}
                                                        isLoading={isLoading}
                                                        agreementClaimStatus={agreementClaimStatus}
                                                        isUpdateDeviceInProgress={
                                                            isUpdateDeviceInProgress
                                                        }
                                                        isAgreementSuspended={isAgreementSuspended}
                                                        incidentType={incidentType}
                                                        warrantyType={warrantyType}
                                                        deviceRegistered={deviceRegistered}
                                                    />
                                                }
                                                showMoreDetails={true}
                                                viewAllDeviceText={viewAllDeviceText}
                                                hasUpgrade={hasUpgrade}
                                                activeSRNumber={activeSRNumber}
                                                isDocuments={isDocuments}
                                                isUpdateDeviceInProgress={isUpdateDeviceInProgress}
                                                isAgreementSuspended={isAgreementSuspended}
                                                programName={selectedValue?.programName}
                                                clientProductSkuNbr={
                                                    selectedValue?.clientProductSkuNbr
                                                }
                                                isPremiumUser={isPremiumUser}
                                                deviceCount={deviceList?.length}
                                                accountStatus={agreementStatus}
                                                warrantyStatus={selectedValue?.warrantyStatus}
                                                deviceRegistered={deviceRegistered}
                                                isSecondDeviceRegistered={isSecondDeviceRegistered}
                                                devicecap={devicecap}
                                                hideAddButton={hideAddButton}
                                                color={selectedValue?.color}
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                        <Box
                            className="swiper-button-next"
                            transform="rotateY(180deg)"
                            boxSize={'50px'}
                        />
                        <Box className="swiper-button-prev" boxSize={'50px'}></Box>
                    </Swiper>
                </Box>
            </HStack>
            {isPremiumUser &&
                deviceList?.length < 2 &&
                isSecondDeviceRegistered === true &&
                deviceRegistered === true &&
                hideAddButton === false && (
                    <Box py={12} borderBottom={{ lg: '1px solid #A5AAAF' }}>
                        <Flex minW="375px" p="10px 16px" alignItems="center" gap="8px">
                            <Icon
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                            >
                                <path
                                    d="M13 15.5V11.5C13 10.948 12.553 10.5 12 10.5H10V12.5H11V15.5H9V17.5H15V15.5H13Z"
                                    fill="black"
                                />
                                <path
                                    d="M13.25 8.5C13.25 9.19036 12.6904 9.75 12 9.75C11.3096 9.75 10.75 9.19036 10.75 8.5C10.75 7.80964 11.3096 7.25 12 7.25C12.6904 7.25 13.25 7.80964 13.25 8.5Z"
                                    fill="black"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 22.5C6.486 22.5 2 18.015 2 12.5C2 6.987 6.486 2.5 12 2.5C17.514 2.5 22 6.986 22 12.5C22 18.015 17.514 22.5 12 22.5ZM12 4.5C7.589 4.5 4 8.089 4 12.5C4 16.911 7.589 20.5 12 20.5C16.411 20.5 20 16.911 20 12.5C20 8.089 16.411 4.5 12 4.5Z"
                                    fill="black"
                                />
                            </Icon>
                            <Text lineHeight={'132%'} fontWeight="400">
                                <TextScript
                                    text={
                                        'Your secondary device is undergoing review and not yet visible in the page. This process will be done within 3 business days.'
                                    }
                                    translationKey={'DeviceSelector/NotifDeviceUnderReview'}
                                />
                            </Text>
                        </Flex>
                    </Box>
                )}
        </Box>
    );
};

export default DeviceSelector;
