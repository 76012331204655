/* eslint-disable no-console */
import { SubHeader, TextScript, getTextScript, useConfig, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Image, Link, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../../Scripts/asurion-script.json';
import Translationkeys from '../../Scripts/translationsKeys.json';
import barcodeImg from '../../icons/barcode.jpg';
import cardImg from '../../icons/card.png';
import TextInput from '../component/TextInput';
import ECPayStatusAlert from './ECPayStatusAlert';
import PaymentBreakdown from './PaymentBreakDown';
import CancelSrPopup from '../myclaims/CancelSrPopup';
import { useCentralStore } from '../../store/appContext';
import { InvoiceOption } from '../../types/types';
import { CLEANING_SERVICE_INVOICE_TYPE } from '../../utils/constant';
import { getCurrencySymbol } from '../../utils/localization-helper';
import ActionTypes from '../../store/actionTypes';
import { createECPayPaymentRequest } from '../../services/api';
import { saveError, showErrorPage } from '../../utils/utills';
import MainStackLayout from '../../layout/MainStackLayout';

function SelectInvoice() {
  const globalState = useCentralStore();
  const navigate = useNavigate();

  const { isTranslationsLoading, language } = useTextScript();

  const [currencySymbol, setCurrencySymbol] = useState('');

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [invoiceOption, setInvoiceOption] = useState<InvoiceOption>();
  const [showOtherInvoiceOption, setShowOtherInvoiceOption] = useState<boolean>(false);

  const [citizenDigital, setCitizenDigital] = useState<string>('');
  const [mobileBarcode, setMobileBarcode] = useState<string>('');
  const [placeholder, setInvoicePlaceholder] = useState<string>('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isOpenPayment,setOpenPayment] = useState<boolean>(false)
  const [openCancelPopup, setOpenCancelPopup] = useState(false);

  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { bodyTextColor } = colors;

  const SelectInvoiceScripts = Scripts.CleaningService.SelectInvoice;
  const SelectInvoiceTranslationkeys = Translationkeys.CleaningService.SelectInvoice;
  const pageName = SelectInvoiceTranslationkeys.PageName;

  const invoiceOptions: Array<InvoiceOption> = [
    { name: SelectInvoiceScripts.ECPay, type: CLEANING_SERVICE_INVOICE_TYPE.EC_PAY },
    {
      name: SelectInvoiceScripts.MobileBarcode,
      type: CLEANING_SERVICE_INVOICE_TYPE.MOBILE_BARCODE,
    },
    {
      name: SelectInvoiceScripts.CitizenDigital,
      type: CLEANING_SERVICE_INVOICE_TYPE.CITIZEN_DIGITAL,
    },
  ];

  useEffect(() => {
    getCurrency();
    getPlaceholder();
  }, [language, isTranslationsLoading]);

  useEffect(() => {
    if (invoiceOption) {
      if (isECPAYSelected(invoiceOption)) setIsDisabled(false);
      else if (mobileBarcode && isMobileBarcodeSelected()) setIsDisabled(false);
      else if (citizenDigital && isCitizenDigitalSelected()) setIsDisabled(false);
      else setIsDisabled(true);
    }
  }, [invoiceOption, mobileBarcode, citizenDigital]);

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  };

  const getPlaceholder = async () => {
    const placeholderLbl = await getTextScript(
      `${pageName}/${SelectInvoiceTranslationkeys.PlaceHolderInvoice}`,
      SelectInvoiceScripts.PlaceHolderInvoice,
      language,
      []
    );

    setInvoicePlaceholder(placeholderLbl);
  };

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const onSubmit = () => {
    // navigate('/cleaningSummary', { replace: true });

    callCreateECPayPaymentAPI();

  };

  const callCreateECPayPaymentAPI = () => {

    const interactionData = globalState?.state?.sessionResponse?.Interaction;

    const cleaningIncidentData =
      globalState?.state?.cleaningIncidentData?.UpdateCleaningServiceRequest;

    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;

    const CreatePaymentRequest = {
      CreatePaymentRequest: {
        ServiceRequestId: cleaningIncidentData?.ServiceRequest?.ServiceRequestId,
        CustomerCaseId: cleaningIncidentData?.ServiceRequest?.CustomerCaseId,
        InteractionLineId: interactionLineId,
        InvoiceMethod: "Mobile Barcode",
        IdentificationNbr: "12345434555"
      },
    };
   
    setLoading(true);
    
    createECPayPaymentRequest(CreatePaymentRequest, interactionLineId)
      .then((data) => {
        setLoading(false);

        // const htmlString = "<form target=\"_blank\" method=post action=\"https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V5\"><input type='text' style=display:none name='BidingCard' value='1'><input type='text' style=display:none name='ChoosePayment' value='ALL'><input type='text' style=display:none name='CustomField1' value='C86621C1220D4D8C89C72C23E9007861'><input type='text' style=display:none name='CustomField2' value='C86621C1220D4D8C89C72C23E9007868'><input type='text' style=display:none name='EncryptType' value='1'><input type='text' style=display:none name='ItemName' value='手機20元X2#隨身碟60元X1'><input type='text' style=display:none name='MerchantID' value='3002607'><input type='text' style=display:none name='MerchantMemberID' value='Test1234'><input type='text' style=display:none name='MerchantTradeDate' value='2024/02/28 09:22:29'><input type='text' style=display:none name='MerchantTradeNo' value='ECPay1709112149808'><input type='text' style=display:none name='PaymentType' value='aio'><input type='text' style=display:none name='ReturnURL' value='https://3s30nfiuqi.execute-api.ap-northeast-1.amazonaws.com/sqa/claim/ecpay/api/fetchecpayresponse'><input type='text' style=display:none name='TotalAmount' value='151'><input type='text' style=display:none name='TradeDesc' value='ecpay商城購物'><input type='text' style=display:none name='CheckMacValue' value='D6BCCC507C9ED4BC4DC451E329AEB905B523D9838C1A650643E4C5BA6B048070'><input class='button04' type='submit' value='送出'></form>";

        insertAndSubmitForm(data?.CreatePaymentResponse?.html_response);

        openAlert();
      })
      .catch((error) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  }

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  };

  const onBackClick = () => {
    if (showOtherInvoiceOption) {
      setShowOtherInvoiceOption(!showOtherInvoiceOption);
      setIsDisabled(true);
    } else navigate('/cleaningReview', { replace: true });
  };

  const handleChange = (name: string) => {
    setInvoiceOption(getOptionByName(name));

    setShowOtherInvoiceOption(!isECPAYSelected(getOptionByName(name)));
  };

  const handleInputChange = (type: string, value: string) => {
    if (type === CLEANING_SERVICE_INVOICE_TYPE.MOBILE_BARCODE) {
      setMobileBarcode(value);
    } else if (type === CLEANING_SERVICE_INVOICE_TYPE.CITIZEN_DIGITAL) {
      setCitizenDigital(value);
    }
  };

  const getOptionByName = (name: string) => {
    const invoiceOption = invoiceOptions.find((option) => {
      return option?.name === name;
    });

    return invoiceOption;
  };

  const isECPAYSelected = (option?: InvoiceOption): boolean => {
    return option?.type === CLEANING_SERVICE_INVOICE_TYPE.EC_PAY;
  };

  const isMobileBarcodeSelected = (): boolean => {
    return invoiceOption?.type === CLEANING_SERVICE_INVOICE_TYPE.MOBILE_BARCODE;
  };

  const isCitizenDigitalSelected = (): boolean => {
    return invoiceOption?.type === CLEANING_SERVICE_INVOICE_TYPE.CITIZEN_DIGITAL;
  };

  const insertAndSubmitForm = (htmlString: string) => {
    // Create a container div
    const container = document.createElement('div');
    container.innerHTML = htmlString;
  
    // Append the container to the body
    document.body.appendChild(container);
  
    // Get the form from the container and submit it
    const form = container.querySelector('form');
    if (form) {
      form.submit();
    }
  }

  const openAlert = () => {
    setIsAlertOpen(true);
  };

  // Function to close the alert dialog
  const closeAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <Box paddingTop={'54px'} w="full">
      <MainStackLayout gap={0} spacing={0}>
        <Flex w={{ base: 'full', lg: '80%' }} h={'100%'} margin="0 auto" direction={'column'}>
          <SubHeader
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={true}
            onClick={onBackClick}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}
          ></SubHeader>
          {!showOtherInvoiceOption && (
            <Box px={4} mx={'auto'} w={{ base: 'full', lg: '60%' }}>
              <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                <TextScript
                  text={SelectInvoiceScripts.HeaderText}
                  translationKey={`${pageName}/${SelectInvoiceTranslationkeys.HeaderText}`}
                />
              </Text>

              <RadioGroup mt={4} onChange={(name) => handleChange(name)}>
                <VStack alignItems={'flex-start'}>
                  {invoiceOptions.map((option, idx) => {
                    return (
                      <Box my={2} p={4} w={'100%'} border={'1px solid #A5AAAF'}>
                        <Radio
                          borderColor={'#A5AAAF'}
                          value={option.name}
                          name={option.name}
                          key={`option-${idx}`}
                        >
                          <Text fontSize={'xl'}>
                            <TextScript
                              text={option.name}
                              translationKey={`${pageName}/${SelectInvoiceTranslationkeys.InvoiceOption}-${option.name}`}
                            />
                          </Text>
                        </Radio>
                      </Box>
                    );
                  })}
                </VStack>
              </RadioGroup>
            </Box>
          )}

          {showOtherInvoiceOption && isMobileBarcodeSelected() && (
            <Box px={4} mx={'auto'} w={{ base: 'full', lg: '60%' }}>
              <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                <TextScript
                  text={SelectInvoiceScripts.MobileBarcodeHeader}
                  translationKey={`${pageName}/${SelectInvoiceTranslationkeys.MobileBarcodeHeader}`}
                />
              </Text>

              <Text mt={2} fontSize={'xl'}>
                <TextScript
                  text={SelectInvoiceScripts.MobileBarcodeTitle}
                  translationKey={`${pageName}/${SelectInvoiceTranslationkeys.MobileBarcodeTitle}`}
                />
              </Text>

              <Box my={'1rem'}>
                <TextInput
                  type="text"
                  showIcon={false}
                  borderColor={'#A5AAAF'}
                  placeholder={placeholder || SelectInvoiceScripts.PlaceHolderInvoice}
                  value={mobileBarcode}
                  maxLength={40}
                  onChange={(e: any) => {
                    handleInputChange(CLEANING_SERVICE_INVOICE_TYPE.MOBILE_BARCODE, e.value);
                  }}
                />
              </Box>

              <Box
                bg={'#F0F0F5'}
                w={'100%'}
                p={4}
                textAlign={'center'}
                mb={{ base: '45%', lg: '25%' }}
              >
                <Text mt={2} color={'#808080'}>
                  <TextScript
                    text={SelectInvoiceScripts.ExampleLbl}
                    translationKey={`${pageName}/${SelectInvoiceTranslationkeys.ExampleLbl}`}
                  />
                </Text>

                <Image
                  my={'2'}
                  marginX={'auto'}
                  minHeight={'100px'}
                  src={barcodeImg}
                  alt="barcode"
                />

                <Text>
                  <TextScript
                    text={SelectInvoiceScripts.DummyBarcode}
                    translationKey={`${pageName}/${SelectInvoiceTranslationkeys.DummyBarcode}`}
                  />
                </Text>
              </Box>
            </Box>
          )}

          {showOtherInvoiceOption && isCitizenDigitalSelected() && (
            <Box px={4} mx={'auto'} w={{ base: 'full', lg: '60%' }} mb={{ base: '45%', lg: '25%' }}>
              <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
                <TextScript
                  text={SelectInvoiceScripts.CitizenDigitalHeader}
                  translationKey={`${pageName}/${SelectInvoiceTranslationkeys.CitizenDigitalHeader}`}
                />
              </Text>

              <Text mt={2} fontSize={'xl'}>
                <TextScript
                  text={SelectInvoiceScripts.CitizenDigitalTitle}
                  translationKey={`${pageName}/${SelectInvoiceTranslationkeys.CitizenDigitalTitle}`}
                />
              </Text>

              <Box my={'1rem'}>
                <TextInput
                  type="text"
                  showIcon={false}
                  borderColor={'#A5AAAF'}
                  placeholder={placeholder || SelectInvoiceScripts.PlaceHolderInvoice}
                  value={citizenDigital}
                  maxLength={40}
                  onChange={(e: any) => {
                    handleInputChange(CLEANING_SERVICE_INVOICE_TYPE.CITIZEN_DIGITAL, e.value);
                  }}
                />
              </Box>

              <Box
                bg={'#F0F0F5'}
                w={'100%'}
                p={4}
                textAlign={'center'}
                mb={{ base: '45%', lg: '25%' }}
              >
                <Text mt={2} color={'#808080'}>
                  <TextScript
                    text={SelectInvoiceScripts.ExampleLbl}
                    translationKey={`${pageName}/${SelectInvoiceTranslationkeys.ExampleLbl}`}
                  />
                </Text>

                <Image my={'2'} marginX={'auto'} minHeight={'100px'} src={cardImg} alt="card" />
              </Box>
            </Box>
          )}

          <Box
            w={'full'}
            p={'16px'}
            bg={'white'}
            position={'fixed'}
            bottom={0}
            right={0}
            left={0}
            pb={{ base: '25%', lg: '2rem' }}
            zIndex={'3'}
            style={{ boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.08)' }}
          >
            <Flex
              w={{ base: 'full', lg: '80%' }}
              direction={'row'}
              justifyContent={'space-between'}
              mx={'auto'}
            >
              <Box>
                <Text fontSize={'14px'}>
                  <TextScript
                    text={Scripts.CleaningService.SelectDeviceModel.CleaingFeeText}
                    translationKey={`${pageName}/${Translationkeys.CleaningService.SelectDeviceModel.CleaingFeeText}`}
                  />
                </Text>
                <Text lineHeight={'tall'} fontSize={'xl'} as={'b'}>
                  {`${currencySymbol}${globalState?.state?.selectedDeviceModel?.PricewithDiscount}`}
                </Text>

                <Link marginY={'auto'} marginLeft={'10px'} fontSize={'14px'} color={bodyTextColor} textDecoration={"underline"} onClick={() => setOpenPayment(!isOpenPayment)}>
                  <Text>
                      <TextScript 
                          text={Scripts?.Payment?.lblBreakdown}
                          translationKey={`${Translationkeys.Payment.PageName}/${Translationkeys.Payment.breakdown}`}
                          dynamicValues={[isOpenPayment ? Scripts?.Payment?.closeBreakdown : Scripts?.Payment?.viewBreakdown]}
                      />
                  </Text>
                </Link>

              </Box>

              <Button
                width={'auto'}
                textAlign={'center'}
                colorScheme="primary"
                color={'white'}
                isDisabled={isDisabled}
                onClick={() => onSubmit()}
              >
                <TextScript
                  text={Scripts.Global.Next}
                  translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Next}`}
                />
              </Button>
            </Flex>

            {isOpenPayment && currencySymbol &&
              <PaymentBreakdown currencySymbol={currencySymbol} />
            }
          </Box>
        </Flex>
      </MainStackLayout>
      <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} isVAS={true}/>
      <ECPayStatusAlert isOpen={isAlertOpen} onClose={closeAlert} />
    </Box>
  );
}

export default SelectInvoice;
