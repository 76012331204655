const enum ActionTypes {
  SET_PROJECT_NAME,
  SET_SELECTED_SERVICE,
  SET_RPM_DEVICE,
  GET_DEVICE_IMAGES,
  LOADING,
  SRF,
  SHOW_PAYMENT_OPTIONS,
  SET_PAYMENT_METHOD,
  SET_SESSION_RESPONSE,
  SET_DELIVERY_NIGHT_FEE,
  SET_SERVICE_ORDER_DATA,
  SET_CREATE_SERVICE_DATA,
  SET_PROCESS_INC_DATA,
  SET_DOOR_STEP_DATA_TRANSFER_ADD_ON,
  GET_FULFILLMENT_DATA,
  SET_FULFILLMENT_DATA,
  SET_SHIPPING_METHOD_V2,
  SET_LFLDevice,
  SET_LFL_DEVICE_STOCK,
  SET_CURRENT_AGREEMENT_DATA,
  SET_PICKUP_DATE,
  SET_RETURN_DATE,
  SET_DELIVERY_DATE,
  SET_PICKUP_TIME,
  SET_RETURN_TIME,
  SET_DELIVERY_TIME,
  SET_DELIVERY_TIME_CODE,
  SET_RETURN_ADDRESS,
  SET_PICKUP_ADDRESS,
  SET_LOCATION_COORDINATES,
  SET_DELIVERY_ADDRESS,
  CHARGE_NIGHT_DELIVERY_FEE,
  SET_ERROR_DATA,
  SET_BT_CLIENT_TOKEN,
  SET_SWAP_CONVERSION,
  SET_INCIDENT_TYPE,
  CHECK_FLAG,
  SET_RPM_RESPONSE,
  ENABLE_REVIEW_BTN,
  SET_SHIPPING_ORDER_UPDATES_RESULT,
  SET_PAYMENT_MESSAGE,
  SET_UPDATESALESORDER_PARAMS,
  SET_REPAIRSCHEDULE_PARAMS,
  SET_RAW_SHPPINGMETHOD_RES,
  IMEI_DEVICE_DETAILS,
  SET_UPDATE_DEVICE_IMEI,
  SET_UPDATE_DEVICE_CAPACITY,
  SET_UPDATE_DEVICE_COLOR,
  SET_UPDATE_DEVICE_INQ_DESC,
  SET_UPDATE_DEVICE_BASE_URL,
  SET_UPDATE_DEVICE_FILE,
  SET_MY_PAYMENT_DETAILS_RESP,
  SET_MY_PAYMENTS_REF_ID,
  SET_MY_PAYMENTS_TOKEN_RESP,
  SDK_LIB_LOADER,
  SDK_LIB_FAILURE,
  SET_SHIPPING_METHOD_RESPONSE,
  SET_CURRENT_CC_DETAILS,
  SET_DEVICES_LIST,
  MYPAYMENTS_CURRENT_SESSION_RESP,
  MYPAYMENTS_SELECTED_DEVICE,
  SET_RENEWAL_OPTION,
  SET_RENEWAL_PAYMENT_DETAILS_RESP,
  SET_RENEWAL_GETQUOTE_DETAILS,
  OPEN_CHAT_WINDOW,
  SET_RENEWAL_MONTHLY_DISCOUNT,
  SET_RENEWAL_ANNUAL_DISCOUNT,
  SET_IS_MAXIS_FLAG,
  SET_PENDING_PAYMENT_FLAG,
  SET_BRAINTREE_METHODS,
  SET_CITIES_DATA,
  SET_ALTERNATE_CONTACT_NUMBER,
  SET_GET_CATEGORY_RESPONSE,
  SET_REGISTER_DEVICE_RESPONSE,
  SET_ADDED_DEVICE_DETAILS,
  SET_SELECTED_CATEGORY,
  SET_INQUIRY_RESPONSE,
  UPDATEDEVICE_ASSET_DETAILS,
  UPDATEDEVICE_ASSET_MODELS_DATA,
  SET_IS_STARHUB_FLAG,
  SET_LIMIT_EXCEED_DATA,
  FROM_UPDATE_DEVICE_BUTTON,
  SET_SELECTED_DEVICE_DETAILS,
  SET_JWT_EXPIRED,
  SET_INVENTORY_RESPONSE,
  SET_DEVICE_REGISTRATION_RESPONSE,
  SET_CLEANING_DEVICE_CATEGORIES,
  SET_CLEANING_DEVICE_CATEGORY,
  SET_CLEANING_DEVICE_MODEL,
  SET_CLEANING_DEVICE_DETAILS,
  SET_CLEANING_LOGISTICS,
  SET_EDIT_CLEANING_LOGISTICS_FIELD,
  SET_CLEANING_INCIDENT_DATA,
  SET_PAYMENT_ERROR,
  SET_SELECTED_ASSET_DETAILS,
  SET_SERVICE_FEE_DATA,
  SET_FINDAGREEMENT_BY_MDN_DATA,
  SET_CLAIMDETAILS_DATA,
  SCROLL_TO_SR
}

export default ActionTypes;
