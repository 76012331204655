import { LFLStockKey } from '../modules/config/constants';
import { TextScript, useSessionStorage, useConfig, getTypeByDomain, getTextScript, useAccount, AccountInfo, getCarrierByDomain, Mixpanel } from '@MGPD/myasurion-shared';
import { Box, Link, Text } from '@chakra-ui/react';

import Scripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import { getRedirectUrl } from '../utils/utills';
import { useCentralStore } from '../store/appContext';
import { useEffect, useState } from 'react';
import ActionTypes from '../store/actionTypes';
import Geocode from 'react-geocode';
import { findAgreementByMdn } from '../../services/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { loadingWaitingMessages } from '../../modules/config/constants';
import { useNavigate } from 'react-router-dom';
import { getOnlineSession } from '../services/api';

const ReviewHold = () => {
  const [LFLStockData] = useSessionStorage<any>(LFLStockKey, true);
  const globalState = useCentralStore();
  const isLimitExceeded = globalState?.state?.limitExceedData?.EligibilityCriteriaStatus?.toUpperCase() === "VOID" ? true : false

  const navigate = useNavigate();
  const [account, ] = useAccount<AccountInfo>({ profileId: '', userId: '' });
  const [sessionData, ] = useSessionStorage<any>('data', '');
  const disableApplLoad = getCarrierByDomain()?.toLowerCase() === 'starhub'//useFeatureIsOn('disableApplLoad');

  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { accent } = colors;

  const ReviewHoldKeys = translationsKeys.ReplacementHold;
  const SRSubmittedReviewKeys = translationsKeys.SRSubmittedReview;
  const LimitExceedKeys = translationsKeys.LimitExceedKeys;
  const [limitExceedDesc, setLimitExceedDesc] = useState('')
  const getLimitExceedDesc = async (value: string) => {
    let PlaceHolderText;
    PlaceHolderText = await getTextScript(
      `${LimitExceedKeys.PageName}/${LimitExceedKeys.KeyDesc}`,
      Scripts.ClaimConfirmation.LimitExceededDesc,
      'en-US',
      [value]);
    setLimitExceedDesc(PlaceHolderText);
  };

  useEffect(() => {
    getLimitExceedDesc(globalState?.state?.limitExceedData?.IncidentType)
  }, [globalState?.state?.limitExceedData])

  // const onCancel = () => { };


  const { mutate: fetchAgreementByMdn } = useMutation({
    mutationFn: findAgreementByMdn,
    retry: 5,
    retryDelay: (failureCount) => {
        if (failureCount === 3) {
            // setLoadingMessage(
                loadingWaitingMessages[
                    Math.floor(Math.random() * loadingWaitingMessages.length)
                ]
            // );
        }
        return Math.min(1000 * 2 ** failureCount, 30000);
    },
    onSuccess: (data) => {
        if(disableApplLoad) {
            let deviceList: any = [];
            deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'REPLACED')
      
            if (!deviceList || deviceList === undefined || deviceList.length === 0) {
            deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'ENROLLED')
            }
            
            deviceList = [...new Map(deviceList?.map((item: any) =>
              [item['mdn'], item])).values()]
  
            globalState?.dispatch({
                type: ActionTypes.SET_DEVICES_LIST,
                payload: deviceList || data?.deviceData,
            });
  
            globalState?.dispatch({
                type: ActionTypes.SET_FINDAGREEMENT_BY_MDN_DATA,
                payload: data,
            });   
        }
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
        navigate(getRedirectUrl(getTypeByDomain()))
    },
    onSettled: () => {
        fetchOnlineSession();
    },
    onError: (error) => {
      globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
        console.error('Findagreement failed', (error as { message: string })?.message);
        // doLogout('findAgreementByMdn');
    },
  });
  
  const { refetch: fetchOnlineSession } = useQuery(
  ['sessionData'],
  async () => {
      return await getOnlineSession();
  },
  {
      retry: 5,
      retryDelay: (failureCount, error) => {
          if (failureCount === 3) {
              // setLoadingMessage(
                  loadingWaitingMessages[
                      Math.floor(Math.random() * loadingWaitingMessages.length)
                  ]
              // );
          }
          Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
              userId: account.userId,
              error: error,
          });
          return Math.min(1000 * 2 ** failureCount, 30000);
      },
      onSuccess: (data) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
          Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Success', {
              userId: account.userId,
          });
  
            globalState?.dispatch({
              type: ActionTypes.SET_SESSION_RESPONSE,
              payload: data,
            });
            const isMaxis = data.InitializeResponse?.ClientName?.toUpperCase() === "MAXIS"
            globalState?.dispatch({
              type: ActionTypes.SET_IS_MAXIS_FLAG,
              payload: isMaxis,
            });
            
            const btMethods = data?.InitializeResponse?.isBraintree?.method
            
            globalState?.dispatch({
              type: ActionTypes.SET_BRAINTREE_METHODS,
              payload: btMethods,
            });
            
      
            const agreementData = data?.FindAgreementsResults?.Agreements?.Agreement?.[0]
            const address1 = agreementData?.Address?.Address1;
            const address2 = agreementData?.Address?.Address2;
            const city = agreementData?.Address?.City;
            const _postalCode = agreementData?.Address?.PostalCode;
            const addressjoin = { address1, address2, city, _postalCode };
            const fulladdress = Object.values(addressjoin).join(', ');
      
            Geocode.setApiKey(import.meta.env.VITE_GOOGLE_API_KEY);    
            Geocode.setLanguage('en');
            Geocode.setRegion('SGP');
            Geocode.setLocationType('ROOFTOP');
      
            Geocode.fromAddress(fulladdress).then(
              (response) => {
                const { lat, lng } = response?.results?.[0]?.geometry?.location;
                globalState?.dispatch({
                  type: ActionTypes.SET_LOCATION_COORDINATES,
                  payload: { lat, lng },
                });
              },
              (error) => {
                console.error(error);
              }
            );
          // setAgreementData(data?.FindAgreementsResults?.Agreements?.Agreement?.[0])
      },
      onError: (error) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
          // console.error(error);
          Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
              userId: account.userId,
              error,
          });
          // setPageLoading(true);
          // setIsFailedApiInit(true);
          // doLogout('getOnlineSession');
      },
      enabled: false,
  }
  );
  
  const callFindAgreement = () => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: true });
    fetchAgreementByMdn({
      mdn: account?.enrolledMdn || '',
      email: account?.email ?? '',
      data: sessionData,
      imei: '',
      idx: 0,
      assetId: '',
  })
  }

  return (
    <Box w={{ base: 'full', lg: '60%' }} margin="0 auto">
      <Box px={4} mt={12}>
        <Text py={'1.5rem'} fontSize={'2rem'} fontWeight={300}>
          <TextScript
            text={
              isLimitExceeded ? Scripts.ClaimConfirmation.LimitExceededHeading : !LFLStockData
                ? Scripts.BackOrderSubmit.heading
                : Scripts.ClaimConfirmation.Replacement.heading
            }
            translationKey={`${isLimitExceeded ? LimitExceedKeys.PageName : !LFLStockData ? SRSubmittedReviewKeys.PageName : ReviewHoldKeys.PageName
              }/${isLimitExceeded ? LimitExceedKeys.KeyHeading : !LFLStockData ? SRSubmittedReviewKeys.KeyHeading : ReviewHoldKeys.KeyHeading}`}
          />
        </Text>
        <Text fontSize={'1.5rem'} pb={'0.5rem'}>
          <TextScript
            text={
              isLimitExceeded ? Scripts.ClaimConfirmation.LimitExceededSubHeading : !LFLStockData
                ? Scripts.BackOrderSubmit.subheading1
                : Scripts.ClaimConfirmation.Replacement.subheading1
            }
            translationKey={`${isLimitExceeded ? LimitExceedKeys.PageName : !LFLStockData ? SRSubmittedReviewKeys.PageName : ReviewHoldKeys.PageName
              }/${isLimitExceeded ? LimitExceedKeys.KeySubHeading : !LFLStockData ? SRSubmittedReviewKeys.KeySubheading1 : ReviewHoldKeys.KeySubheading1
              }`}
          />
        </Text>
        <Text>
          {isLimitExceeded ?
            <>{limitExceedDesc}</>
            :
            <TextScript
              text={
                !LFLStockData
                  ? Scripts.BackOrderSubmit.subheadingcontent1
                  : Scripts.ClaimConfirmation.Replacement.subheadingcontent1
              }
              translationKey={`${!LFLStockData ? SRSubmittedReviewKeys.PageName : ReviewHoldKeys.PageName
                }/${!LFLStockData
                  ? SRSubmittedReviewKeys.KeySubheadingcontent1
                  : ReviewHoldKeys.KeySubheadingcontent1
                }`}
            />
          }
        </Text>
        <Box display="flex" justifyContent="center" mt={'1.5rem'}>
          <Link
            // href={getRedirectUrl(getTypeByDomain())}
            color={accent}
            fontSize={'1rem'}
            fontWeight={700}
            py={'12px'}
            textDecoration="underline"
            onClick={() => callFindAgreement()}
          >
            <TextScript
              text={Scripts.Global.CloseThisPage}
              translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.CloseThisPage}`}
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewHold;
