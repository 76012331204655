/* eslint-disable no-console */
import {
  assetDataKey,
  currentAgreementKey,
  interactionLineKey,
  processIncidentKey,
  serviceFeeKey,
  serviceRequestKey,
  submitRequestKey,  
} from '../modules/config/constants';
import {
  createServiceRequestApi,
  getReplacementDevice,
  logGTMEvent,
  processIncidentApi,
  // servicefeeApi,
  setFulfillmentMethodApi,
} from '../services/api';
import ActionTypes from '../store/actionTypes';
import { getTextScript } from '@MGPD/myasurion-shared/components/localization';
import Scripts from '../Scripts/asurion-script.json';
import CNScripts from '../Scripts/asurion-script-chinese.json';
import translationsKeys from '../Scripts/translationsKeys.json'
import { useCentralStore } from '../store/appContext';
import { AssetData, DesiredService, MultiSelectTriageOption, serviceFeeResult } from '../types/types';
import { DOMAIN, GTM_EVENT, INCIDENT_TYPES, REPAIR_FULLFILLMENT_METHOD_TYPE, TRIAGE_QUESTION_TYPE } from '../utils/constant';
import { DeviceCard, DeviceService, useSessionStorage, TextScript, useConfig, SubHeader, getTypeByDomain, getCarrierByDomain, getUrlByTypeAndEnv } from '@MGPD/myasurion-shared';
import { clearSessionData, getDateAndDay, getGTMData, getRedirectUrl, getSRFee, isBatteryReplacement, isChineseDefaultLanguage, isClaimEligible, isDeviceRefresh, isDeviceRepair, isMalfunction, isReplacement, isScreenRepair, isScreenRepairBatReplacement, isScreenRepairWarranty, isSwap, saveError, showErrorPage } from '../utils/utills';
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDeviceImageByModel } from '../utils/get-device-images';
import { QueAns, TriageQuestion } from './TriageQuestion';
import { getBatReplacementLBL, getCurrencySymbol, getDeviceRefreshLBL, getDeviceRepairLBL, getPerilText, getProgrammeName, getReplacementLBL, getScreenRepairLBL, getSwapLBL } from '../utils/localization-helper';
import { checkIsOnlyRefresh, hasIncidentTypeSupport, hasMultiDeviceSupport, hideTriageQuestions, showGadgetRepairTriage, showTriageOtherIssuesQuestions } from '../utils/featuresUtills';
// callServiceFee,
// showTriageMoreDetailsSection,
import features from '../utils/features.json';
import gadgetTriage from '../utils/gadgetTriage.json';
import { camelize, getTriageQuestions } from '../utils/helper';

const HowCanWeHelpPage: React.FC<PropsWithChildren> = () => {
  const globalState = useCentralStore();
  
  const isTOM = globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === "Asurion_TechCare"
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const domainType = getTypeByDomain();
  const carrier = getCarrierByDomain();
  // const RepairPortal_URL:any = getUrlByTypeAndEnv(carrier, 'repair', import.meta.env.VITE_DOMAIN_ENV);
  const DPPortal_URL: any = getUrlByTypeAndEnv(carrier, 'device_protection', import.meta.env.VITE_DOMAIN_ENV);
  const [, setSRSubmits] = useSessionStorage<any>(submitRequestKey, null);
  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 850px)').matches);
  const HowCanWeHelpPageKeys = translationsKeys.HowCanWeHelpPage
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const incidentType: any = urlParams.get('type');
  
  // const incidentType: any = 'Screen Repair Warranty'  //|| 'Bat Scr Replacement';   //need to add
  let PerilType =
    (incidentType?.toUpperCase() === 'MALFUNCTION' || incidentType?.toUpperCase() === 'EWS')
      ? INCIDENT_TYPES.MALFUNCTION
      : incidentType?.toUpperCase() === 'DEVICE-REPAIR'
        ? INCIDENT_TYPES.FAULT_REPAIR
        : incidentType?.toUpperCase() === 'BATTERY-REPLACEMENT'
          ? INCIDENT_TYPES.BATTERY_REPLACEMENT
          : incidentType?.toUpperCase() === 'SCREEN-REPAIR'
            ? INCIDENT_TYPES.SCREEN_REPAIR
            : incidentType?.toUpperCase() === 'BAT-SCR-REPLACEMENT' ?
              INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT
              : incidentType?.toUpperCase() === 'SCREEN-REPAIR-WARRANTY' ?
                INCIDENT_TYPES.SCREEN_REPAIR_WARRANTY
                : incidentType;

  const [deviceInPossession, setDeviceInPossession] = useState<boolean | undefined>(undefined);
  const [selectedService, setSelectedService] = useState<string>(PerilType);
  const [assetData, setAssetData] = useState<any>();
  const [triagestatus, setTriageStatus] = useState<any>(true);
  const [offeredPeril, setOfferedPeril] = useState<any>('');
  const [, setOfferedserviceFee] = useState<any>();
  const [Placeholdertexts, setPlaceholderText] = useState<any>('');
  const [, setServiceRequest] = useState<any>();
  // const [click, setClick] = useState<any>();

  const [MultiOptions, setMultiOptions] = useState<MultiSelectTriageOption[]>([
    { label: 'Cosmetic Damage', selected: false, scriptKey: 'CosmeticDamage', displayLabel: 'Cosmetic Damage' },
    { label: 'Camera Issues', selected: false, scriptKey: 'CameraIssues', displayLabel: 'Camera Issues' },
    { label: 'Audio Issues', selected: false, scriptKey: 'AudioIssues', displayLabel: 'Audio Issues' },
    { label: 'Connectivity Issues (Network / WiFi)', selected: false, scriptKey: 'ConnectivityIssues', displayLabel: 'Connectivity Issues (Network / WiFi)' },
    { label: 'Cannot Power Off / Device Hang', selected: false, scriptKey: 'CannotPowerDeviceHang', displayLabel: 'Cannot Power Off / Device Hang' },
    { label: 'Other Issues', selected: false, scriptKey: 'OtherIssues', displayLabel: 'Other Issues' },
  ]);
  let [otherText, setOtherText] = useState('');
  const [OtherSelected, setOtherSelected] = useState('');
  const [selectedMultiOptions, setSelectedOptions] = useState([]);
  const [triageList, setTriageList] = useState<Array<QueAns>>();
  // const [disableNext, setDisableNext] = useState<boolean>(
  //   (PerilType?.toUpperCase() === 'FAULT REPAIR' && MultiSelectedOptions?.length > 0)
  //     ? false
  //     : true
  // );
  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary, accent, bodyTextColor } = colors;

  const [disableNext, setDisableNext] = useState<boolean>(true);

  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  // const [serviceFee, setServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
  const [, setSelectedAssetData] = useSessionStorage<any>(assetDataKey, null);
  const [, setCurrentAgreementData] = useSessionStorage<any>(currentAgreementKey, null);
  const [, setServiceRequestData] = useSessionStorage<any>(serviceRequestKey, null);
  const [, setProcessIncidentData] = useSessionStorage<any>(processIncidentKey, null);
  const [, setInteractionLineData] = useSessionStorage<any>(interactionLineKey, null);
  const [, setMultiOptionsData] = useSessionStorage<any>('MultiOptionsData', null);

  const [titletextSwap, setTitletextSwap] = useState('');
  const [titletextRefresh, setTitletextRefresh] = useState('');
  const [titletextReplace, setTitletextReplace] = useState('');
  const [descriptionSwap, setDescriptionSwap] = useState('');
  const [descriptionRefresh1, setDescriptionRefresh1] = useState('');
  const [descriptionRefresh2, setDescriptionRefresh2] = useState('');
  const [descriptionReplace, setDescriptionReplace] = useState('');
  const [SRFtext, setSRFtext] = useState('');
  const [RepairSRFtext, setRepairSRFtext] = useState('');
  // const [deviceRefreshFeetext, setDeviceRefreshFeetext] = useState('');
  const [exploreOptions, setExploreOptions] = useState('');
  const [optionToRepair, setOptionToRepair] = useState('');
  const [descriptionExploreOptions, setDescriptionExploreOptions] = useState('');
  // const [BatReplacementLBL, setBatReplacementLBL] = useState('')
  const [, setBatReplacementLBL] = useState('')
  const [SwapLBL, setSwapLBL] = useState("")
  const [replLBL, setReplacementLBL] = useState('')
  const [deviceRepairLBL, setDeviceRepairLBL] = useState('DEVICE REPAIR')
  const [screenRepairLBL, setScreenRepairLBL] = useState('SCREEN REPAIR')
  const [deviceRefreshLBL, setDeviceRefreshLBL] = useState('Refresh')
  const [selectedTab, setSelectedTab] = useState(incidentType?.toUpperCase() === 'REFRESH' ? 1 : 0);

  const [IncidentTypes, setIncidentTypes] = useState<any>({});

  const [currencySymbol, setCurrencySymbol] = useState('')
  const [programmeNameLBL, setProgrammeNameLBL] = useState('')
  const [_PerilType, setPerilType] = useState('');
  const [customerCaseResults, setCustomerCaseResults] = useState('');
  const [hasRepairSupport, setHasRepairSupport] = useState(false);

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const agreementData1: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const isChinese = isChineseDefaultLanguage();

  useEffect(() => {
    const _customerCaseResults: any = globalState?.state?.sessionResponse?.CustomerCaseResults?.CustomerCases?.filter((v: any) => ['new', 'reopen'].includes(v?.CustomerCaseStatus?.toLowerCase()))?.[0]
    setCustomerCaseResults(_customerCaseResults)
  }, [globalState?.state?.sessionResponse?.CustomerCaseResults])

  // let assetData1: AssetData = agreementData1?.Assets.Asset.filter(
  //   (a: any) => a.AssetInstance === 'ENROLLED'
  // )[0];
  
  const [serviceFee, setServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
  useEffect(() => {
    if(globalState?.state?.serviceFeeDetails) {
      setServiceFeeData(globalState?.state?.serviceFeeDetails)
    }
  },[globalState?.state?.serviceFeeDetails])
  const servicefeeData = globalState?.state?.serviceFeeDetails

  // const { isFetching: serviceFeeLoading, data: servicefeeData } = useQuery(
  //   ['servicefee'],

  //   async () => {
  //     setLoading(true);
  //     return await servicefeeApi(
  //       agreementData1?.AgreementId,
  //       assetData1?.AssetId,
  //       interactionData?.InteractionLine.InteractionLineId
  //     );
  //   },
  //   {
  //     enabled: !!agreementData1?.AgreementId && callServiceFee(agreementData1?.ClientOffer?.ClientOfferName),
  //     onSuccess: (data: any) => {
  //       setServiceFeeData(data);
  //       setLoading(false);
  //       // loadDeviceServiceScripts();
  //       let GTMData = getGTMData(GTM_EVENT.TRIAGE_SCREEN_EVENT, agreementData1, carrier, domainType, data?.CustomerCaseNumber)
  //       GTMData.ClaimEligibilty = true;
  //       logGTMEvent(GTMData);
  //       // logGTMEvent({
  //       //   event: GTM_EVENT.TRIAGE_SCREEN_EVENT,          
  //       //   Partner: carrier,
  //       //   Program: agreementData1?.ClientProductSkuNbr,
  //       //   MainSubscriberMDN: agreementData1?.ContactPoints?.ContactPoint?.filter(
  //       //     (val: any) => val?.PhoneNumber
  //       //   )?.[0]?.PhoneNumber,
  //       //   EmailAddress: agreementData1?.ContactPoints?.ContactPoint?.filter(
  //       //     (val: any) => val?.EmailAddress
  //       //   )?.[0]?.EmailAddress,
  //       //   Skill: domainType,
  //       //   ClaimEligibilty: true
  //       // });
  //     },
  //     onError: (error) => {
  //       saveError(error, globalState);
  //       showErrorPage(navigate);
  //       setServiceFeeData(data)
  //       setLoading(false);
  //     },
  //   }
  // );

  useEffect(() => {
    globalState?.dispatch({ type: ActionTypes.SET_INCIDENT_TYPE, payload: camelize(offeredPeril ||selectedService) });
  }, [selectedService, offeredPeril]);

  useEffect(() => {
    setInteractionLineData(interactionData?.InteractionLine.InteractionLineId);
    loadDeviceServiceScripts();
  }, [interactionData]);

  const getPlaceHolderText = async () => {
    const PlaceHolderText = await getTextScript(
      'HowCanWeHelpPage/Placeholder',
      `Please tell us more`);
    setPlaceholderText(PlaceHolderText);
  };

  const getTitletextSwap = async (value: string) => {
    const PlaceHolderText = await getTextScript(
      `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.offeredServiceTitle}`,
      Scripts.HowCanWeHelpYou.offeredServiceTitleText,
      'en-US',
      [value]);
    setTitletextSwap(PlaceHolderText);
  };

  const getTitletextRefresh = async () => {
    const PlaceHolderText = await getTextScript(
      `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.offeredServiceTitle}`,
      Scripts.HowCanWeHelpYou.offeredServiceTitleText,
      'en-US',
      ['Refresh']);
    setTitletextRefresh(PlaceHolderText);
  };

  const getTitletextReplace = async () => {
    const PlaceHolderText = await getTextScript(
      `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.offeredServiceTitle}`,
      Scripts.HowCanWeHelpYou.offeredServiceTitleText,
      'en-US',
      ['Replace']);
    setTitletextReplace(PlaceHolderText);
  };

  const getDescriptionSwap = async () => {
    const PlaceHolderText = await getTextScript(
      `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.descriptionSwap}`,
      `${Scripts.HowCanWeHelpYou.descriptionSwap}`);
    setDescriptionSwap(PlaceHolderText);
  };

  const getDescriptionRefresh = async (deviceRefreshLBL: string, swapLBL: string) => {
    const PlaceHolderText1 = await getTextScript(
      `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.descriptionRefresh1}`,
      Scripts.HowCanWeHelpYou.descriptionRefresh1,
      'en-US',
      [deviceRefreshLBL]);
    setDescriptionRefresh1(PlaceHolderText1);

    const PlaceHolderText2 = await getTextScript(
      `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.descriptionRefresh2}`,
      Scripts.HowCanWeHelpYou.descriptionRefresh2,
      'en-US',
      [deviceRefreshLBL, swapLBL]);
    setDescriptionRefresh2(PlaceHolderText2);
  };

  const getDescriptionReplace = async (replLBL: string) => {
    const PlaceHolderText = await getTextScript(
      `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.descriptionReplace}`,
      Scripts.HowCanWeHelpYou.descriptionReplace,
      'en-US',
      [replLBL]);
    setDescriptionReplace(PlaceHolderText);
  };

  const getSRFtext = async () => {
    const PlaceHolderText = await getTextScript(
      `${translationsKeys.Global.PageName}/${translationsKeys.Global.SRFLBL}`,
      Scripts.Global.SRFLBL);
    setSRFtext(PlaceHolderText);
  };

  const getRepairSRFtext = async () => {
    const PlaceHolderText = await getTextScript(
      `${translationsKeys.Global.PageName}/${translationsKeys.Global.RepairFeeLBL}`,
      Scripts.Global.RepairFeeLBL);
    setRepairSRFtext(PlaceHolderText);
  };

  // const getDeviceRefreshFeetext = async (deviceRefreshLBL: string) => {
  //   const PlaceHolderText = await getTextScript(
  //   `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.DeviceRefreshFeetext}`,
  //   Scripts.HowCanWeHelpYou.DeviceRefreshFeetext,
  //   'en-US',
  //   [deviceRefreshLBL]);
  //   setDeviceRefreshFeetext(PlaceHolderText);
  //   };

  const getExploreOptions = async () => {
    const PlaceHolderText = await getTextScript(
      `${translationsKeys.Global.PageName}/${translationsKeys.Global.ExploreYourOptions}`,
      `${Scripts.Global.ExploreYourOptions}`);
    setExploreOptions(PlaceHolderText);
  };

  const getOptionToRepair = async () => {
    const PlaceHolderText = await getTextScript(
      `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.optionToRepair}`,
      `${Scripts.HowCanWeHelpYou.optionToRepair}`);
    setOptionToRepair(PlaceHolderText);
  };

  const getDescriptionExploreOptions = async () => {
    const PlaceHolderText = await getTextScript(
      `${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.descriptionExploreOptions}`,
      `${Scripts.HowCanWeHelpYou.descriptionExploreOptions}`);
    setDescriptionExploreOptions(PlaceHolderText);
  };

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  }

  const getSwapLbl = async () => {
    const swapLBL = await getSwapLBL();
    setSwapLBL(swapLBL);
  }

  const getBatReplacementLbl = async () => {
    const batReplLBL = await getBatReplacementLBL();
    setBatReplacementLBL(batReplLBL);
  }

  const getReplacementLbl = async () => {
    const replLBL = await getReplacementLBL();
    setReplacementLBL(replLBL);
  }

  const getDeviceRepairLbl = async () => {
    const deviceRepairLBL = await getDeviceRepairLBL();
    setDeviceRepairLBL(deviceRepairLBL);
  }

  const getScreenRepairLbl = async () => {
    const screenRepairLBL = await getScreenRepairLBL();
    setScreenRepairLBL(screenRepairLBL);
  }

  const getDeviceRefreshLbl = async () => {
    const deviceRefreshLBL = await getDeviceRefreshLBL();
    setDeviceRefreshLBL(deviceRefreshLBL);
  }

  const getPerilLbl = async () => {
    const peril = await getPerilText(PerilType?.toUpperCase(), PerilType);
    setPerilType(peril);
  }

  const getIncidentType = () => {
    let incidentType = isMalfunction(selectedService)
      ? INCIDENT_TYPES.MALFUNCTION
      : isDeviceRepair(PerilType)
        ? INCIDENT_TYPES.FAULT_REPAIR
        : isBatteryReplacement(PerilType)
          ? INCIDENT_TYPES.BATTERY_REPLACEMENT
          : selectedService === 'Refresh'
            ? INCIDENT_TYPES.DEVICE_REFRESH
            : isScreenRepair(PerilType)
              ? INCIDENT_TYPES.SCREEN_REPAIR
              : selectedService;
              
   return ((isBatteryReplacement(PerilType) ||
        isScreenRepair(PerilType) ||
        isScreenRepairBatReplacement(PerilType))
        && offeredPeril) ? camelize(offeredPeril) : incidentType
  }

  const getServiceRequestId = (data: any) => {
    if (data?.CustomerCaseStatus?.toLowerCase() == 'reopen') return data?.ServiceRequests?.[1]?.ServiceRequestId || data?.ServiceRequest?.ServiceRequestId
    else return data?.ServiceRequest?.ServiceRequestId || data?.ServiceRequests?.[0]?.ServiceRequestId
  }

  const loadDeviceServiceScripts = async () => {
    getPlaceHolderText();
    getExploreOptions();
    getOptionToRepair();
    getDescriptionExploreOptions();

    getCurrency();
    getSwapLbl();
    getBatReplacementLbl();

    getReplacementLbl();
    getDeviceRepairLbl();
    getScreenRepairLbl()
    getDeviceRefreshLbl();

    getTitletextRefresh();
    getTitletextReplace();

    getDescriptionSwap();
    getSRFtext();
    // getDeviceRefreshFeetext(deviceRefreshLBL);
    getRepairSRFtext();
    getPerilLbl()
  };

  const triages = {
    TriageAccepted: true,
    TriageRefusalReason: 'Triage refused by customer',
    Notes: '',
  };

  // CREATE SERVICE REQUEST API
  const {
    refetch: createServiceRequest,
    // data: serviceRequestData,
    isFetching: serviceRequestLoading,
  } = useQuery(
    ['createServiceRequest'],
    async () =>
      await createServiceRequestApi(
        interactionData?.InteractionLine.InteractionLineId,
        agreementData1?.AgreementId
      ),
    {
      enabled: false,
      onSuccess: (data) => {
        setLoading(false);
        clearSessionData(false);
        setServiceRequestData(data);
        callProcessIncidentApi(data);
        let incType = getIncidentType()
        if (
          isDeviceRefresh(incType) ||
          selectedService.toUpperCase() === 'REFRESH' ||
          isDeviceRepair(incType) ||
          isScreenRepair(incType) ||
          isBatteryReplacement(incType) ||
          isScreenRepairBatReplacement(incType) ||
          isScreenRepairWarranty(incType)
        ) {
          navigate('../repair', { replace: true });
        }
        queryClient.setQueryData(['startServiceRequest'], data);
        globalState?.dispatch({ type: ActionTypes.SET_CREATE_SERVICE_DATA, payload: data });

        // const GTMData = {
        //   event: GTM_EVENT.CLAIM_NO_EVENT,
        //   CaseNo: data?.CustomerCaseNumber,
        //   Partner: carrier,
        //   Program: agreementData1?.ClientProductSkuNbr,
        //   MainSubscriberMDN: agreementData1?.ContactPoints?.ContactPoint?.filter(
        //     (val: any) => val?.PhoneNumber
        //   )?.[0]?.PhoneNumber,
        //   EmailAddress: agreementData1?.ContactPoints?.ContactPoint?.filter(
        //     (val: any) => val?.EmailAddress
        //   )?.[0]?.EmailAddress,
        //   Skill: domainType
        // };
        const GTMData = getGTMData(GTM_EVENT.CLAIM_NO_EVENT, agreementData1, carrier, domainType, data?.CustomerCaseNumber)
        logGTMEvent(GTMData);
      },
      onError: (error) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      },
    }
  );

  const callReplacementApi = (ServiceRequestId: string) => {
    globalState?.dispatch({ type: ActionTypes.CHECK_FLAG, payload: "INPROGRESS" });
    getReplacementDevice(ServiceRequestId,
      interactionData?.InteractionLine.InteractionLineId)
      .then((res) => {
        globalState?.dispatch({ type: ActionTypes.CHECK_FLAG, payload: "PROCESSED" });
        globalState?.dispatch({ type: ActionTypes.SET_RPM_RESPONSE, payload: res.ReplacementEquipmentResults.ReplacementItems.ReplacementItem });
      })
      .catch((error) => {
        globalState?.dispatch({ type: ActionTypes.CHECK_FLAG, payload: "FAILED" });
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  }

  const callProcessIncidentApi = (data: any) => {    
    if (isReplacement(selectedService) || isSwap(selectedService) || isMalfunction(selectedService)) {
      setLoading(true);
    }

    if (customerCaseResults && (isDeviceRefresh(selectedService) ||
      selectedService.toUpperCase() === 'REFRESH' ||
      isDeviceRepair(selectedService) ||
      isScreenRepair(selectedService) ||
      isBatteryReplacement(selectedService) ||
      isScreenRepairBatReplacement(selectedService) ||
      isScreenRepairWarranty(selectedService))) {
      navigate('../repair', { replace: true });
    }

    processIncidentApi(
      interactionData?.InteractionLine?.InteractionLineId,
      getServiceRequestId(data),
      data?.CustomerCaseId,
      agreementData1?.ClientOffer.ClientOfferId,
      assetData!.MobileDeviceNumber,
      agreementData1?.AgreementId,
      agreementData1?.Customers?.Customer?.[0]?.CustomerId, //customerId,
      getIncidentType(),
      `${FailureDescriptiveText?.trim()} ${OtherSelected?.length > 0 ? '| ' + otherText : ''}`?.trim(),
      getDateAndDay(new Date(), 'YYYY-MM-DD'),
      assetData!.AssetCatalog.AssetCatalogId,
      assetData!.AssetId,
      assetData!.IMEI,
      isMalfunction(incidentType) ? triages : undefined,
      getTriageAnswers()
    )
      .then((data) => {

        setLoading(false);
        if (isClaimEligible(data)) {
          setServiceRequest(data);
          setProcessIncidentData(data);
          setSelectedAssetData(assetData);
          setInteractionLineData(interactionData?.InteractionLine.InteractionLineId);
          globalState?.dispatch({ type: ActionTypes.SET_PROCESS_INC_DATA, payload: data });
          // both are at same time, so remove one
          // logGTMEvent({
          //   event: GTM_EVENT.INCIDENT_TYPE_EVENT,
          //   IncidentType: data?.ServiceRequest?.IncidentType,
          //   Partner: carrier,
          //   Program: agreementData1?.ClientProductSkuNbr,
          //   MainSubscriberMDN: agreementData1?.ContactPoints?.ContactPoint?.filter(
          //     (val: any) => val?.PhoneNumber
          //   )?.[0]?.PhoneNumber,
          //   EmailAddress: agreementData1?.ContactPoints?.ContactPoint?.filter(
          //     (val: any) => val?.EmailAddress
          //   )?.[0]?.EmailAddress,
          //   Skill: domainType,
          //   ClaimEligibilty: true
          // });
          let GTMData = getGTMData(GTM_EVENT.INCIDENT_TYPE_EVENT, agreementData1, carrier, domainType, data?.CustomerCaseNumber)
          GTMData.ClaimEligibilty = true;          
          logGTMEvent(GTMData);
          // logGTMEvent({
          //   event: GTM_EVENT.TRIAGE_SELECTION_EVENT,
          //   IncidentType: data?.ServiceRequest?.IncidentType,
          //   Partner: carrier,
          //   Program: agreementData1?.ClientProductSkuNbr,
          //   MainSubscriberMDN: agreementData1?.ContactPoints?.ContactPoint?.filter(
          //     (val: any) => val?.PhoneNumber
          //   )?.[0]?.PhoneNumber,
          //   EmailAddress: agreementData1?.ContactPoints?.ContactPoint?.filter(
          //     (val: any) => val?.EmailAddress
          //   )?.[0]?.EmailAddress,
          //   Skill: domainType,
          //   ClaimEligibilty: true,
          //   SeletedTriage: getTriageAnswers()
          // });

          // check for holds and hold navigation
          if (data.Holds && data.Holds.length > 0) {
            handleHold(data);
            return;
          }

          if (
            isReplacement(data.ServiceRequest.IncidentType) ||
            isSwap(data.ServiceRequest.IncidentType)
          ) {
            callReplacementApi(data?.ServiceRequest?.ServiceRequestId); // call replacement api before RPM page
          }

          if (isReplacement(data.ServiceRequest.IncidentType)) {

            // redirect to LostMode for Apple Device OR redirect to replacement Page for Non-Apple devices
            if (assetData?.Make?.Name.toLowerCase() === 'apple') {
              navigate('../lostmode', { replace: true });
            } else {
              navigate('../replacement', { replace: true });
            }
          }
          // else if (
          //   data.ServiceRequest.IncidentType.toUpperCase() === 'DEVICE REFRESH' ||
          //   PerilType.toUpperCase() === 'FAULT REPAIR' ||
          //   isBatteryReplacement(PerilType)
          // ) {
          //   navigate('../repair', { replace: true });
          // }
          else if (isSwap(data.ServiceRequest.IncidentType)) {
           const shippedClaimCount = globalState?.state?.sessionResponse?.CustomerCaseResults?.CustomerCases?.filter(
              (d: any) => d.ShippingOrderStatusExist && d.ShippingOrderStatus.toUpperCase() == "SHIP"
              )
            if(shippedClaimCount.length){
              setfulFillmentMethod(data)
              navigate('/replacement', { replace: true });
            } 
            else navigate('../oemwarranty', { replace: true });
          } else if (isMalfunction(data.ServiceRequest.IncidentType)) {
            // IE OEM redirect to repair page
            navigate('../repair', { replace: true });
          }
        } else {
          // claim is not eligible show claim reveiw page
          globalState?.dispatch({
            type: ActionTypes.SET_LIMIT_EXCEED_DATA,
            payload: data?.Eligibility?.[0],
          });
          const GTMData = getGTMData(GTM_EVENT.INCIDENT_TYPE_EVENT, agreementData1, carrier, domainType, data?.CustomerCaseNumber)
          console.log('GTMData', GTMData);
          
          GTMData.ClaimEligibilty = false;          
          logGTMEvent(GTMData);
          // logGTMEvent({
          //   event: GTM_EVENT.INCIDENT_TYPE_EVENT,
          //   IncidentType: data?.ServiceRequest?.IncidentType,
          //   Partner: carrier,
          //   Program: agreementData1?.ClientProductSkuNbr,
          //   MainSubscriberMDN: agreementData1?.ContactPoints?.ContactPoint?.filter(
          //     (val: any) => val?.PhoneNumber
          //   )?.[0]?.PhoneNumber,
          //   EmailAddress: agreementData1?.ContactPoints?.ContactPoint?.filter(
          //     (val: any) => val?.EmailAddress
          //   )?.[0]?.EmailAddress,
          //   Skill: domainType,
          //   ClaimEligibilty: false
          // });
          navigate('../reviewhold', { replace: true });
        }
      })
      .catch((error) => {
        
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  const setfulFillmentMethod = (data: any) => {
    setLoading(true);
    setFulfillmentMethodApi(interactionData?.InteractionLine.InteractionLineId, 'RPLCMNT', getServiceRequestId(data))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  const handleHold = (data: any) => {
    const isVideoHold =
      data.Holds &&
        data.Holds.filter((val: any) => {
          return val.HoldType === 'VIDEO' && val.HoldStatus === 'OPEN';
        })?.length > 0
        ? true
        : false;

    const isDocumentHold =
      data.Holds &&
        data.Holds.filter((val: any) => {
          return val.HoldType === 'DOCUMENT' && val.HoldStatus === 'OPEN';
        })?.length > 0
        ? true
        : false;

    if (isVideoHold) {
      // redirect to video declaration
      navigate('../submitdeclaration', { replace: true });
    } else if (isDocumentHold) {
      // redirect document uplodescreen
      navigate('../econfirmation', { replace: true });
    } else {
      // redirect to Hard StopPage for any other hold
      navigate('../reviewhold', { replace: true });
    }
  }

  const getTriageAnswers = () => {
    const triageAnswers = triageList?.map((val: any) => {
      return { Que: val.Que, Ans: val.ans, key: PerilType };
    });

    return triageAnswers;
  };

  const loadTriageQuestions = (assetData: any) => {
    const deviceMake = (!hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) && assetData?.Make?.Name?.toUpperCase() === 'APPLE') ? 'APPLE' : 'OTHER';
    let TriageQuestionsFilter = globalState?.state?.sessionResponse.InitializeResponse?.TriageQuestions?.filter((a: any) => {
      return (
        a?.AssetmakeName?.toUpperCase() === deviceMake &&
        a?.FaultDetection?.toUpperCase() === PerilType?.toUpperCase() &&
        a.MultiSelect === 0 &&
        a?.QuestionType !== TRIAGE_QUESTION_TYPE.IN_WARRANTY &&
        a?.QuestionType !== TRIAGE_QUESTION_TYPE.IN_WARRANTY_OTHER
      );
    });

    const selectedAsset: any = JSON.parse(sessionStorage.getItem('dsMdn') || '');

    TriageQuestionsFilter = selectedAsset?.assetCategoryName && showGadgetRepairTriage(agreementData1?.ClientOffer?.ClientOfferName, features) ? gadgetTriage?.triageGadgets : TriageQuestionsFilter

    const Questions = Array.from(new Set(TriageQuestionsFilter?.map((val: any) => val.Question)))
      .map((uniqueQuestion: any) => {
        const questionData = TriageQuestionsFilter.find((val: any) => val.Question === uniqueQuestion);
        return {
          Que: questionData?.Question,
          ans: '',
          expAns: questionData?.Answer,
          type: questionData?.QuestionType,
          displayOrder: questionData?.DisplayOrder,
        };
      }) ?? [];

    // sort questions as per display order
    Questions?.sort((a: QueAns, b: QueAns) => {
      return a?.displayOrder - b?.displayOrder;
    });

    setTriageList(Questions);
  };

  const isAllQuestionAnswered = (Questions?: Array<QueAns>) => {
    const answers = Questions?.filter((item) => {
      return item.ans != undefined && item.ans?.trim().length > 0;
    });

    const state =
      answers?.length != undefined && answers?.length === triageList?.length;

    return state;
  };

  const isOtherAnswered = () => {
    if (OtherSelected?.length > 0) {
      if (otherText?.length > 0) return true;
      else return false;
    } else if (selectedMultiOptions?.length > 0 || isBatteryReplacement(PerilType) || isScreenRepair(PerilType) || isScreenRepairBatReplacement(PerilType) || isScreenRepairWarranty(PerilType) || !showTriageOtherIssuesQuestions(PerilType, agreementData1?.ClientOffer?.ClientOfferName, features)) {
      return true;
    }
    return false;
  };

  const [desiredServices, setDesiredServices] = useState<any>([]);

  useEffect(() => {
    if(agreementData1) {
      let isDisable;
      if(isDeviceRepair(PerilType) && agreementData1?.IsSmartSupport4Plus && assetData?.AssetCategory?.AssetCategoryName) {
        isDisable = isAllQuestionAnswered(triageList) && otherText?.length > 0;
      }
      else {
        isDisable = isAllQuestionAnswered(triageList) && isOtherAnswered();
      }
      if (isDisable) {
        triageDecisionMatrix();
      }
      setDisableNext(!isDisable);
    }
  }, [otherText, OtherSelected, agreementData1]);

  useEffect(() => {
    window
      .matchMedia('(min-width: 850px)')
      .addEventListener('change', (e) => setIsDesktop(e.matches));
  }, []);

  useEffect(() => {
    if (servicefeeData?.length > 0 && Object.keys(IncidentTypes).length > 0) {
      // let ProductFeature = agreementData1?.ProductFeatures?.ProductFeature?.map(item => item?.Services?.toUpperCase());

      const hasDeviceRefreshSupport = hasIncidentTypeSupport(INCIDENT_TYPES.DEVICE_REFRESH, agreementData1?.ClientOffer?.ClientOfferName, features);
      const _hasRepairSupport = hasIncidentTypeSupport(INCIDENT_TYPES.FAULT_REPAIR, agreementData1?.ClientOffer?.ClientOfferName, features)
      setHasRepairSupport(_hasRepairSupport);

      let newData: Array<serviceFeeResult>
      // else if(!hasIncidentTypeSupport(INCIDENT_TYPES.REPLACEMENT, agreementData1?.ClientOffer?.ClientOfferName, features)) {
      //   newData = servicefeeData.filter((d: serviceFeeResult) => deviceInPossession && hasDeviceRefreshSupport ? [INCIDENT_TYPES.SWAP?.toUpperCase()].includes(d.IncidentType) : []);
      // } 
      if (checkIsOnlyRefresh(agreementData1?.ClientOffer?.ClientOfferName)) {
        newData = servicefeeData.filter((d: serviceFeeResult) => deviceInPossession && hasDeviceRefreshSupport ? [INCIDENT_TYPES.DEVICE_REFRESH?.toUpperCase()].includes(d.IncidentType) : []);
      } else {
        newData = servicefeeData.filter((d: serviceFeeResult) =>
          deviceInPossession && !hasDeviceRefreshSupport
            ? d.IncidentType === INCIDENT_TYPES.SWAP?.toUpperCase()
            : deviceInPossession && hasDeviceRefreshSupport
              ? [INCIDENT_TYPES.SWAP?.toUpperCase(), INCIDENT_TYPES.DEVICE_REFRESH?.toUpperCase()].includes(d.IncidentType)
              : d.IncidentType === INCIDENT_TYPES.REPLACEMENT?.toUpperCase()
        );
      }
      let sortedData: Array<DesiredService> = newData.map((val: serviceFeeResult) => {
        return {
          service: IncidentTypes[val?.IncidentType],
          // fee: Math.round(parseInt(val?.Fees.TotalAmount))
          fee: val?.Fees.TotalAmount
        };
      });
      setDesiredServices(sortedData);
      queryClient.setQueryData(['servicefeeData'], sortedData);
    }
  }, [deviceInPossession, servicefeeData, IncidentTypes]);

  useEffect(() => {
    if (agreementData1) {
      // get programme name
      getProgrammeNameLbl();
      setCurrentAgreementData(agreementData1);
      globalState?.dispatch({
        type: ActionTypes.SET_CURRENT_AGREEMENT_DATA,
        payload: agreementData1,
      });
      queryClient.setQueryData(['findAgreement'], agreementData1);

      let assetData1: AssetData;
      // do not allow to stay on this page
      if(agreementData1?.IsNewClaimAllowed?.toUpperCase() === "NO" && incidentType){
        window.open(getRedirectUrl(getTypeByDomain()), '_self');
      }
      // for multi device support get selected device from session
      if (hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) && sessionStorage.getItem('dsMdn')) {
        // get selected asset details stored in session
        const selectedAsset: any = JSON.parse(sessionStorage.getItem('dsMdn') || '');
        assetData1 = agreementData1?.Assets.Asset.filter(
          (a: any) => a.AssetId === selectedAsset?.assetId
        )[0];

        setAssetDetails(assetData1);

        // if repair fulfillment type is OnSiteRepair then show claim eligible flow
        if (assetData1?.RepairFulfilmentMethod?.toUpperCase() === REPAIR_FULLFILLMENT_METHOD_TYPE.ONSITE) {
          setLoading(false)
          navigate('/claimInEligible', { replace: true })
          return;
        } else if (assetData1?.AssetRank?.toUpperCase() === 'ANCHOR DEVICE' && (!assetData1?.IMEI || assetData1?.IMEI?.length === 0)) {
          // if serial number is not added for anchor device then redirect user to update serial number
          setLoading(false)
          navigate('/updateAnchorDevice', { replace: true })
          return;
        }
      } else {
        assetData1 = agreementData1?.Assets.Asset.filter(
          (a: any) => a.AssetInstance === 'REPLACED'
        )[0];

        if (!assetData1 || assetData1 === undefined) {
          assetData1 = agreementData1?.Assets.Asset.filter(
            (a: any) => a.AssetInstance === 'ENROLLED'
          )[0];
        }
        globalState?.dispatch({ type: ActionTypes.SET_SELECTED_ASSET_DETAILS, payload: assetData1 });
        setAssetDetails(assetData1);
      }
      // load triageQuestions
      loadTriageQuestions(assetData1);
    }

    setSRSubmits(null);
    if (!agreementData1 || !globalState?.state?.sessionResponse) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [agreementData1 || globalState?.state?.sessionResponse]);

  const setAssetDetails = (assetData1: AssetData) => {
    setAssetData(assetData1);
    setSelectedAssetData(assetData1);
    queryClient.setQueryData(['assetData'], assetData1);
  }
  
  useEffect(() => {
    if (serviceRequestLoading) {
      setLoading(true);
    }
  }, [serviceRequestLoading]);

  useEffect(() => {
    if (agreementData1 && (
      PerilType?.toUpperCase() === 'SWAP' ||
      PerilType?.toUpperCase() === 'REFRESH' ||
      PerilType?.toUpperCase() === 'REPLACE')
    ) {
      if (triageList?.[0]?.ans === 'Yes' || !hasIncidentTypeSupport(INCIDENT_TYPES.REPLACEMENT, agreementData1?.ClientOffer?.ClientOfferName, features)) {
        setDeviceInPossession(true);
        setSelectedService(incidentType?.toUpperCase() === 'REFRESH' ? 'Device Refresh' : 'Swap');
      } else if (triageList?.[0]?.ans === 'No') {
        setDeviceInPossession(false);
        setSelectedService('Replacement');
      }
    }
  }, [triageList]);

  useEffect(() => {

    if (SwapLBL && SwapLBL?.length > 0) {
      getTitletextSwap(SwapLBL);
    }

    if (replLBL && replLBL?.length > 0) {
      getDescriptionReplace(replLBL);
    }

    if (deviceRepairLBL && deviceRepairLBL?.length > 0) {
      setDeviceRepairLBL(deviceRepairLBL);
    }

    if (screenRepairLBL && screenRepairLBL?.length > 0) {
      setScreenRepairLBL(screenRepairLBL);
    }

    if (deviceRefreshLBL && deviceRefreshLBL?.length > 0 && SwapLBL && SwapLBL?.length > 0) {
      getDescriptionRefresh(deviceRefreshLBL, SwapLBL);
      setDeviceRefreshLBL(deviceRefreshLBL);
    }

    if (replLBL && replLBL?.length > 0 && SwapLBL && SwapLBL?.length > 0) {
      setIncidentTypes({
        SWAP: SwapLBL,
        'DEVICE REFRESH': 'Refresh',
        REPLACEMENT: replLBL,
      })
    }

  }, [SwapLBL, deviceRefreshLBL, deviceRepairLBL, replLBL, screenRepairLBL])

  useEffect(() => {

    if(incidentType && incidentType === 'cleaning-services'){
      setLoading(false);
      navigate('../cleaningServices', { replace: true });
    }

  }, [incidentType])

  const getQuestionAnswer = () => { };

  const getProgrammeNameLbl = async () => {
    const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName);
    setProgrammeNameLBL(programmeName);
  }

  const onClickMulti = (v: any, e: any) => {
    const selectedOptions = MultiOptions.map((item: any) => {
      if (v.label === item.label) {
        item.selected = !item.selected;
        item.displayLabel = e?.target?.innerText;
      }
      return item;
    });
    const selectedOtherOption: any = MultiOptions?.filter((i: any) => {
      return i.selected === true && i?.label?.toUpperCase() === 'OTHER ISSUES';
    });
    const selectedMultiOptions: any = MultiOptions?.filter((i: any) => {
      return i.selected === true;
    });
    setSelectedOptions(selectedMultiOptions);
    setMultiOptions(selectedOptions);
    setMultiOptionsData(selectedMultiOptions);
    setOtherSelected(selectedOtherOption);
  };

  let FailureDescriptiveText = MultiOptions
    .filter(item => item?.selected && item?.label !== 'Other Issues')
    .map(item => item?.label)
    .join(' | ');


  let handleInputChange = (e: any) => {
    let inputValue = e.target.value;
    setOtherText(inputValue);
  };

  const onAnswerClick = (triageQue: QueAns, ans: string) => {
    const triageQues = triageList?.map((item: QueAns) => {
      if (triageQue.Que === item.Que) {
        item.ans = ans;
      }
      return item;
    });
    setTriageList(triageQues);
    let isDisable; //isAllQuestionAnswered(triageQues) && agreementData1?.IsSmartSupport4Plus ? otherText?.length > 0 : isOtherAnswered();
    if(isDeviceRepair(PerilType) && agreementData1?.IsSmartSupport4Plus && assetData?.AssetCategory?.AssetCategoryName) {
      isDisable = isAllQuestionAnswered(triageQues) && otherText?.length > 0;
    } else {
      isDisable = isAllQuestionAnswered(triageQues) && isOtherAnswered();
    }
    if (isDisable) {
      triageDecisionMatrix();
      getserviceFee();
    }
    setDisableNext(!isDisable);
  };

  const getTriageQuestionsByPeril = (peril: string) => {
    let assetData = agreementData1?.Assets.Asset.filter(
      (a: any) => a.AssetInstance === 'REPLACED'
    )[0];
    if(!assetData) assetData = agreementData1?.Assets.Asset.filter(
      (a: any) => a.AssetInstance === 'ENROLLED'
    )[0];
    const deviceMake = (!hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) && assetData?.Make?.Name?.toUpperCase() === 'APPLE') ? 'APPLE' : 'OTHER';
    return getTriageQuestions(peril, globalState?.state?.sessionResponse.InitializeResponse?.TriageQuestions, deviceMake, triageList || [] )
  }

  const isBatteryReplacementEligible = (questionType?: string) => {
    if (questionType) {
      // get Battery Replacement questions mapped with current answers
      let newtriageList = getTriageQuestionsByPeril(INCIDENT_TYPES.BATTERY_REPLACEMENT)
      const triageQues = newtriageList?.filter((item: QueAns) => {
        return item.type === questionType && item.expAns.toUpperCase() != item.ans.toUpperCase();
      });

      // Triage answer shoulbe be same as expected answer
      return triageQues?.length === undefined || triageQues?.length === 0;
    } else {
      const triageQues = triageList?.filter((item: QueAns) => {
        return item.expAns.toUpperCase() === item.ans.toUpperCase();
      });

      // all answer shoulbe be same as expected answer
      return triageQues?.length === triageList?.length;
    }
  };

  const isDeviceRepairEligible = () => {
    const triageQues = triageList?.filter((item: QueAns) => {
      return item.type === TRIAGE_QUESTION_TYPE.TRIAGE && item.expAns?.toUpperCase() != item.ans.toUpperCase();
    });

    // triage type question shoulbe be same as expected ans
    return triageQues?.length === undefined || triageQues?.length === 0;
  };

  const isScreenRepairEligible = (questionType?: string) => {
    // get Screen Repair questions mapped with current answers
    let newtriageList = getTriageQuestionsByPeril(INCIDENT_TYPES.SCREEN_REPAIR)
    const triageQues = newtriageList?.filter((item: QueAns) => {
      return item.type === (questionType || TRIAGE_QUESTION_TYPE.TRIAGE) && item.expAns.toUpperCase() != item.ans.toUpperCase();
    });

    // triage type question shoulbe be same as expected ans
    return triageQues?.length === undefined || triageQues?.length === 0;
  };

  const isScreenRepairWarrantyEligible = () => {
    const triageQues = triageList?.filter((item: QueAns) => {
      return item.type === TRIAGE_QUESTION_TYPE.TRIAGE && item.expAns.toUpperCase() != item.ans.toUpperCase();
    });

    // triage type question shoulbe be same as expected ans
    return triageQues?.length === undefined || triageQues?.length === 0;
  };

  const isScreenRepairBatteryReplacementEligible = () => {
    // get Battery Screen replacement questions mapped with current answers
    let newtriageList = getTriageQuestionsByPeril(INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT)
    const triageQues = newtriageList?.filter((item: QueAns) => {
      return item.type === TRIAGE_QUESTION_TYPE.SYMPTOMS && item.expAns.toUpperCase() == item.ans.toUpperCase();
    });

    // symptoms type question shoulbe be same as expected ans
    return triageQues?.length
  };

  const isOEM_IW_Eligible = () => {
    const triageQues = triageList?.filter((item: QueAns) => {
      return item.expAns?.toUpperCase() === item.ans.toUpperCase();
    });

    // user should be eligible for EW
    // all answer shoulbe be same as expected answer
    return triageQues?.length === triageList?.length;
  };

  const getserviceFee = () => {
    const offer = serviceFee?.filter((a: any) => {
      return a.IncidentType?.toUpperCase() === offeredPeril?.toUpperCase();
    });
    setOfferedserviceFee(offer);
  };

  const setTriageResetPeril = (triageStatus: boolean, offeredPeril: string) => {
    setTriageStatus(triageStatus);
    setOfferedPeril(offeredPeril)
  }

  const triageDecisionMatrix = () => {
    if (isBatteryReplacement(PerilType)) {  
      // If selected peril is Battery Replacement: offer the peril with which Triage matches  
      // 1.  if program supports device repair and battery replacement eligibility checked on all questions   
      // then offer battery replacement else device repair i.e fault repair
      if (hasIncidentTypeSupport(INCIDENT_TYPES.FAULT_REPAIR, agreementData1?.ClientOffer?.ClientOfferName, features) && isBatteryReplacementEligible()) {
        setTriageResetPeril(true, '')
      } else if (hasIncidentTypeSupport(INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT, agreementData1?.ClientOffer?.ClientOfferName, features) && isBatteryReplacementEligible(TRIAGE_QUESTION_TYPE.TRIAGE)) {
        // 2. if program supports Bat Scr Replacement, and if symptoms matches with it as triage type already supports battery replacement
        // then offer  bar scr replacement else battery replacement
        if (isScreenRepairBatteryReplacementEligible()) {
          setTriageResetPeril(false, INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT);
        }
        // 3. else offer battery replacement and Next button enables
        else setTriageResetPeril(true, '')
      } else {
        setTriageStatus(false);
        // if bat replacement triage fails, check for other supported repair types and if its reapective triage succeeds, offer that peril         
        // else offer swap
        if (hasIncidentTypeSupport(INCIDENT_TYPES.FAULT_REPAIR, agreementData1?.ClientOffer?.ClientOfferName, features) && isDeviceRepairEligible()) {
          setTriageResetPeril(false, deviceRepairLBL?.toUpperCase());          
        } else if (hasIncidentTypeSupport(INCIDENT_TYPES.SCREEN_REPAIR, agreementData1?.ClientOffer?.ClientOfferName, features)) {
          if (isScreenRepairEligible(TRIAGE_QUESTION_TYPE.TRIAGE) && isScreenRepairEligible(TRIAGE_QUESTION_TYPE.SYMPTOMS)) {
            setOfferedPeril(INCIDENT_TYPES.SCREEN_REPAIR)
          }
          else setOfferedPeril(SwapLBL?.toUpperCase())
        }
        else {
          setTriageResetPeril(false, SwapLBL?.toUpperCase());
        }
      }
    } else if (isDeviceRepair(PerilType)) {
      // check if IW-OEM is availble and Customer is eligible
      if (assetData?.IsEWEligible && isOEM_IW_Eligible()) {
        setTriageResetPeril(true, '')
        // update Peril type as Malfunction
        setSelectedService(INCIDENT_TYPES.MALFUNCTION);
      } else if (isDeviceRepairEligible()) {
        setTriageResetPeril(true, '')
      } else {
        setTriageResetPeril(false, SwapLBL?.toUpperCase());
      }
    } else if (isScreenRepair(PerilType)) {
      // If selected peril is Screen Repair: offer the peril with which Triage matches        
      if (isScreenRepairEligible(TRIAGE_QUESTION_TYPE.TRIAGE)) {
        // 1. if Screen repair triage succeeds based on only Triage type questions, check for Battery Replacement if its supported
        if (hasIncidentTypeSupport(INCIDENT_TYPES.BATTERY_REPLACEMENT, agreementData1?.ClientOffer?.ClientOfferName, features)) {
          if (isBatteryReplacementEligible(TRIAGE_QUESTION_TYPE.TRIAGE)) {
            setTriageStatus(false);
            // 2. if Battery Replacement triage succeeds check for Bat scr replacement if its supported,
            // if triage succeeds, offer bat scr replacement else battery replacement
            if (hasIncidentTypeSupport(INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT, agreementData1?.ClientOffer?.ClientOfferName, features)) {
              if (isScreenRepairBatteryReplacementEligible()) {
                setOfferedPeril(INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT)
              }
              else setOfferedPeril(INCIDENT_TYPES.BATTERY_REPLACEMENT)
            }
          }
        // 3. else check for remaining questions, so that if all screen repair triage matches, offer screen repair
        // else offer swap
          else if (isScreenRepairEligible(TRIAGE_QUESTION_TYPE.SYMPTOMS)) {
            setTriageResetPeril(true, '')
          }
          else {
            setTriageResetPeril(false, SwapLBL?.toUpperCase());
          }
        }
        // 4. this condition is to handle if battery replacement is not offered but bat scr replacement is there
        else if (hasIncidentTypeSupport(INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT, agreementData1?.ClientOffer?.ClientOfferName, features)) {
          if (isBatteryReplacementEligible(TRIAGE_QUESTION_TYPE.TRIAGE) && isScreenRepairBatteryReplacementEligible()) {
            setTriageResetPeril(false, INCIDENT_TYPES.SCREENREPAIR_BATTERYREPLACEMENT);
          }
        }
        else {
          // else screen repair is eligible
          if(isScreenRepairEligible(TRIAGE_QUESTION_TYPE.SYMPTOMS)) setTriageResetPeril(true, '')
          else setTriageResetPeril(false, SwapLBL?.toUpperCase())
        }
      } else {
        // offer swap if triage fails to offer any other peril
        setTriageResetPeril(false, SwapLBL?.toUpperCase())
      }
    } else if (isScreenRepairBatReplacement(PerilType)) {
      // If selected peril is Battery Screen Replacement: offer the peril with which Triage matches  
      // 1. If Screen repair is supported check for Battery replacement then for Bat scr replacement eligibility
      if (hasIncidentTypeSupport(INCIDENT_TYPES.SCREEN_REPAIR, agreementData1?.ClientOffer?.ClientOfferName, features)) {
        setTriageStatus(false);
        if (isScreenRepairEligible(TRIAGE_QUESTION_TYPE.TRIAGE)) {
          if (hasIncidentTypeSupport(INCIDENT_TYPES.BATTERY_REPLACEMENT, agreementData1?.ClientOffer?.ClientOfferName, features)) {
            if (isBatteryReplacementEligible(TRIAGE_QUESTION_TYPE.TRIAGE)) {
              if (isScreenRepairBatteryReplacementEligible()) {
                setTriageResetPeril(true, '')
              } else setOfferedPeril(INCIDENT_TYPES.BATTERY_REPLACEMENT);
            } else if (isScreenRepairEligible(TRIAGE_QUESTION_TYPE.SYMPTOMS)) {
              setOfferedPeril(INCIDENT_TYPES.SCREEN_REPAIR);
            } else setOfferedPeril(SwapLBL?.toUpperCase());
          }
        } else setOfferedPeril(SwapLBL?.toUpperCase());        
      } else {
        // 2. else offer swap
        setTriageResetPeril(false, SwapLBL?.toUpperCase())        
      }
    } else if (isScreenRepairWarranty(PerilType)) {
      if (isScreenRepairWarrantyEligible()) {
        setTriageResetPeril(true, '')
      } else {
        setTriageResetPeril(false, SwapLBL?.toUpperCase())
      }
    } else if (isMalfunction(PerilType)) {
      // all answers should be same as expected answer
      if (isBatteryReplacementEligible()) {
        setTriageResetPeril(true, '')
      } else {
        setTriageStatus(false);
      }
    }
  };

  const OnNextClick = () => {
    // if (triagestatus) {
    //   createServiceRequest();
    // }
    if (customerCaseResults) {
      callProcessIncidentApi(customerCaseResults)
    } else if (triagestatus) {
      createServiceRequest();
    }
  };
  const deviceMake = assetData?.Make?.Name?.toUpperCase() === 'APPLE' ? 'APPLE' : 'OTHER';

  const handleTakeMeThere = () => {
    // place this part in else of else if
    if (isTOM || globalState?.state?.isMaxis) {
      // const redirectUrl = domainType === 'repair' ? DPPortal_URL : RepairPortal_URL;
      const redirectUrl = domainType === DOMAIN.REPAIR ? DPPortal_URL : DPPortal_URL + '/ssoredirect';

      window.open(redirectUrl, "_blank")
    } else if ((isBatteryReplacement(PerilType) ||
    isScreenRepair(PerilType) ||
    isScreenRepairBatReplacement(PerilType))
    && offeredPeril) {      
      createServiceRequest();
    } else {
      window.history.back();
    }
  }

  const getDeviceImage = (): ReactNode => {
    if (hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName)) {
      return <></>
    } else {
      return <Image
        height={'80px'}
        marginTop={'22px'}
        alignSelf={'center'}
        src={getDeviceImageByModel(
          globalState?.state?.deviceImages,
          `${assetData?.AssetCatalog.AssetFamily}`,
          '',
          80
        )}
      ></Image>
    }
  }

  const MobileDeviceNumber = assetData && assetData?.MobileDeviceNumber?.split('_')?.[0];
  
  return (
    <Stack>
      <Box id={'myasurion-srswap-howcanwehelp-1'} paddingTop={'54px'}>
        <SubHeader
          showBackButton={true}
          backText={isChinese ? CNScripts?.Global.Back : Scripts.Global.Back}
          cancelText={Scripts.CancelSR.cancelBtnText}
          allowBackButtonClick={false}
          // onClick={() => window.open(getRedirectUrl(getTypeByDomain()), '_self')}>
          onClick={() => navigate(getRedirectUrl(getTypeByDomain()))}
          >
        </SubHeader>
        <Box
          paddingTop={'16px'}
          paddingX={'16px'}
          id={'myasurion-srswap-howcanwehelp-2'}
          marginX={isDesktop ? '20%' : '0%'}
        >
          <Text fontSize="32px" pb={'16px'} w={{ base: 'full', lg: '80%' }} margin={'auto'}>
            <TextScript
              text={isChinese ? CNScripts.HowCanWeHelpYou.HeaderText : Scripts.HowCanWeHelpYou.HeaderText}
              translationKey={`${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.HeaderText}`} />
          </Text>
          <Center>
            {assetData ? (
              <DeviceCard
                type=""
                status=""
                assetId={assetData && assetData?.AssetId.toString()}
                plan={programmeNameLBL || agreementData1?.ClientOffer?.ClientOfferName}
                mdn={`(${assetData?.CountryCallingCode?.split('+')?.[1] || agreementData1?.ContactPoints?.ContactPoint?.[0]?.CountryCallingCode}) ${MobileDeviceNumber
                  }`}
                model={`${assetData?.AssetCatalog.AssetCatalogName}`}
                imei={assetData?.IMEI}
                imageEl={getDeviceImage()}
              ></DeviceCard>
            ) : (
              <Skeleton minW={'335px'} minH={'150px'} w={'full'} borderRadius={'8px'} />
            )}
          </Center>
          {triageList?.length != undefined && triageList?.length > 0 && deviceMake &&
            !hideTriageQuestions(selectedService, agreementData1?.ClientOffer?.ClientOfferName) && (
              <Center>
                <TriageQuestion
                  enableButton={true}
                  triageQuestionList={triageList}
                  isDesktop={false}
                  getQuestionAnswer={getQuestionAnswer}
                  onAnswerClick={onAnswerClick}
                  pageName={HowCanWeHelpPageKeys.PageName}
                  incidentType={PerilType}
                  asset={deviceMake}
                  isDefaultChinese={isChinese}
                ></TriageQuestion>
              </Center>
            )}
          <Center>
            {isDeviceRepair(PerilType) && agreementData1?.IsSmartSupport4Plus && assetData?.AssetCategory?.AssetCategoryName ? 
            <></>
            :
            agreementData1?.ClientOffer?.ClientOfferName &&
              showTriageOtherIssuesQuestions(PerilType, agreementData1?.ClientOffer?.ClientOfferName, features) && (
                <Box w={{ base: 'full', lg: 'full' }}>
                  <Flex justifyContent="center"
                    alignContent="center">
                    <Text fontSize={'24px'} paddingTop={'36px'}>
                      <TextScript
                        text={'Please indicate if there are any other issues with your device:'}
                        translationKey={`${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.MultiSelectQuestion}`} />
                    </Text>
                  </Flex>
                  <Flex
                    direction="row"
                    wrap="wrap"
                    justifyContent="center"
                    alignContent="center"
                    id={'myasurion-profile-avatar'}
                    w={'full'}
                    h={'auto'}
                  >
                    {MultiOptions.map((val: any, i: any) => {
                      return (
                        <Tag
                          key={`Multi-${i}`}
                          width={{ base: '155px', lg: '280px' }}
                          height={{ base: '100px', lg: 'auto' }}
                          px={'16px'}
                          py={'12px'}
                          // alignItems={'center'}
                          textAlign="center"
                          margin={'6px'}
                          border={
                            val.selected ? `3px solid #000` : '1px solid #A5AAAF'
                          }
                          fontWeight={'700'}
                          background={'#FFF'}
                          display={'flex'}
                          color={bodyTextColor}
                          justifyContent="center"
                          onClick={(e) => {
                            onClickMulti(val, e);
                          }}
                        >
                          <TextScript
                            text={val.label}
                            translationKey={`${HowCanWeHelpPageKeys.PageName}/${val.scriptKey}`}
                          />
                        </Tag>
                      );
                    })}
                  </Flex>
                </Box>
              )}

          </Center>
          {
          // isDeviceRepair(PerilType) && agreementData1?.IsSmartSupport4Plus && assetData?.AssetCategory?.AssetCategoryName ?
          //   <></>
          //   :
          /*PerilType?.toUpperCase() === 'FAULT REPAIR' &&
            (isSS4 || isTOM)*/((agreementData1?.ClientOffer?.ClientOfferName && showTriageOtherIssuesQuestions(PerilType, agreementData1?.ClientOffer?.ClientOfferName, features) &&
            OtherSelected?.length > 0) || isDeviceRepair(PerilType) && agreementData1?.IsSmartSupport4Plus && assetData?.AssetCategory?.AssetCategoryName)  &&
            (<Box w={{ base: 'full', lg: '60%' }} margin='auto'>
              <Text px={'9px'} pt={'16px'} pb={'8px'} fontSize={'18px'}>
                <TextScript
                  text={'Please provide more details of your device issues.'}
                  translationKey={`${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.TextAreaLbl}`} />
              </Text>
              <Textarea
                h={'140px'}
                value={otherText}
                onChange={handleInputChange}
                paddingTop={'8px'}
                placeholder={Placeholdertexts ? Placeholdertexts : ''}
                maxLength={150}
              />
              <Flex justifyContent={'end'}>
                <Text pt={'5px'}>
                  {otherText.length}
                  <TextScript
                    text={`/150`}
                    translationKey={`${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.TextAreaCount}`} />
                </Text>
              </Flex>
            </Box>
            )}
          {deviceInPossession !== undefined ? (
            <Box
              id={'myasurion-srswap-deviceinpossession'}
              paddingTop={'24px'}
              paddingBottom={'24px'}
              // h={'40vh'}
              w={{ base: 'full', lg: '80%' }}
              margin={{ base: 0, lg: 'auto' }}
            >
              {deviceInPossession ? (
                <Box>
                  <Text fontWeight={'700'} lineHeight={'24px'} fontSize={'18px'}>
                    <TextScript
                      text={`Please confirm the service to proceed`}
                      translationKey={`${HowCanWeHelpPageKeys.PageName}/${HowCanWeHelpPageKeys.ChooseDesiredTextLbl}`} />
                  </Text>
                  <Tabs
                    bg={'gray.200'}
                    // colorScheme="black"
                    isFitted={true}
                    index={selectedTab}
                    onChange={(index) => {
                      setSelectedTab(index)
                      setSelectedService(desiredServices[index].service)
                    }}
                  >
                    <TabList>
                      {desiredServices.map((data: DesiredService) => {
                        return (
                          <Tab
                            _selected={{ color: 'black', fontWeight: 'bold', borderColor: 'black' }}
                            color={accent}
                            height={'56px'}
                            key={data.service}
                          >
                            {data?.fee
                              ? `${data.service} ${currencySymbol}${data.fee}`
                              : `${data.service}`}
                          </Tab>
                        );
                      })}
                    </TabList>
                    <TabPanels>
                      <TabPanel padding={'0px'}>
                        <DeviceService
                          title={titletextSwap || ''}
                          desc2=""
                          desc={descriptionSwap || Scripts.HowCanWeHelpYou.descriptionSwap}
                          feeText={desiredServices[0]?.fee && SRFtext && currencySymbol ? `${SRFtext} ${currencySymbol}${desiredServices[0]?.fee}` : `${Scripts.Global.SRFLBL}`}
                          deviceServiceButtonProps={{
                            label: 'Proceed',
                            onClick: () => !customerCaseResults ? createServiceRequest() : callProcessIncidentApi(customerCaseResults),
                            // disable: serviceFeeLoading ? true : false,
                            disable: false,
                          }}
                        ></DeviceService>
                      </TabPanel>
                      <TabPanel padding={'0px'}>
                        <DeviceService
                          title={titletextRefresh || Scripts.HowCanWeHelpYou.titletextRefresh}
                          desc={descriptionRefresh1 || Scripts.HowCanWeHelpYou.descriptionRefresh1}
                          desc2={descriptionRefresh2 || Scripts.HowCanWeHelpYou.descriptionRefresh2}
                          desc3={agreementData1?.firstDeviceRefreshEligible ? `SRF is waived for 1st ${deviceRefreshLBL} request` : null}
                          feeText={desiredServices?.[1]?.fee && SRFtext && currencySymbol ? `${SRFtext} ${currencySymbol}${desiredServices[1]?.fee}` : `${Scripts.Global.SRFLBL}`}
                          // feeText={agreementData1?.firstDeviceRefreshEligible  ? `${deviceRefreshFeetext} $0`: desiredServices?.[1]?.fee && deviceRefreshFeetext ? `${deviceRefreshFeetext} $${desiredServices[1]?.fee}` : `${Scripts.HowCanWeHelpYou.DeviceRefreshFeetext}`}
                          deviceServiceButtonProps={{
                            label: 'Proceed',
                            onClick: () => !customerCaseResults ? createServiceRequest() : callProcessIncidentApi(customerCaseResults),
                            // disable: serviceFeeLoading ? true : false,
                            disable: false,
                          }}
                        ></DeviceService>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              ) : (
                <>
                  {!checkIsOnlyRefresh(agreementData1?.ClientOffer?.ClientOfferName) &&
                    <Box>
                      <DeviceService
                        desc2=""
                        title={titletextReplace || Scripts.HowCanWeHelpYou.titletextReplace}
                        desc={descriptionReplace || Scripts.HowCanWeHelpYou.descriptionReplace}
                        feeText={desiredServices?.[0]?.fee && SRFtext && currencySymbol ? `${SRFtext} ${currencySymbol}${desiredServices[0].fee}` : `${Scripts.Global.SRFLBL}`}
                        deviceServiceButtonProps={{
                          label: 'Proceed',
                          onClick: () => !customerCaseResults ? createServiceRequest() : callProcessIncidentApi(customerCaseResults),
                          // disable: serviceFeeLoading ? true : false,
                          disable: false,
                        }}
                      ></DeviceService>
                    </Box>
                  }
                </>
              )}
            </Box>
          ) : (
            <></>
          )}

          {globalState?.state?.sessionResponse &&
            (isDeviceRepair(PerilType) || isBatteryReplacement(PerilType) || isScreenRepair(PerilType) || isScreenRepairBatReplacement(PerilType) || isScreenRepairWarranty(PerilType) || isMalfunction(PerilType)) &&
            triagestatus && (
              <Center>
                <Flex direction={'column'}>
                  {
                    <Box pt={'24px'} pb={'24px'}>
                      <Button
                        px={'20px'}
                        py={'12px'}
                        w={'343px'}
                        variant={'solid'}
                        onClick={() => OnNextClick()}
                        isDisabled={disableNext}
                        style={{
                          cursor: disableNext ? 'not-allowed' : '',
                          background: disableNext ? 'gray' : primary,
                          color: disableNext ? 'lightGray' : 'white',
                        }}
                      >
                        <TextScript
                          text={Scripts.Global.Next}
                          translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`} />
                      </Button>
                    </Box>
                  }
                </Flex>
              </Center>
            )}
          {isDeviceRepair(PerilType) && agreementData1?.IsSmartSupport4Plus && assetData?.AssetCategory?.AssetCategoryName && otherText?.length > 0 ? 
            <Text align={'center'} fontWeight={'700'} pb={'100px'}>Sorry your device is not eligible for repair.</Text>
          : !triagestatus &&
            (isDeviceRepair(PerilType) || isBatteryReplacement(PerilType) || isScreenRepairBatReplacement(PerilType) || isScreenRepairWarranty(PerilType) || isScreenRepair(PerilType)) &&
            // globalState?.state?.sessionResponse && hasRepairSupport && (
            (<Box
              pt={'24px'}
              pb={'24px'}
              w={{ base: 'full', lg: '80%' }}
              margin={{ base: 0, lg: 'auto' }}
            >
              <DeviceService
                tag={exploreOptions || Scripts.Global.ExploreYourOptions}
                desc2=""
                title={`Option for ${offeredPeril
                  } of Device`}
                desc={`Sorry You are not eligible for ${
                  // isScreenRepair(PerilType) ? screenRepairLBL : isDeviceRepair(PerilType) ? deviceRepairLBL : BatReplacementLBL
                  _PerilType
                  } You can Explore more options`} //need to do
                feeText={
                  (offeredPeril?.toUpperCase() === SwapLBL?.toUpperCase() || isSwap(offeredPeril?.toUpperCase()))
                    ? `${SRFtext} ${currencySymbol}${getSRFee(servicefeeData, INCIDENT_TYPES.SWAP)}`
                    : `${RepairSRFtext} ${currencySymbol}${getSRFee(servicefeeData, offeredPeril?.toUpperCase() === deviceRepairLBL?.toUpperCase() ? INCIDENT_TYPES.FAULT_REPAIR.toUpperCase() : offeredPeril)}`
                }
                deviceServiceButtonProps={{
                  label: 'Take me there',
                  onClick: handleTakeMeThere,
                  // disable: serviceFeeLoading ? true : false,
                  disable: false,
                }}
              ></DeviceService>
            </Box>
            )}
          {!triagestatus && selectedService?.toUpperCase() === 'REFRESH' && hasRepairSupport &&
            globalState?.state?.sessionResponse &&
            triageList?.length !== undefined &&
            triageList?.length > 0 && (
              <Box
                // pt={'40px'}
                // mt={{ base: '295px', lg: '130px' }}
                mb={'24px'}
                w={{ base: 'full', lg: '80%' }}
                mx={{ base: 0, lg: 'auto' }}
              >
                <DeviceService
                  tag={exploreOptions || Scripts.Global.ExploreYourOptions}
                  desc2=""
                  title={optionToRepair || Scripts.HowCanWeHelpYou.optionToRepair}
                  desc={descriptionExploreOptions || Scripts.HowCanWeHelpYou.descriptionExploreOptions}
                  // desc2=''
                  feeText={RepairSRFtext ? `${RepairSRFtext} ${currencySymbol}${getSRFee(servicefeeData, INCIDENT_TYPES.FAULT_REPAIR)}` : Scripts.Global.RepairFeeLBL}
                  deviceServiceButtonProps={{
                    label: 'Take me there',
                    onClick: handleTakeMeThere,
                    // disable: serviceFeeLoading ? true : false,
                    disable: false,
                  }}
                ></DeviceService>
              </Box>
            )}
          {isSwap(selectedService) && hasRepairSupport &&
            globalState?.state?.sessionResponse &&
            (isTOM || globalState?.state?.isMaxis || triageList?.length &&
              triageList?.[0]?.ans === 'Yes') && (
              <Box
                // pt={'0px'}
                // mt={{ base: '80px', lg: '130px' }}
                mb={'24px'}
                w={{ base: 'full', lg: '80%' }}
                mx={{ base: 0, lg: 'auto' }}
              >
                <DeviceService
                  tag={exploreOptions || Scripts.Global.ExploreYourOptions}
                  desc2=""
                  title={optionToRepair || Scripts.HowCanWeHelpYou.optionToRepair}
                  desc={descriptionExploreOptions || Scripts.HowCanWeHelpYou.descriptionExploreOptions}
                  feeText={RepairSRFtext ? `${RepairSRFtext} ${currencySymbol}${getSRFee(servicefeeData, INCIDENT_TYPES.FAULT_REPAIR)}` : Scripts.Global.RepairFeeLBL}
                  deviceServiceButtonProps={{
                    label: 'Take me there',
                    onClick: handleTakeMeThere,
                    // disable: serviceFeeLoading ? true : false,
                    disable: false,
                  }}
                ></DeviceService>
              </Box>
            )}
          {!triagestatus && isMalfunction(PerilType) && (
            <Box textAlign={'center'}>
              <Text fontWeight={'bold'} color={'red'} py={'24px'} fontSize={'22px'}>
                <TextScript
                  text={Scripts.HowCanWeHelpYou.triageFailure}
                  translationKey={`${translationsKeys.HowCanWeHelpPage.PageName}/${translationsKeys.HowCanWeHelpPage.triageFailure}`} />
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default HowCanWeHelpPage;
