/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-unused-vars */
import Scripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json'
import { 
  InWarrantyDataKey, 
  ResumeFlowDataKey, 
  getaspstorelistResponseKey, 
  getscheduledavailabilityResponseKey, 
  nightDeliveryFeeKey, 
  processIncidentKey,
  storeAddressKey, 
  storeDateKey, 
  storeIdKey, 
  storeTimeKey,
} from '../modules/config/constants';
import { useCentralStore } from '../store/appContext';
import { PickupSchedule, StoreSelection, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { TextScript , getTextScript} from '@MGPD/myasurion-shared';

import { getappointmentslotsApi, getaspstorelistAPi, getaspstorelistTOMAPi, getscheduledavailabilityTOMApi } from '../services/api';
import ActionTypes from '../store/actionTypes';
import { isAfter4PMSlot, isBatteryReplacement, saveError, showErrorPage, isScreenRepairBatReplacement, getDateAndDay, getTime } from '../utils/utills';
import { useNavigate } from 'react-router-dom';
import { shippingMethodContent } from './myclaims/EditDetails';
import moment from 'moment';
import { getGlobalDateFormat } from '../utils/localization-helper';

interface WalkingDetailsProps {
  preferredWalkingStoreLocation: string;
  preferredWalkingTimeSlot: string;
  preferredWalkingDate: string;
  setPreferredWalkingStoreLocation: (e: any) => void;
  setPreferredWalkingTimeSlot: (e: any) => void;
  setPreferredWalkingDate: (e: any) => void;
}

const WalkingDetails: React.FC<WalkingDetailsProps> = ({
  preferredWalkingStoreLocation,
  preferredWalkingTimeSlot,
  preferredWalkingDate,
  setPreferredWalkingStoreLocation,
  setPreferredWalkingTimeSlot,
  setPreferredWalkingDate,
}) => {
  const globalState = useCentralStore();

  const navigate = useNavigate();
  const WalkinDetailsKey = translationsKeys.WalkinDetails
  const [dateFormat, setDateFormat] = useState<string>('');
  const [InWarrantyData] = useSessionStorage<any>(InWarrantyDataKey, null);
  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const [ProcessIncidentResponse] = useSessionStorage<any>(processIncidentKey, null);
  const [assetData] = useSessionStorage<any>('assetData', null);
  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
  const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
  const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;
  const R_IncidentType = R_ServiceRequests?.IncidentType;
  // const R_ServiceOrderId = R_ServiceRequests?.ServiceOrder?.ServiceOrderId;

  const incType =
    globalState?.state?.processIncData?.ServiceRequest?.IncidentType || R_IncidentType;

  const hasDTAddOnAcquired =
    globalState?.state?.doorStepAddOnData?.hasAddOnAcquired || ResumeFlowData?.IsDataTransfer;

  // const queryClient = useQueryClient();
  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  const _locationCoordinates = globalState?.state?.locationCoordinates;
  const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;
  const isTOM = InitializeResponse?.ClientName === "Asurion_TechCare";
  const agreementData1: any =
  globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

  const assetData1 = agreementData1?.Assets?.Asset?.filter(
    (a: any) => a?.AssetInstance === 'ENROLLED'
  )?.[0];

  const [, setgetaspstorelistAPiResponse] = useSessionStorage<any>(
    getaspstorelistResponseKey,
    null
  );
  const [, setgetscheduledavailabilityApiResponse] = useSessionStorage<any>(
    getscheduledavailabilityResponseKey,
    null
  );
  const [processIncidentResponse] = useSessionStorage<any>(processIncidentKey, null);

  // interface serviceRequest {
  //   CustomerCaseId: string;
  //   CustomerCaseNumber: number;
  //   CustomerCaseStatus: string;
  //   ServiceRequest: {
  //     ServiceRequestId: string;
  //     ServiceRequestNumber: number;
  //     ServiceRequestStatus: string;
  //   };
  // }
  // const startServiceRequestResponse: serviceRequest = queryClient.getQueryData([
  //   'startServiceRequest',
  // ]) as serviceRequest;

  const ServiceRequest = processIncidentResponse && processIncidentResponse?.ServiceRequest;
  const [dateAndTimeSlots, setDateAndTimeSlot] = useState<any>([]);
  const [storeList, setStoreList] = useState([]);

  const [, setNighDeliverytFees] = useSessionStorage<any>(nightDeliveryFeeKey, null);

  const [, setStoreAddress] = useSessionStorage<any>(storeAddressKey, null);
  const [, setstoreDate] = useSessionStorage<any>(storeDateKey, null);
  const [, setStoreTime] = useSessionStorage<any>(storeTimeKey, null);
  const [, setStoreId] = useSessionStorage<any>(storeIdKey, null);

  const [walkingDate, setWalkingDate] = useState(preferredWalkingDate);
  const [walkingTime, setWalkingTime] = useState(preferredWalkingTimeSlot);

  const [selectedStoreId, setSelectedStoreId] = useState(preferredWalkingStoreLocation);

  const isRepairRefresh =
    incType?.toLowerCase() === 'device refresh' ||
    incType?.toLowerCase() === 'fault repair' ||
    isBatteryReplacement(incType) ||
    isScreenRepairBatReplacement(incType);

  const handleOnSelect = (e: any) => {
    setSelectedStoreId(e);
    setPreferredWalkingStoreLocation(e);
    const storeAddress: any = storeList?.filter((store: any) => store.ServiceProviderId === e);
    setStoreAddress({
      // AddressId: '8690B9C721F911EE835006D55EEA719D', // Placeholder for AddressID
      Address1: storeAddress[0]?.AddressLine1 || storeAddress[0]?.RepairStoreName,
      Address2: '',
      Address3: '',
      AddressLine1: storeAddress[0]?.AddressLine1 || storeAddress[0]?.RepairStoreName,
      City: storeAddress[0]?.City || '',
      StateProvinceCode: storeAddress[0]?.State || '',
      CountryCode: storeAddress[0]?.Country || '',
      PostalCode: storeAddress[0]?.PostalCode || '',
      RepairStoreName: storeAddress[0]?.RepairStoreName || '',
    });
    setStoreId(e);
    callgetschedule(e);
  };

  // const ServiceRequestId = '1EE26B205BEF0960879E6F522597E2C7';

  // Api Calls Started //
  const interactionData = globalState?.state?.sessionResponse?.Interaction

  const callgetschedule = (storeId: any) => {
    setLoading(true);
    setWalkingDate('');
    setWalkingTime('');
    setDateAndTimeSlot('');
    if(isTOM || globalState?.state?.isMaxis){
      getscheduledavailabilityTOMApi(
        {
          GetScheduledAvailabilityReq: {
            CustomerCaseId: ProcessIncidentResponse?.CustomerCaseId  || R_CustomerCaseId,
            Department: 'AOC - TLC',
            ServiceProviderId: storeId,
            ServiceRequestId: ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          },
        },
        interactionData?.InteractionLine?.InteractionLineId
      )
        .then((data) => {
          setLoading(false);
          const res = data?.availabilityData;
          const shipingMethods:shippingMethodContent[] = res.map((item: any) => ({
            EstimatedDeliveryDate: item.date,
            DeliverySlots: item.startTime,
          }));

          // change timeslot format for TOM
          if(isTOM)
            shipingMethods?.forEach(item => {
              return item.DeliverySlots = item?.DeliverySlots.map(time => moment(time, "HH:mm:ss").locale('en-US').format("hh:mm A"));
            });
          setgetscheduledavailabilityApiResponse(data);
  
          // do not show timeslots after 4 PM if DT add on aquired
          if(hasDTAddOnAcquired) {
            shipingMethods.map((item: any) => {
              let slots = item?.DeliverySlots?.filter((slot: any) => {
                if(isAfter4PMSlot(slot))
                  return false
  
                return true;
              });
                
              item.DeliverySlots = slots;
            })
          }
            
          setDateAndTimeSlot(shipingMethods);
        })
        .catch((error) => {
          setLoading(false);
          saveError(error, globalState);
          showErrorPage(navigate)
        });
    } else {
    getappointmentslotsApi(
      {
        AppointmentSlotsRequest: {
          ServiceRequestId: ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          ServiceProviderId: storeId,
        },
      },
      interactionData?.InteractionLine?.InteractionLineId
    )
      .then((data) => {
        setLoading(false);
        // setGetscheduledavailabilityResponse(data);
        const res = data?.availabilityData;
        const shipingMethods = res.map((item: any) => ({
          // deliveryDate: item.date,
          // deliverySlot: item.startTime,
          // shippingMethod: '',
          EstimatedDeliveryDate: item.date,
          DeliverySlots: item.startTime,
        }));
        setgetscheduledavailabilityApiResponse(data);

        // do not show timeslots after 4 PM if DT add on aquired
        if(hasDTAddOnAcquired) {
          shipingMethods.map((item: any) => {
            let slots = item?.DeliverySlots?.filter((slot: any) => {
              if(isAfter4PMSlot(slot))
                return false

              return true;
            });
              
            item.DeliverySlots = slots;
          })
        }
          
        setDateAndTimeSlot(shipingMethods);
      })
      .catch((error) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate)
      });
    }
  };

  const Make = assetData?.Make?.Name?.toUpperCase() || assetData1?.Make?.Name?.toUpperCase();

  useQuery(
    ['getaspstorelistData'],
    async () => {
      setLoading(true);
      if(isTOM || globalState?.state?.isMaxis){
        return await getaspstorelistTOMAPi(
          _locationCoordinates?.lat,
          _locationCoordinates?.lng,
          Make,
          ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          interactionData?.InteractionLine?.InteractionLineId
        ); 
      } else {
      return await getaspstorelistAPi(
        ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
        incType,

        interactionData?.InteractionLine?.InteractionLineId
      );
      }
    },
    {
      onSuccess: (data: any) => {
        setLoading(false);
        const resp = data?.GetASPListResponse;
        setgetaspstorelistAPiResponse(resp);
        handleStoreChange(resp);
      },
      onError: (error) => {
        console.error('error ala', error);
        saveError(error, globalState);
        showErrorPage(navigate)
      },
    }
  );

  // Api Calls ended //

  const handleStoreChange = (reponse: any) => {
    if (InWarrantyData && InWarrantyData?.ServiceProviderName) {
      // for IW claim show only last claim store details here
      let storeList = reponse?.filter(
        (val: any) => val.RepairStoreName === InWarrantyData?.ServiceProviderName
      );
      setStoreList(storeList);
    } else {
      setStoreList(reponse);
    }
  };

  const onDateSelect = (date: string) => {
    setWalkingDate(date);
    setPreferredWalkingDate(date);
    setstoreDate(date);
  };

  const onTimeSlotSelect = (timeSlot: string) => {
    setWalkingTime(timeSlot);
    setPreferredWalkingTimeSlot(timeSlot);
    setStoreTime(timeSlot);
    setNighDeliverytFees(null);
  };
  useEffect(() => {
    getSelectPreferredStoreLBLSwap()
    getSelectPreferredStoreLBL()
    getDateFormat();
  }, []);
  const WalkinDetailstranslationKey = translationsKeys.WalkinDetails
  const [SelectPreferredStoreLBLText, setSelectPreferredStoreLBLText] = useState('');
  const [SelectPreferredStoreLBLSwapText, setSelectPreferredStoreLBLSwapText] = useState('');

  const getSelectPreferredStoreLBL = async () => {
    const SelectPreferredStoreLBL = await getTextScript( 
      `${WalkinDetailstranslationKey.PageName}/${WalkinDetailstranslationKey.SelectPreferredStoreLBL}`,
      `${Scripts?.LogisticsScripts?.SelectPreferredStoreLBL}`
    ) 
    setSelectPreferredStoreLBLText(SelectPreferredStoreLBL);
  } 
  const getSelectPreferredStoreLBLSwap = async () => {
    const SelectPreferredStoreLBLSwap = await getTextScript(
      `${WalkinDetailstranslationKey.PageName}/${WalkinDetailstranslationKey.SelectPreferredStoreLBLSwap}`,
      `${Scripts?.LogisticsScripts?.SelectPreferredStoreLBLSwap}`) 
    setSelectPreferredStoreLBLSwapText(SelectPreferredStoreLBLSwap);
  } 

  // useEffect(() => {
  //   setWalkingDate(dateAndTimeSlots?.[0]?.EstimatedDeliveryDate);
  //   setPreferredWalkingDate(dateAndTimeSlots?.[0]?.EstimatedDeliveryDate);
  //   setWalkingTime(dateAndTimeSlots?.[0]?.DeliverySlots?.[0]);
  //   setstoreDate(dateAndTimeSlots?.[0]?.EstimatedDeliveryDate);
  //   setStoreTime(dateAndTimeSlots?.[0]?.DeliverySlots?.[0]);
  //   setPreferredWalkingTimeSlot(dateAndTimeSlots?.[0]?.DeliverySlots?.[0]);
  // },[dateAndTimeSlots])
  const getDateFormat = async () => {
    const format = await getGlobalDateFormat();
    setDateFormat(format);
  }

  return (
    <Box>
      <Text as="b" fontSize={'md'}>
        {isRepairRefresh ? 
          `${SelectPreferredStoreLBLText}`
         : `${SelectPreferredStoreLBLSwapText}`
         }
      </Text>
      <Box pt={2}>
        {storeList && storeList?.length > 0 && 
          <StoreSelection ASPStoreList={storeList} onSelect={handleOnSelect} />
        }
      </Box>
      {selectedStoreId && dateAndTimeSlots?.length > 0 && (
        <>
          <Text as="b" fontSize={'md'}>
          <TextScript
         text={Scripts?.LogisticsScripts?.SelectPreferredDateTimeLBL}
         translationKey={`${WalkinDetailsKey.PageName}/${WalkinDetailsKey.SelectPreferredDateTimeLBL}`}/>
          </Text>
          <Box pt={2}>
            <PickupSchedule
              isWalking={true}
              dateLabel={Scripts?.LogisticsScripts?.PreferredDateLBL}
              timeSlotLabel={Scripts?.LogisticsScripts?.PreferredTimeLBL}
              shipingMethods={dateAndTimeSlots}
              onDateSelect={onDateSelect}
              onTimeSlotSelect={onTimeSlotSelect}
              selectedDate={walkingDate}
              selectedTime={walkingTime}
              applyDateFormat={getDateAndDay}
              dateFormat={dateFormat || Scripts.Global.DateFormat}
              applyTimeTranslation={getTime}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default WalkingDetails;
