/* eslint-disable no-console */
import { useCentralStore } from '../store/appContext';
import { SubHeader, SubmitDeclaration } from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/layout';
import { useNavigate } from 'react-router-dom';
import CancelSrPopup from './myclaims/CancelSrPopup';
import Scripts from '../Scripts/asurion-script.json';
import { useState } from 'react';

const SubmitDeclarationPage = () => {
  const globalState = useCentralStore();

  const [openCancelPopup, setOpenCancelPopup] = useState(false);

  const Agreement =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  console.log('SubmitDeclarationPage-Agreement>>>>>>>>>>', Agreement);
  const navigate = useNavigate();

  const EnrolledDeviceMake = Agreement?.Assets?.Asset?.filter(
    (asset: any) => asset?.AssetInstance === 'ENROLLED'
  )?.[0]?.Make?.Name;
  console.log('SubmitDeclarationPage-EnrolledDeviceMake>>>>>>>>>', EnrolledDeviceMake);

  const handleClick = () => {
    navigate('/videodeclaration', { replace: true });
  };

  const handleSkipClick = () => {
    // if (EnrolledDeviceMake.toLowerCase() !== 'apple') {
    //   navigate('../lostmode');
    // } else {
    //   navigate('/replacement');
    // }
    navigate('../reviewhold', { replace: true });
  };

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  }

  return (
    <Box w={{ base: 'full', lg: '80%' }} margin='auto'>
      <Box px={'1rem'} my={'5rem'}>
        <SubHeader
          allowBackButtonClick={false}
          showCancelButton={true}
          showBackButton={false}
          cancelText={Scripts.CancelSR.cancelBtnText}
          onCancelClick={onCancelClick}>
        </SubHeader>
        <Box w={{ base: 'full', lg: '80%' }} margin='0 auto'>
          <SubmitDeclaration
            bodyText="You’re almost there! Please record a short video recording of yourself providing details of what happened to your phone."
            btnLabel="Start recording"
            header="Start video declaration"
            // linkLabel="Skip this step"
            tipText="Tip: Once you click ‘Start record’ some scripting will appear to assist you with providing the relevant information to us"
            onClickCallback={handleClick}
            onSkipClick={handleSkipClick}
          />
        </Box>
      </Box>
      <CancelSrPopup
        closeCancelPopup={setOpenCancelPopup}
        openCancelPopup={openCancelPopup}/>
    </Box>
  );
};

export default SubmitDeclarationPage;
