import { useSessionStorage, TextScript, useAccount, useConfig, getCarrierByDomain, getTypeByDomain } from '@MGPD/myasurion-shared';
import { Box, Checkbox, Flex, Text, Button, RadioGroup, Center, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
// import { getClientToken } from '../../services/api';
import { btTokenizeKey, btCreatePaymentMethodKey, btNewNonceKey, assetDataKey, btChargeOrderKey, btProcessPaymentKey, btSubmitOrderKey, ResumeFlowDataKey, serviceFeeKey, storeIdKey, deliveryTypeKey, processIncidentKey } from '../../modules/config/constants';
import { useState, useEffect } from 'react';
//import PaymentCCIcon from '../../icons/PaymentCCIcon';
// import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
    callCreateClient,
    callCreateGpay,
    callCreatePaypal,
    callCreateApplepay
} from "./BrainTreeWeb";

// import { useAccount } from '@MGPD/myasurion-shared';
import { v4 as uuid } from "uuid";
import { useCentralStore } from '../../store/appContext';
import ActionTypes from '../../store/actionTypes';
import PaymentOptions from './PaymentOptions';
import PaymentScript from '../../Scripts/asurion-script.json'
import PaymentKeys from '../../Scripts/translationsKeys.json'

import { headers } from '../../services/headers'
import { callLoggerApi, convertDateToUTC, getGTMData, isBatteryReplacement, isDeviceRefresh, isDeviceRepair, isEWSClaim, isInWarrantyOEM, isMalfunction, isPUR, isReplacement, isScreenRepair, isScreenRepairBatReplacement, isSwap, isWalkIn, saveError, showErrorPage } from '../../utils/utills';
import TextInput from '../component/TextInput';
import { chargeOrderApi, chargeOrderApiBrainTree, getClientToken, logGTMEvent, processOveragePayment, repairRequestSchedule, submitOrderApi, updateStatus } from '../../services/api';
import { getCurrencySymbol } from '../../utils/localization-helper';
import { getCountryCode, getMaid } from '../../utils/helper';
import PaymentOptionPopup from '../myclaims/PaymentOptionPopup';
import { hasMultiDeviceSupport } from '../../utils/featuresUtills';
import { GTM_EVENT } from '../../utils/constant';
 
function Payment() {
    const globalState = useCentralStore();
    const [account] = useAccount({ firstname: 'firstName', lastname: 'lastName', email: 'email' });
    const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
    const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);

    const showLoader = (val:boolean) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: val })
    }
    const showPaymentMessage = (val: boolean) => {
        globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_MESSAGE, payload: val });
    }

  const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode;
    const _locationCoordinates = globalState?.state?.locationCoordinates
    const agreementData: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0]
    const userAddress = agreementData?.Address

    // const startServiceRequestResponse: any = globalState?.state?.createServiceData;

    const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
    const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
    const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;
    const R_ServiceOrderId = R_ServiceRequests?.ServiceOrder?.ServiceOrderId;
    const R_ServiceOrderNumber = R_ServiceRequests?.ServiceOrder?.ServiceOrderNumber;
  const R_IncidentType = R_ServiceRequests?.IncidentType;

  
  const [ServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
  const LFLSRFFees =
    ServiceFeeData &&
    ServiceFeeData?.filter((item: any) => item?.IncidentType === R_IncidentType?.toUpperCase())?.[0]
      ?.Fees?.TotalAmount;
  
    const [isOpen, setIsOpen]  = useState<boolean>(false);
    const [validCVV, setValidCVV] = useState<any>(undefined);
    const [validCardNumber, setValidCardNumber] = useState<any>(undefined);
    const [validName, setValidName] = useState<any>(undefined);
    const [validExpDate, setValidExpDate] = useState<any>(undefined);
    const [cardType, setCardType] =  useState<any>(undefined);
    const [, setInValidCVV] = useState<any>(undefined);
    const [, setInValidCardNumber] = useState<string>("");
    const [, setInValidName] = useState<string>("");
    const [, setInValidExpDate] = useState<string>("");
    const [, setAuthenticationError] = useState<string>("");
    const [hostedFieldsInstance, setHostedFieldsInstance] = useState<any>();
    const [createThreeDSecure, setCreateThreeDSecure] = useState<any>();
    const [_paymentMethod, setPaymentMethod] = useState<string>("");
    const [deviceData, setDeviceData] = useState<string>("");
    const [, setBraintreeDetails] = useState<object>({});
    const [, setVerifyCardResponse] = useState<object>({});
    const [, setOpenPopup] = useState<boolean>(false);    
    const [clientToken, setClientToken] = useState<string>('');

    // const [, setBTClientTokenResp] = useSessionStorage<any>(btClientTokenKey, null);
    const [, setBTTokenizeResponse] = useSessionStorage<any>(btTokenizeKey, null);
    const [, setCreatePaymentMethodResults] = useSessionStorage<any>(btCreatePaymentMethodKey, null);
    const [, setNewNonce] = useSessionStorage<any>(btNewNonceKey, null);
    const [chargeOrderResponse, setChargeOrderResponse] = useSessionStorage<any>(btChargeOrderKey, null);
    const [, setProcessPaymentResponse] = useSessionStorage<any>(btProcessPaymentKey, null);
    const [, setSubmitOrderResponse] = useSessionStorage<any>(btSubmitOrderKey, null);
    const [srDeliveryType] = useSessionStorage<any>(deliveryTypeKey, null);
    const [btCreatePaymentMethodData] = useSessionStorage<any>(btCreatePaymentMethodKey, null);
    // const [BTClientTokenData] = useSessionStorage<any>(btClientTokenKey, null);
    const [StoreId] = useSessionStorage<any>(storeIdKey, null);
    const [, setApplePayResp] = useSessionStorage<any>("applePayResp", null);

    const isPickup = isPUR(srDeliveryType);

    const [walkingDate] = useSessionStorage<any>('storeDate', null);
    const [walkingTime] = useSessionStorage<any>('storeTime', null);

    const initializeData = globalState?.state?.sessionResponse?.InitializeResponse
    const interactionData = globalState?.state?.sessionResponse?.Interaction

    const [assetData] = useSessionStorage<any>(assetDataKey, null);

      //localization color theme changes
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { primary, bodyTextColor } = colors;

    const assetData1 = agreementData?.Assets?.Asset?.filter(
        (a: any) => a?.AssetInstance === 'ENROLLED'
      )?.[0]
    
    const _ServiceOrderId = globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderId || R_ServiceOrderId
    const _ServiceOrderNumber = globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderNumber || R_ServiceOrderNumber
    // const [gpayPaymentData, setGpayPaymentData] = useState<any>(undefined);
    const incType = globalState?.state?.processIncData?.ServiceRequest?.IncidentType || R_IncidentType
    // const isRepair = incType?.toLowerCase() === 'device refresh' || incType?.toLowerCase() === 'fault repair' || isBatteryReplacement(incType) || isScreenRepair(incType) || isScreenRepairBatReplacement(incType)
    
    const isRepair =
    isDeviceRefresh(incType) ||
    isDeviceRepair(incType) ||
    isBatteryReplacement(incType) ||
    isScreenRepair(incType) ||
    isScreenRepairBatReplacement(incType) ||
    (isMalfunction(incType) && isInWarrantyOEM(serviceRequestData)) ||
    isEWSClaim(serviceRequestData, incType);

    const processIncData = globalState?.state?.processIncData

    // const _ServiceOrderId = globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderId
    // const _ServiceOrderNumber = globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderNumber
    const R_SRF = ResumeFlowData?.ServiceFee?.ServiceFee && parseFloat(ResumeFlowData?.ServiceFee?.ServiceFee)?.toFixed(2);

    const [totalSRFee, setTotalSRFee] = useState<any>(globalState?.state?.srf || R_SRF)
    const [currencySymbol, setCurrencySymbol] = useState('$')

    useEffect(() => {
        if (globalState?.state?.chargeNightDeliveryFee) {
            setTotalSRFee(Number(globalState?.state?.nightDeliveryFee))
        } else {
            setTotalSRFee((Number(globalState?.state?.srf || R_SRF || LFLSRFFees) + Number(globalState?.state?.nightDeliveryFee) + Number(globalState?.state?.doorStepAddOnData.fee))?.toFixed(2))
        }
    }, [globalState?.state?.nightDeliveryFee, R_SRF, LFLSRFFees, globalState?.state?.doorStepAddOnData.fee])
        
    useEffect(() => {
        clearPaymentDetails();
        getCurrency();
    }, [])

    useEffect(() => {
        if(!globalState?.state?.selectedPaymentMethod){
            clearPaymentDetails();
        }
    }, [globalState?.state?.selectedPaymentMethod])

    const getCurrency = async () => {
        const currency = await getCurrencySymbol();
        setCurrencySymbol(currency);
    }

    const [] = useSessionStorage<any>(btChargeOrderKey, null);

    const isBraintree = initializeData?.isBraintree
    // const maid = isBraintree?.maid
    const maid = getMaid(incType, isBraintree)
    const is3DS = isBraintree?.method?.creditCard?.is3DS;

    const hasDTAddOnAcquired = false;//globalState?.state?.doorStepAddOnData?.hasAddOnAcquired || ResumeFlowData?.IsDataTransfer;

    function generateUniqueId() {
        return uuid();
    }

    // const headers = {
    //     "asurion-channel": "online",
    //     "asurion-client": "starhub",
    //     "asurion-enduser": "online",
    //     "asurion-lineofbusiness": "MOBILITY",
    //     "asurion-region": "APAC-SEA",
    //     "asurion-application": "myasurion",
    //     'Asurion-interactionlineid': interactionData?.InteractionLine?.InteractionLineId,
    //     'asurion-correlationid': generateUniqueId().replace(/-/g, "").toUpperCase()
    // }
    
    const createClient = (clientTokenResponse: string) => {
        return callCreateClient(
            clientTokenResponse,
            onCCSuccess,
            setValidCardNumber,
            setValidName,
            setValidExpDate,
            setValidCVV,
            setInValidCardNumber,
            setInValidName,
            setInValidExpDate,
            setInValidCVV,
            setAuthenticationError,
            setCardType
        );
    };

    const onCCSuccess = (
        hostedFieldsInstance: any,
        threeDSecureInstance: any,
        deviceData: any
    ) => {
        setHostedFieldsInstance(hostedFieldsInstance);
        setCreateThreeDSecure(threeDSecureInstance);
        setPaymentMethod("CRE");
        setDeviceData(deviceData)
        setBraintreeDetails(
            {
                deviceData: deviceData,
                paymentMethod: 'CRE'
            }
        )
        showLoader(false);
    };

    const createPaypal = (clientTokenResponse: any) => {
        showLoader(true)
        return callCreatePaypal(
          clientTokenResponse,
          onPayPalSuccess,
          agreementData,
          showLoader,
          getCountryCode(currencyCode, 'payment')
        );
      };

      const onPayPalSuccess = (response: any, deviceData: any) => {
        let paymentMethod = 'PYPL'
        setPaymentMethod("PYPL");
        showLoader(false)
        return callCreatePaymentMethod(response, paymentMethod, deviceData)
      };

    const createGpay = (clientTokenResponse: string) => {
        return callCreateGpay(clientTokenResponse, totalSRFee, onGpaySuccess, showLoader);
    };
        
    let _gpayPaymentData: any;

    const onGpaySuccess = (response: any, deviceData: any, _paymentData: any) => {
        showLoader(false)
        // console.log(response, deviceData,  "Gpay Nonce, deviceData");
        // setGpayPaymentData(_paymentData)
        _gpayPaymentData = _paymentData
        setPaymentMethod("GGLP");
        let paymentMethod = 'GGLP'
        next(response, response, paymentMethod, deviceData);
        // let paymentMethod = "GPAY";
    };

    const createApplepay = (clientTokenResponse: string) => {
        return callCreateApplepay(clientTokenResponse, totalSRFee, onApplepaySuccess, showLoader);
    }

    const onApplepaySuccess = (response: any, deviceData: any) => {
        // showLoader(false)
        console.log(response, deviceData,  "Applepay Nonce, deviceData");
        setPaymentMethod("APLP");
        let paymentMethod = 'APLP'
        setApplePayResp(JSON.stringify(response))
        next(response, response, paymentMethod, deviceData);
    }
 
    // useQuery(
    //     ['getClientTokenResponse'],
    //     async () => {
    //         showLoader(true)
    //         return await getClientToken(maid, interactionData?.InteractionLine.InteractionLineId)
    //     },
    //     {
    //         onSuccess: (data: any) => {
    //             setBTClientTokenResp(data.clientToken)
    //             showLoader(false)
    //         },
    //         onError: (error) => {
    //             console.log("getClientTokenResponse error===>", error)
    //             showLoader(false)
    //             saveError(error, globalState);
    //             showErrorPage(navigate)
    //         }
    //     }
    // )

    const navigate = useNavigate();
    
    const [isCheckboxChecked, setCheckboxChecked] = useState(false);
    const [bldgValue, setBldgValue] = useState<string>();
    const [addressVal, setAddress] = useState<string>();
    const [unitNumber, setUnitNumber] = useState<string>();
    const [postalCode, setPostalCode] = useState<any>();

    const addressChecked = () => {
        setCheckboxChecked(!isCheckboxChecked)
        setBldgValue(!isCheckboxChecked ? userAddress?.Address1 : '')
        setAddress(!isCheckboxChecked ? userAddress?.Address2 + ' ' + userAddress?.City : '')
        setUnitNumber(!isCheckboxChecked ? userAddress?.Address3 : '')
        setPostalCode(!isCheckboxChecked ? userAddress?.PostalCode : '')

        setIsInavlidBldgValue(false)
        setIsInavlidAddress(false)
        setIsInavlidUnitNumber(false)
        setIsInavlidPostalCode(false)
    }

    const [isValidBldgValue, setIsInavlidBldgValue] = useState<boolean>(false);
    const [isValidAddressVal, setIsInavlidAddress] = useState<boolean>(false);
    const [isValidUnitNumber, setIsInavlidUnitNumber] = useState<boolean>(false);
    const [isValidPostalCode, setIsInavlidPostalCode] = useState<boolean>(false);

    const handleInpuTBldgLBL = (e: any) => {
        if(e?.value?.length > 100) {
            setIsInavlidBldgValue(true)
            setBldgValue('')
        } else {
            setIsInavlidBldgValue(false)
        }
        setBldgValue(e?.value)
    }

    const handleInputAddress = (e: any) => {
        if(e?.value?.length > 100) {
            setIsInavlidAddress(true)
        } else {
            setIsInavlidAddress(false)
        }
        setAddress(e.value)
    }

    const handleInputUnitNumber = (e: any) => {
        if(e?.value?.length > 100) {
            setIsInavlidUnitNumber(true)
        } else {
            setIsInavlidUnitNumber(false)
        }
        setUnitNumber(e.value)
    }

    const handleInputPostalCode = (e: any) => {
        // if(e?.target?.value?.length > 100) {
        //     setIsInavlidPostalCode(true)
        // } else {
        //     setIsInavlidPostalCode(false)
            let testPostal = globalState?.state?.isMaxis ? /[0-9A-Fa-f]{5}/g : /[0-9A-Fa-f]{6}/g
            if(!testPostal?.test(e.value)) {
                setIsInavlidPostalCode(true)
            } else {
                setIsInavlidPostalCode(false)
            }
            setPostalCode(e.value)
        // }
    }

    const setPaymentError = (errmsg: string) => {
        globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_ERROR, payload: errmsg });
    }

    const enableButton = validCardNumber && validCVV && validExpDate && validName && (cardType?.toUpperCase() === 'VISA' || cardType?.toUpperCase() === 'MASTERCARD') && (isCheckboxChecked || (addressVal && unitNumber && postalCode && !isValidAddressVal && !isValidUnitNumber && !isValidPostalCode)) //bldgValue

    const callCreatePaymentMethod = async (verifyCardResponse: any, paymentMethod: any, deviceData: any) => {        
        const CreatePaymentMethodParameters = {
            ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
            AgreementId: agreementData?.AgreementId,
            ServiceOrderId: _ServiceOrderId,
            CreateCustomerRequest: {
                Amount: totalSRFee?.toString(),//globalState?.state?.srf.substring(1), //servicefeeDetails?.[0]?.fee,
                Nonce: verifyCardResponse?.nonce,
                Bin: verifyCardResponse?.details?.bin,
                Email: account?.email,
                Billing: {
                    GivenName: paymentMethod === 'CRE' ? verifyCardResponse?.details?.cardholderName : verifyCardResponse?.details?.firstName,//agreementData?.Customers?.Customer[0]?.FirstName,
                    SurName: paymentMethod === 'CRE' ? '' : verifyCardResponse?.details?.lastName,//agreementData?.Customers?.Customer[0]?.LastName,
                    PhoneNumber: String(
                        agreementData?.ContactPoints?.ContactPoint[0]?.PhoneNumber
                    ),
                    streetAddress: userAddress?.Address1,
                    ExtendedAddress: userAddress?.Address2,
                    Locality: userAddress?.City,
                    Region: getCountryCode(currencyCode, 'payment'), // ISO-3166-2 code
                    PostalCode: userAddress?.PostalCode,
                    CountryCodeAlpha2: getCountryCode(currencyCode, 'payment'),
                },
                AdditionalInformation: {
                    WorkPhoneNumber: String(
                        agreementData?.ContactPoints?.ContactPoint[0]?.PhoneNumber
                    ),
                    ShippingGivenName: agreementData?.Customers?.Customer[0]?.FirstName,
                    ShippingSurname: agreementData?.Customers?.Customer[0]?.LastName,
                    ShippingPhone: String(
                        agreementData?.ContactPoints?.ContactPoint[0]?.PhoneNumber
                    ),
                    ShippingAddress: {
                        StreetAddress: userAddress?.Address1,
                        ExtendedAddress: userAddress?.Address2,
                        Locality: userAddress?.City,
                        Region: getCountryCode(currencyCode, 'payment'), // ISO-3166-2 code
                        PostalCode: userAddress?.PostalCode,
                        CountryCodeAlpha2: getCountryCode(currencyCode, 'payment'),
                    },
                },
            },
        }
        showLoader(true);
        try {
            const response = await axios.post(
                `/swap/claim/braintree/api/createpaymentmethod/${_ServiceOrderId}`,
                { CreatePaymentMethodParameters }, { headers: { ...headers, 'Asurion-interactionlineid': interactionData?.InteractionLine.InteractionLineId }, }
            );
            setCreatePaymentMethodResults(response?.data?.CreatePaymentMethodResults)
            if (paymentMethod === "CRE") {
                  return callNewNonce(response?.data?.CreatePaymentMethodResults, paymentMethod, deviceData);
            }
            else {
                return next(verifyCardResponse, response?.data?.CreatePaymentMethodResults, paymentMethod, deviceData);
            }
        } catch (error) {
            console.error(error);
            showLoader(false);
            showPaymentMessage(false)
            setPaymentError('Payment failed')
            callLoggerApi(error, globalState);
            // saveError(error, globalState);
            // showErrorPage(navigate)
        }
    };

    let threeDSecureParameters: any = {
        amount: totalSRFee?.toString(),//globalState?.state?.srf.substring(1), //servicefeeDetails?.[0]?.fee, //isOverageFlow ? replacementOption?.OverageAmount : ServiceFeeResponse?.TotalAmount || BraintreeDetails?.TotalAmount, // check with team
        email: account?.email,
        billingAddress: {
            givenName: agreementData?.Customers?.Customer[0]?.FirstName, // ASCII-printable characters required, else will throw a validation error
            surname: agreementData?.Customers?.Customer[0]?.LastName, // ASCII-printable characters required, else will throw a validation error
            phoneNumber: String(
                agreementData?.ContactPoints?.ContactPoint[0]?.PhoneNumber
            ),
            streetAddress: userAddress?.AddressLine1,
            extendedAddress: userAddress?.AddressLine2,
            locality: userAddress?.CityName,
            region: "MY", // ISO-3166-2 code
            postalCode: userAddress?.PostalCode,
            countryCodeAlpha2: "MY",
        },
        additionalInformation: {
            workPhoneNumber: String(
                agreementData?.ContactPoints?.ContactPoint[0]?.PhoneNumber
            ),
            shippingGivenName: agreementData?.Customers?.Customer[0]?.FirstName,
            shippingSurname: agreementData?.Customers?.Customer[0]?.LastName,
            shippingPhone: String(
                agreementData?.ContactPoints?.ContactPoint[0]?.PhoneNumber
            ),
            shippingAddress: {
                streetAddress: userAddress?.AddressLine1,
                extendedAddress: userAddress?.AddressLine2,
                locality: userAddress?.CityName,
                region: "MY", // ISO-3166-2 code
                postalCode: userAddress?.PostalCode,
                countryCodeAlpha2: "MY",
            },
        },
        onLookupComplete: function (data: any, next: any) {
            console.log(data);
            showLoader(false);
            next();
        },
    };

    const callNewNonce = async (paymentMethodResp: any, paymentMethod: any, deviceData: any) => {

        try {
            const newNonce = await axios.get(
                `/swap/claim/braintree/api/createnewnonce/${paymentMethodResp?.Token}/${processIncData?.CustomerCaseId || R_CustomerCaseId}`,
                {
                    headers: { ...headers, 'Asurion-interactionlineid': interactionData?.InteractionLine.InteractionLineId },
                }
            );
            setNewNonce(newNonce)
            threeDSecureParameters.nonce = newNonce?.data?.paymentMethodNonce?.nonce;
            threeDSecureParameters.bin = newNonce?.data?.paymentMethodNonce?.details?.bin;
            createThreeDSecure
                .verifyCard(threeDSecureParameters)
                .then((verifyCardResponse: any) => {
                    let StoreVerifyCardRequest = {
                        AgreementId: agreementData?.AgreementId,
                        CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
                        ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
                        VerifiedCardDetails: verifyCardResponse
                    };
                    try {
                        axios.post(
                            `/swap/claim/braintree/api/storeverifiedcard`,
                            { StoreVerifyCardRequest }, { headers: { ...headers, 'Asurion-interactionlineid': interactionData?.InteractionLine.InteractionLineId }, }
                        );                        
                    } catch (error) {                        
                        console.error(error);
                        showLoader(false)
                        showPaymentMessage(false)
                        // saveError(error, globalState);
                        // showErrorPage(navigate)
                        callLoggerApi(error, globalState);
                    }
                    if (verifyCardResponse?.threeDSecureInfo?.status === "challenge_required") {
                        showLoader(false);
                    } else if (!verifyCardResponse?.liabilityShifted) {
                        // setBGError(homeplusscript.LIABILITY_SHIFTED_ERROR);
                    } else if (verifyCardResponse?.liabilityShifted) {
                        return next(verifyCardResponse, paymentMethodResp, paymentMethod, deviceData);
                    } else {
                        // setBGError(homeplusscript.BG_ERROR_GENERIC);
                    }
                })
                .catch((err: any) => {
                    showLoader(false);
                    showPaymentMessage(false)
                    // setPaymentError('Payment failed')
                    callLoggerApi(err, globalState);
                    // saveError(err, globalState);
                    // showErrorPage(navigate)
                });

        } catch (error) {
            console.error(error);
            showLoader(false)
            showPaymentMessage(false)
            callLoggerApi(error, globalState);
            // saveError(error, globalState);
            // showErrorPage(navigate)
        }
    };

    const callSubmitOrder = async () => {
        showLoader(true);
        const SubmitServiceOrderParameters = {
              ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
              InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
              ServiceOrderId: _ServiceOrderId,
          };
          try {

            // navigate to final page and then call submit API in background
            showLoader(false);
            // sessionStorage.removeItem('ResumeFlowData');
            navigate('/claimconfirmation', { replace: true })

            submitOrderApi({ SubmitServiceOrderParameters })
            .then((response: any) => {
                setSubmitOrderResponse(response?.SubmitServiceOrderResults);
                showPaymentMessage(false)
                const GTMData = getGTMData(GTM_EVENT.SUBMIT_ORDER_EVENT, globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0], 
                    getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
                    logGTMEvent(GTMData)
            }).catch(error => {
                console.error(error);
                showPaymentMessage(false)
                callLoggerApi(error, globalState);
            })
        } catch (error) {
            showLoader(false);
            showPaymentMessage(false)
            // navigate('/claimconfirmation')
            console.error(error);
            // onApiFailure(ActionTypes.PROCESS_PAYMENT, error);
            setPaymentError('Payment failed')
            saveError(error, globalState);
            showErrorPage(navigate)
        }
    }


    const callProcessPaymentApi = async (
        ProcessPaymentParameters: any,
        ServiceOrderId: any,
        ChargeOrderId: any,
      ) => {
        if(ChargeOrderId) {
            showLoader(true);
            try {
                const response = await axios.post(
                    `/swap/claim/api/v3/processpayment/${ServiceOrderId}/${ChargeOrderId}`,
                    { ProcessPaymentParameters  }, { headers: { ...headers, 'Asurion-interactionlineid': interactionData?.InteractionLine.InteractionLineId }, }
                );
                // if(response) {
                    setProcessPaymentResponse(response?.data?.ProcessPaymentResults);
                    
                    // if(incType?.toLowerCase() === 'device refresh' || incType?.toLowerCase() === 'fault repair') {
                    //     let CreateRepairRequestParams = {        
                    //         InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,        
                    //         CustomerCaseId: processIncData?.CustomerCaseId,        
                    //         ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId,        
                    //         ServiceOrderId: _ServiceOrderId,        
                    //         RepairRequestType: srDeliveryType === 'Pick-up and return' ? 'PUR' : 'WALKIN'    
                    //     }
                    //     try {
                    //         const repairrequestResponse = await axios.post(
                    //             `/swap/claim/api/repairrequest/create`,
                    //             { CreateRepairRequestParams  }, { headers: headers }
                    //         );
                    //         console.log('repairrequest repairrequestResponse', repairrequestResponse);

                    //         callSubmitOrder()
                    //     }
                    //     catch (error) {
                    //         console.log('repairrequest error', error);
                    //     }
                if (globalState?.state?.chargeNightDeliveryFee) {
                    showPaymentMessage(false);
                    callProcessOveragePaymentApi();
                } else {
                    callSubmitOrder()
                }
                    // } else {
                        // callSubmitOrder()
                    // }
                // }
                
            } catch (error) {
                showLoader(false);
                // navigate('/claimconfirmation')
                setPaymentError('Payment failed')
                callLoggerApi(error, globalState);
                console.error(error);
                showPaymentMessage(false)
                // onApiFailure(ActionTypes.PROCESS_PAYMENT, error);
                // saveError(error, globalState);
                // showErrorPage(navigate)
            }
        }
      };

    const callProcessPayment = (ChargeOrder: any, _payment_method: any, _chargOrderPayload: any, optn: any) => {
        const paymentMethod = _payment_method || initializeData?.isBraintree?.paymentMethod
        //let paymentMethodType = isBraintree ? (paymentMethod === "CRE" ? "CRE" : paymentMethod === "PYPL" ? "PYPL" : "COD") : (ChargeOrder?.PaymentMethodType === "CRE" ? "CRE" : "COD")
        const ProcessPaymentParameters: any = {
            ChargeOrderId: ChargeOrder?.ChargeOrderId,
            ServiceOrderId: _ServiceOrderId,
            ApplicationId: initializeData?.ApplicationId,
            ClientId: initializeData?.ClientId,
            InteractionLineId:interactionData?.InteractionLine?.InteractionLineId,
            ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            ServiceOrderNumber: _ServiceOrderNumber?.toString(),
            AuthorizeAdditionalCharges: false,
            ValidateAddress: false,
            ValidateCardSecurityCode: false,
            IpAddressForFraud: interactionData?.SourceIpAddress,
            FulfillmentOption: "RPLCMNT",
            PaymentInformation: {
              PaymentMethodType: paymentMethod,
            //   CardType: paymentMethod === "CRE" ? "CREDIT" : "",
              CardType: ChargeOrder?.CardType, // in future, CardType check for other payment method
              CardBrand: paymentMethod === "CRE" ? ChargeOrder?.CardBrand : paymentMethod === "PYPL" ? ChargeOrder?.CardBrand : "",
            //   CardCheckNumber: paymentMethod === "CRE" ? ChargeOrder?.CardCheckNumber?.toString() : "",
              CardCheckNumber: ChargeOrder?.CardCheckNumber?.toString(),
              AccountHolder: {
                FirstName: paymentMethod === 'GGLP' ? _gpayPaymentData?.paymentMethodData?.info?.billingAddress?.name : paymentMethod === 'APLP' ? _chargOrderPayload?.CardHolderFirstName : _chargOrderPayload?.CardHolderFirstName,//agreementData?.Customers?.Customer[0]?.FirstName,
                LastName: paymentMethod === 'GGLP' || paymentMethod === 'APLP' ? '' : _chargOrderPayload?.CardHolderLastName,//agreementData?.Customers?.Customer[0]?.LastName,
                CompanyName: "",
                IdentificationType: "NATIONALID",
                IdentificationNumber: agreementData?.IdentificationNumber,
              },
            },
            Address: userAddress,
            ContactDetails: {
              phone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
              workPhone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
              cellPhone: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
              email: account?.email,
            },
            AutoCompleteId: ChargeOrder?.AutoCompleteId,
        };
        
        if(isBraintree && optn !== PaymentScript?.Payment?.codOption){
          ProcessPaymentParameters.BrainTreeDetails = {
            IsBrainTree: true,
            Amount: totalSRFee?.toString(),//globalState?.state?.srf.substring(1), //servicefeeDetails?.[0]?.fee,//isOverageFlow ? replacementOption?.OverageAmount?.toString() : workflowState["BraintreeDetails"]?.ServiceFeeResponse?.TotalAmount,
            Maid: getMaid(incType, isBraintree),
            Descriptor: isBraintree?.descriptor,
            ThreeDSecureAuthenticationId: btCreatePaymentMethodData?.threeDSecureAuthenticationId,
            ProductId: btCreatePaymentMethodData?.productId,
            Prepaid: btCreatePaymentMethodData?.prepaid,
            FirstName: paymentMethod === 'GGLP' ? _gpayPaymentData?.paymentMethodData?.info?.billingAddress?.name : paymentMethod === 'APLP' ? _chargOrderPayload?.CardHolderFirstName : _chargOrderPayload?.CardHolderFirstName,
            LastName: paymentMethod === 'GGLP' || paymentMethod === 'APLP' ? '' : _chargOrderPayload?.CardHolderLastName,
            BillingAddress: {
              GivenName: agreementData?.Customers?.Customer[0]?.FirstName,
              Surname: agreementData?.Customers?.Customer[0]?.LastName,
              PhoneNumber: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
              streetAddress: bldgValue || userAddress?.Address1,
              ExtendedAddress: unitNumber || userAddress?.Address2,
              Locality: addressVal || userAddress?.City,
              Region: getCountryCode(currencyCode, 'payment'), // ISO-3166-2 code
              PostalCode: postalCode || userAddress?.PostalCode,
              CountryCodeAlpha2: getCountryCode(currencyCode, 'payment'),
            },
          };
        }
      
        callProcessPaymentApi(
          ProcessPaymentParameters,
          _ServiceOrderId,
          ChargeOrder?.ChargeOrderId,
        );
      };

    const callCreateRepairRequest = async(_payment_method?:any, _chargOrderPayload?:any) => {
        showLoader(true)
        let CreateRepairRequestParams = {        
            InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,        
            CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,        
            ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,        
            ServiceOrderId: _ServiceOrderId,        
            RepairRequestType: isPickup ? 'PUR' : 'WALKIN',
            ServiceProviderId: isWalkIn(srDeliveryType) ? StoreId : undefined ,
            AssetMake: "APPLE",
            Longitude: _locationCoordinates?.lng,//103.840400,
            Lattitude: _locationCoordinates?.lat,//1.428850,
            AppointmentDate: isWalkIn(srDeliveryType)
            ? convertDateToUTC(`${walkingDate} ${walkingTime}`, 'YYYY-MM-DD HH:mm:ssa', initializeData?.TimeZone)
            : globalState?.state?.pickupDate,
        }
        try {
            await axios.post(
                `/swap/claim/api/repairrequest/create`,
                { CreateRepairRequestParams  }, { headers: { ...headers, 'Asurion-interactionlineid': interactionData?.InteractionLine.InteractionLineId }, }
            );
            // console.log('repairrequest repairrequestResponse', repairrequestResponse, ChargeOrder);
            // if(isScreenRepair(incType))
            //     callProcessPayment(ChargeOrder, _payment_method, _chargOrderPayload, optn);
            // else
                callSubmitOrder();
        }
        catch (error) {
            showPaymentMessage(false)
            showLoader(false)
            saveError(error, globalState);
            showErrorPage(navigate)
        }
    }

    const callChargeOrderApi = async (setOpenPopup: any, ChargeOrder: any, paymentMethodResp: any) => {
        const _payment_method = ChargeOrder?.PaymentMethodType
        const _chargOrderPayload = ChargeOrder
        const CreateChargeOrderParameters: any = {
                InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
                ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
                CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
                // AssetCategoryId: null,//fileClaimDetails?.selectedDevice?.AssetCategory?.AssetCategoryId,
                AssetCategoryId: hasMultiDeviceSupport(agreementData?.ClientOffer?.ClientOfferName) ? assetData?.AssetCategory?.AssetCategoryId : undefined,
                AgreementId: agreementData?.AgreementId,
                ClientId: interactionData?.ClientId,
                ClientChannelId: interactionData?.ClientChannelId,
                ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
                AssetMakeId: null,//fileClaimDetails?.selectedDevice?.Make?.MakeId,
                Transactionid: generateUniqueId().replace(/-/g, "")?.toUpperCase(),
                MobileNumber: assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber,
                ChargeOrder: _ServiceOrderId ? ChargeOrder : {
                    PaymentMethodType: "COD",
                    ChargeOrderStatus: "PREAUTH",
                    AddressId: agreementData?.billingAddress?.AddressId,
                    AdditionalChargeAuth: "false",
                    ServiceOrderId: _ServiceOrderId,
                },
                ServiceOrderId: _ServiceOrderId,
                isRecheduleNightDeliveryFlow: globalState?.state?.chargeNightDeliveryFee ? true :false
                // IsRedeliveryFee: isReschedulePickupDelivery ? true : false,
        };

        CreateChargeOrderParameters.IsBrainTree = true

        // console.log('paymentMethodResp nonce', paymentMethodResp, _paymentMethod);

        if(_payment_method === 'GGLP' || _payment_method === 'APLP') {
            CreateChargeOrderParameters.BraintreeDetails = {
                Nonce: paymentMethodResp?.nonce, //'fake-apple-pay-visa-nonce'
            }
        } else {
            CreateChargeOrderParameters.BraintreeDetails = {
                Token: paymentMethodResp?.Token,
                BraintreeTransactionDetailId: paymentMethodResp?.BraintreeTransactionDetailId,
                PayerId: paymentMethodResp?.PayerId,
                UniqueNumberIdentifier: paymentMethodResp?.UniqueNumberIdentifier
            }
        }

        showLoader(true)

        chargeOrderApiBrainTree(
            { CreateChargeOrderParameters },
            _ServiceOrderId,
            interactionData?.InteractionLine?.InteractionLineId
          )
            .then((response) => {
              showLoader(false);
              const ChargeOrder = response?.CreateChargeOrderResults?.ChargeOrder;
              setChargeOrderResponse(ChargeOrder);
              const chargeOrderHold = response?.CreateChargeOrderResults.Holds;
              const isHold = (holds: any) => {
                return holds.some((hold: any) => hold.HoldStatus === 'OPEN');
              };
              if (
                setOpenPopup &&
                chargeOrderHold?.length > 0 &&
                isHold(chargeOrderHold)) {
                setOpenPopup(true);
                showPaymentMessage(false);
                navigate('../reviewhold', { replace: true });
              } else if (isRepair) {
                callCreateRepairRequest(_payment_method, _chargOrderPayload);
              } else {
                callProcessPayment(ChargeOrder, _payment_method, _chargOrderPayload, '');
              }
            })
            .catch((error) => {
              showLoader(false);
              showPaymentMessage(false);
              setPaymentError('Payment failed');
              saveError(error, globalState);
              showErrorPage(navigate);
            });
    };

    // const handleBGError = () => {
    //     return false;
    // };

    const chargeOrderRequest = (
        response: any,
        paymentMethod: any,
        getCCDetails: any,
        paymentMethodResp: any,
        deviceData: any
    ) => {
        
        let ChargeOrder = {
            PaymentMethodType: paymentMethod,
            ChargeOrderStatus: "PREAUTH",
            AddressId: agreementData?.billingAddress?.AddressId,
            AdditionalChargeAuth: "false",
            ChargeOrderCardBrand:
                paymentMethod === "CRE" ? response?.details?.cardType : response?.type,
            ChargeOrderCardType: "CREDIT",
            CardCheckNumber: parseInt("0"),
            CardHolderFirstName: (paymentMethod === "CRE" || paymentMethod === "APLP") ? (response?.details?.cardholderName || response?.details?.firstName) : (response?.details?.cardholderName?.split(" ")[0] || response?.details?.firstName),
            CardHolderLastName: (paymentMethod === "CRE" || paymentMethod === "APLP") ? (response?.details?.lastName || '') : (response?.details?.cardholderName?.split(" ")[1] || response?.details?.lastName),
            OverageAmount: 1, //isOverageFlow ? replacementOption?.OverageAmount : undefined,
            ccDetails: paymentMethod === "CRE" ? getCCDetails() : undefined,
            DeviceData: deviceData?.deviceData,
        };

        callChargeOrderApi(setOpenPopup, ChargeOrder, paymentMethodResp)
    };

    const next = (response: any, paymentMethodResp: any, paymentMethod: any, deviceData: any) => {
        const getCCDetails = () => {
            let ccmask = response?.details?.lastFour;
            const name = response?.details?.cardholderName?.split(" ")[0];
            let cardType = response?.details?.cardType;
            let cvv = "";
            return {
                ccmask,
                name,
                cardType,
                cvv,
            };
        };


        chargeOrderRequest(
            response,
            paymentMethod,
            getCCDetails,
            paymentMethodResp,
            deviceData
        );
    };

    const submit = () => {
        showPaymentMessage(true)
        setPaymentError('')
        let paymentMethod = 'CRE'
        hostedFieldsInstance
            .tokenize()
            .then((tokenizeResponse: any) => {
                if (tokenizeResponse?.binData?.prepaid === "Yes") {
                    //   setBGError(homeplusscript.PREPAID_CARD_ERROR);
                } else if (is3DS) {
                    const verifyCardResponse = {
                        nonce: tokenizeResponse.nonce,
                        bin: tokenizeResponse.details.bin,
                    }
                    setBTTokenizeResponse(verifyCardResponse)
                    return callCreatePaymentMethod(tokenizeResponse, paymentMethod, deviceData);
                } else {
                    setVerifyCardResponse({
                        nonce: tokenizeResponse.nonce,
                        bin: tokenizeResponse.details.bin,
                    })
                    return next(tokenizeResponse, undefined, paymentMethod, deviceData);
                }
            })
            .catch((err: any) => {
                showLoader(false);
                showPaymentMessage(false)
                saveError(err, globalState);
                showErrorPage(navigate)
            });

    };

    const clearPaymentDetails = () => {
        setCheckboxChecked(false)
        globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_METHOD, payload: '' })
        setOpenPayment(false)
        setIsInavlidBldgValue(false);
        setIsInavlidAddress(false);
        setIsInavlidUnitNumber(false);
        setIsInavlidPostalCode(false);

        setBldgValue('');
        setAddress('');
        setUnitNumber('');
        setPostalCode('');
    }

      const callClientToken = () => {
        showLoader(true)
        getClientToken(maid, interactionData?.InteractionLine.InteractionLineId, agreementData?.AgreementId)
            .then((res)=> {
                setClientToken(res?.clientToken)
                showLoader(false)
            })
            .catch((error) => {
                showLoader(false)
                showPaymentMessage(false)
                saveError(error, globalState);
                showErrorPage(navigate)
            })
      }

      useEffect(() => {        
        if(globalState?.state?.BTClientTokenData === '') {
            callClientToken()
        }
      }, [globalState?.state?.BTClientTokenData])

      const chargeOrderCOD = (optn: any) => {
        showLoader(true);
        const ChargeOrder = {
          PaymentMethodType: 'COD',
          IncidentType: incType,
          ChargeOrderStatus: "PREAUTH",
          AddressId: agreementData?.billingAddress?.AddressId,
        };
    
        const CreateChargeOrderParameters: any = {
          InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
          ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
          AgreementId: agreementData?.AgreementId,
          ClientId: interactionData?.ClientId,
          ClientChannelId: interactionData?.ClientChannelId,
          // FulfillmentOption: "RPLCMNT",
          CustomerCaseNumber: processIncData?.CustomerCaseNumber || ResumeFlowData?.CustomerCaseNumber,
          ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
          MobileNumber: assetData?.MobileDeviceNumber,
          ChargeOrder: ChargeOrder,
          ServiceOrderId: _ServiceOrderId,
        };
        chargeOrderApi(
          { CreateChargeOrderParameters },
          _ServiceOrderId,
          interactionData?.InteractionLine?.InteractionLineId
        )
          .then((data: any) => {
            showLoader(false);
            const ChargeOrderResponse = data?.CreateChargeOrderResults?.ChargeOrder;
            setChargeOrderResponse(ChargeOrderResponse);
            const chargeOrderHold = data?.CreateChargeOrderResults.Holds;
            const isHold = (holds: any) => {
                // return holds.some(hold => hold.HoldType === 'SRVAL' && hold.HoldStatus === "OPEN");
                // not allowing to move ahead for any hold
                return holds.some((hold: any) => hold.HoldStatus === "OPEN");
            };

            if (
                setOpenPopup &&
                chargeOrderHold?.length > 0 &&
                isHold(chargeOrderHold)
            ) {
                setOpenPopup(true);
                //changes required for HOLD
                showPaymentMessage(false)
                // if (incType?.toLowerCase() === 'replacement') {
                    navigate('../reviewhold', { replace: true });
                //   } else {
                    // navigate('/claimconfirmation');
                //   }
                // return;
            } else if(isRepair) {
                callCreateRepairRequest(ChargeOrder?.PaymentMethodType, ChargeOrder)
            } else {
                callProcessPayment(ChargeOrderResponse, ChargeOrder?.PaymentMethodType, ChargeOrder, optn);
            }
          })
          .catch((err: any) => {
            showLoader(false);
            setPaymentError('Payment failed');
            saveError(err, globalState);
            showErrorPage(navigate);
          });
      };

      const handleChange = (e: any) => {
        setPaymentError('')
        globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_METHOD, payload: e })
        if (e === PaymentScript?.Payment?.ccOption) {
            showLoader(true)
            return createClient(globalState?.state?.BTClientTokenData || clientToken);
        }
        if (e === PaymentScript?.Payment?.paypalOption) {
            showLoader(true)
            return createPaypal(globalState?.state?.BTClientTokenData || clientToken);
        }
        if (e === PaymentScript?.Payment?.gpayOption) {
            showLoader(true)
            return createGpay(globalState?.state?.BTClientTokenData || clientToken);
        }
        if(e === PaymentScript?.Payment?.applePayOption) {
            showLoader(true)
            return createApplepay(globalState?.state?.BTClientTokenData || clientToken);
        }
        if(e === PaymentScript?.Payment?.codOption) {
            setIsOpen(true) 
            // chargeOrderCOD(e)           
        }        
    };

    const handleYesClick = () => {
        setIsOpen(false);
        chargeOrderCOD(globalState?.state?.selectedPaymentMethod);
    }

    const callUpdateStatusAPI = () => {
        showLoader(true);
        updateStatus(globalState?.state?.updateSalesOrderParams, interactionData?.InteractionLine?.InteractionLineId)
            .then((data: any) => {
                showLoader(false);
                //   callClaimDetailsAPI();
                navigate('/myplansandservicerequests', { replace: true });
                console.log('updateStatus: ', data);
            })
            .catch((error: any) => {
                showLoader(false);
                console.log(`error is ${error}`);
            });
    };

    const callRepairReqScheduleAPI = () => {
        showLoader(true);
        repairRequestSchedule(
            globalState?.state?.repirScheduleParams,
            interactionData?.InteractionLine?.InteractionLineId
        )
            .then((data: any) => {
                showLoader(false);
                callUpdateStatusAPI();
                console.log('callRepairReqScheduleAPI: ', data);
            })
            .catch((error: any) => {
                showLoader(false);
                console.log(`error is ${error}`);
            });
    };

    const callProcessOveragePaymentApi = () => {
        showLoader(true);
        const payloadParams = {
          ProcessOveragePaymentParameters: {
            CustomerDecision: 'NIGHT_DELIVERY_FEE',
            RepairRequestId: R_ServiceRequests?.RepairRequest?.RepairRequestId || generateUniqueId()?.replace(/-/g, "")?.toUpperCase(),
            CustomerCaseId: processIncData?.CustomerCaseId || R_CustomerCaseId,
            ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
            ServiceOrderId: _ServiceOrderId,
            AssetCatalogId: R_ServiceRequests?.EnrolledDevice?.AssetCatalog?.AssetCatalogId,
            UpdatedBy: R_ServiceRequests?.UpdatedBy,
            ServiceOrderLines: {
              ServiceOrderLine: [
                {
                  ServiceOrderLineType: 'PHN',
                  Quantity: 1,
                  AssetCatalogId: R_ServiceRequests?.EnrolledDevice?.AssetCatalog?.AssetCatalogId,
                  VendorItemId: '',
                  Priority: 'MNDTRY',
                  VenderItemType: 'PHN',
                  VendorData: {
                    IsLikeForLike: true,
                    IsSimCard: false,
                    IsInStock: true,
                  },
                },
              ],
            },
            ChargeOrderType: chargeOrderResponse?.ChargeOrderType,
            ReferenceNumber: ResumeFlowData?.CustomerCaseNumber?.toString(),
            TotalAmount: chargeOrderResponse?.TotalAmount,
            TaxAmount: chargeOrderResponse?.TaxAmount
          },
        };
        processOveragePayment(payloadParams, interactionData?.InteractionLine?.InteractionLineId)
          .then((data: any) => {
            showLoader(false);
            callRepairReqScheduleAPI();
            console.log('callProcessOveragePaymentApi: ', data);
          })
          .catch((error: any) => {
            showLoader(false);
            console.log(`error is ${error}`);
          });
    };

    const [isOpenPayment,setOpenPayment] = useState<boolean>(false)

    const PaymentTotal = () => {
        return(
            <Box mt='10px' w={{ base: 'full', lg: '50%' }}>
                <hr />
                <Text my={4} fontWeight={700} fontSize={'18px'}>
                    <TextScript 
                        text={PaymentScript?.Payment?.paymentBreakdown}
                        translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.PaymentBreakdown}`}
                    />
                </Text>
                {!globalState?.state?.chargeNightDeliveryFee &&
                    <Flex mb={3} direction="row" justifyContent="space-between">
                        {(isSwap(incType) || isReplacement(incType) || isMalfunction(incType) || isDeviceRefresh(incType)) &&
                            <Text>
                                <TextScript
                                    text={PaymentScript.Global.SRFLBL}
                                    translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.SRFLBL}`}
                                />
                            </Text>
                        }
                        {(!isSwap(incType) && !isReplacement(incType) && !isMalfunction(incType) && !isDeviceRefresh(incType)) &&
                            <Text>
                                <TextScript
                                    text={PaymentScript.Global.RepairFeeLBL}
                                    translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.RepairFeeLBL}`}
                                />
                            </Text>
                        }
                        <Text>{currencySymbol}{globalState?.state?.srf || R_SRF || Number(LFLSRFFees)?.toFixed(2)}</Text>
                    </Flex>
                }
                {globalState?.state?.nightDeliveryFee &&
                    <Flex mb={3} direction="row" justifyContent="space-between">
                        <Text>
                            <TextScript 
                                text={PaymentScript?.Payment?.nightdelivery}
                                translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.nightdelivery}`}
                            />
                        </Text>
                        <Text>{currencySymbol}{globalState?.state?.nightDeliveryFee || 0}</Text>
                    </Flex>
                }
                { hasDTAddOnAcquired &&
                  <Flex mb={3} direction="row" justifyContent="space-between">
                    <Text>
                        <TextScript 
                            text={PaymentScript?.Payment?.dataTransfer}
                            translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.dataTransfer}`}
                        />
                    </Text>
                    <Text>{currencySymbol}{globalState?.state?.doorStepAddOnData.fee || 0}</Text>
                </Flex>
                }
                <hr style={{
                        border: 'none',
                        borderTop: '2px dotted #adaaaa',
                    }}/>
                <Flex my={2} direction="row" justifyContent="space-between">
                    <Text>
                        <TextScript 
                            text={PaymentScript?.Global?.Subtotal}
                            translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.Subtotal}`}
                        />
                    </Text>
                    <Text>{currencySymbol+totalSRFee}</Text>
                </Flex>
                {/* <Flex mb={2} direction="row" justifyContent="space-between">
                    <Text>Discount (SAVE20)</Text>
                    <Text>${globalState?.state?.nightDeliveryFee || 0}</Text>
                </Flex> */}
                <Flex mb={2} direction="row" justifyContent="space-between">
                    <Text fontWeight={700}>
                        <TextScript 
                            text={PaymentScript?.Global?.Total}
                            translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.Total}`}
                        />
                    </Text>
                    <Text fontWeight={700}>{currencySymbol+totalSRFee}</Text>
                </Flex>
            </Box>
        )
    }

    return (
        <>
        <Flex position='relative' 
              pb={isOpenPayment ? '320px' : '110px'} 
              direction='column' 
            //   justifyContent='center'
              w={{ base: 'full', lg: globalState?.state?.selectedPaymentMethod === '' ? '50%' : '70%' }}
              margin={'auto'}
              >
                {(globalState?.state?.selectedPaymentMethod === '' || globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.codOption) &&
                    // <Text mb={3} fontSize={32} fontWeight={300}>{PaymentScript?.Payment?.paymentOptionHeading}</Text>
                    <Text fontSize="32px" mb={'16px'} textAlign={{ base: 'start', lg: 'center' }}>
                        <TextScript 
                            text={PaymentScript?.Payment?.paymentOptionHeading}
                            translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.PaymentOptionHeading}`}
                        />
                    </Text> 
                }
                {globalState?.state?.selectedPaymentMethod !== '' && globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.ccOption &&
                    <Text fontSize={'2xl'}>
                        <TextScript 
                            text={PaymentScript?.Payment?.paymentHeading}
                            translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.PaymentHeading}`}
                        />
                    </Text>
                }
                <RadioGroup
                    onChange={(e) => {
                        globalState?.dispatch({ type: ActionTypes.SET_PAYMENT_METHOD, payload: '' })
                        handleChange(e);
                    }}
                    onClick={() =>  globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.codOption ? handleChange(globalState?.state?.selectedPaymentMethod): null}
                    value={globalState?.state?.selectedPaymentMethod}
                    // w={{ base: 'full', lg: globalState?.state?.selectedPaymentMethod === '' ? '60%' : '80%' }}
                    // marginX={{ base: '0', lg: '70px' }}
                    alignSelf={globalState?.state?.selectedPaymentMethod === '' ? 'center' : ''}
                >
                {(globalState?.state?.selectedPaymentMethod === '' || globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.codOption) && !globalState?.state?.isMaxis && <PaymentOptions optn={PaymentScript?.Payment?.ccOption} />}
                {globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.ccOption &&
                    <Box mb={5}>
                        <Flex direction={'column'} my={4} w='full'>
                            {/* <Flex> */}
                                {/* <PaymentCCIcon position="absolute" top="12px" left="12px" /> */}
                                <Box id="cardNumber" borderWidth='1px' borderRadius='lg' borderColor={validCardNumber === false ? 'red' : ''} h="50px"  w={{lg:'49%',base:'full'}} padding={2}></Box>
                            {/* </Flex> */}
                            {validCardNumber === false &&
                                <Text fontSize="12px" color="red" mt={1}>
                                    <TextScript 
                                        text={PaymentScript?.Payment?.invalidCCNo}
                                        translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.invalidCCNoMsg}`}
                                    />
                                </Text>
                            }
                        </Flex>
                        <Flex mb={4} w='full'>
                            <Flex direction="column" w='full'>
                                <Box id="expiryDate" borderWidth='1px' borderColor={validExpDate === false ? 'red' : ''} borderRadius='lg' h="50px" p={2}></Box>
                                {validExpDate === false &&
                                    <Text fontSize="12px" color="red" mt={1}>
                                        <TextScript 
                                            text={PaymentScript?.Payment?.invalidExpiryDate}
                                            translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.invalidExpiryDateMsg}`}
                                        />
                                    </Text>
                                }
                            </Flex>
                            <Flex direction="column" w='full' ml={4}>
                                <Box id="cvv" borderWidth='1px' borderColor={validCVV === false ? 'red' : ''} borderRadius='lg' h="50px" p={2}></Box>
                                {validCVV === false &&
                                    <Text fontSize="12px" color="red" mt={1}>
                                        <TextScript 
                                            text={PaymentScript?.Payment?.invalidCVV}
                                            translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.invalidCVVMsg}`}
                                        />
                                    </Text>
                                }
                            </Flex>
                        </Flex>
                        <Flex direction="column">
                            <Box id="cardName" borderWidth='1px' borderRadius='lg' borderColor={validName === false ? 'red' : ''} h="50px" w={'full'} p={2}></Box>
                            {validName === false &&
                                <Text fontSize="12px" color="red" mt={1}>
                                    <TextScript 
                                        text={PaymentScript?.Payment?.invalidCCName}
                                        translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.invalidCCNameMsg}`}
                                    />
                                </Text>
                            }
                        </Flex>

                        <Checkbox
                            mt={3}
                            onChange={() => addressChecked()}
                            isChecked={isCheckboxChecked}
                            display={`${globalState?.state?.isMaxis ? 'none' :'flex'}`}
                            flexDirection='row'
                            alignItems='start'
                            pt='10px'
                        >
                            <Text mt={{ base: '-4px', lg: '-2px' }}>
                                <TextScript 
                                    text={PaymentScript?.Payment?.checkboxBillingAddr}
                                    translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.checkboxBillingAddrMsg}`}
                                />
                            </Text>
                        </Checkbox>
                        {/* {!isCheckboxChecked && */}
                            <>
                               {/* <Flex justifyContent={{ base: "space-between", lg: 'start' }} > */}
                                <Flex direction={'column'} mt={3}>
                                        {/* <Input
                                            size="lg"
                                            type="tel"
                                            mt={3}
                                            h='50px'
                                            borderColor="#e2e8f0"
                                            _placeholder={{ fontSize: '16px' }}
                                            placeholder={PaymentScript?.Payment?.placeholderBldgName}
                                            onChange={(e: any) => handleInpuTBldgLBL(e)}
                                            value={bldgValue}
                                            style={{
                                                border: isValidBldgValue ? '1px solid red' : ''
                                            }}
                                        // isInvalid={!bldgValue}
                                        /> */}
                                        <TextInput
                                                size="lg"
                                                type="tel"
                                                h='50px'
                                                borderColor="#e2e8f0"
                                                _placeholder={{ fontSize: '16px' }}
                                                placeholder={PaymentScript?.Payment?.placeholderBldgName}
                                                onChange={(e: any) => handleInpuTBldgLBL(e)}
                                                value={bldgValue}
                                                style={{
                                                    border: isValidBldgValue ? '1px solid red' : '1px solid #e2e8f0'
                                                }}
                                            // isInvalid={!isInvalidInputUnitNumberValue}
                                            />
                                        {isValidBldgValue &&
                                            <Text fontSize={'12px'} color={'red'}>
                                                <TextScript 
                                                    text={PaymentScript?.Payment?.InvalidInput}
                                                    translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.InvalidInput}`}
                                                />
                                            </Text>
                                        }
                                </Flex>
                                <Flex direction={'column'} mt={2}>
                                    {/* <TextInput
                                        size="lg"
                                        type="tel"
                                        mt={3}
                                        h='50px'
                                        borderColor="#e2e8f0"
                                        _placeholder={{ fontSize: '16px' }}
                                        placeholder={PaymentScript?.Payment?.placeholderAddress}
                                        onChange={(e: any) => handleInputAddress(e)}
                                        value={addressVal}
                                        style={{
                                            border: isValidAddressVal ? '1px solid red' : ''
                                        }}
                                    // isInvalid={!isInvalidInputAddressValue}
                                    /> */}
                                    <TextInput
                                        size="lg"
                                        type="tel"
                                        h='50px'
                                        // borderColor="#e2e8f0"
                                        _placeholder={{ fontSize: '16px' }}
                                        placeholder={PaymentScript?.Payment?.placeholderAddress}
                                        onChange={(e: any) => handleInputAddress(e)}
                                        value={addressVal}
                                        style={{
                                            border: isValidAddressVal ? '1px solid red' : '1px solid #e2e8f0'
                                        }}
                                    // isInvalid={!isInvalidInputUnitNumberValue}
                                    />
                                    {isValidAddressVal &&
                                        <Text fontSize={'12px'} color={'red'}>
                                            <TextScript 
                                                text={PaymentScript?.Payment?.InvalidInput}
                                                translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.InvalidInput}`}
                                            />
                                        </Text>
                                    }
                                </Flex>
                                <Flex direction={'row'} gap={'16px'} mt={3} w={'100%'} justifyContent={'space-between'}>
                                    <Flex direction={'column'} w={'100%'}>
                                        <TextInput
                                            size="lg"
                                            type="tel"
                                            h='50px'
                                            containerWidth='100%'
                                            // borderColor="#e2e8f0"
                                            _placeholder={{ fontSize: '16px' }}
                                            placeholder={PaymentScript?.Payment?.placeholderUnitNumber}
                                            onChange={(e: any) => handleInputUnitNumber(e)}
                                            value={unitNumber}
                                            style={{
                                                border: isValidUnitNumber ? '1px solid red' : '1px solid #e2e8f0',
                                            }}
                                        // isInvalid={!isInvalidInputUnitNumberValue}
                                        />
                                        {isValidUnitNumber &&
                                            <Text fontSize={'12px'} color={'red'}>
                                                <TextScript 
                                                    text={PaymentScript?.Payment?.InvalidInput}
                                                    translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.InvalidInput}`}
                                                />
                                            </Text>
                                        }
                                    </Flex>
                                        
                                {/* </Flex> */}
                               {/* </Flex> */}
                                
                                {/* <Flex mt={3}> */}
                                {/* <Flex justifyContent={{ base: "space-between", lg: 'start' }} mt={3}> */}
                                    {/* <Flex direction={'column'}>
                                        <Input
                                            size="lg"
                                            type="tel"
                                            h='50px'
                                            borderColor="#e2e8f0"
                                            _placeholder={{ fontSize: '16px' }}
                                            placeholder={PaymentScript?.Payment?.placeholderUnitNumber}
                                            mr={4}
                                            onChange={(e: any) => handleInputUnitNumber(e)}
                                            value={unitNumber}
                                            style={{
                                                border: isValidUnitNumber ? '1px solid red' : ''
                                            }}
                                        // isInvalid={!isInvalidInputUnitNumberValue}
                                        />
                                        {isValidUnitNumber &&
                                            <Text fontSize={'12px'} color={'red'}>Invalid input/should not more than 100 characters</Text>
                                        }
                                    </Flex> */}
                                    <Flex direction={'column'} w={'100%'}>
                                        <TextInput
                                            size="lg"
                                            type="tel"
                                            h='50px'
                                            containerWidth='100%'
                                            // borderColor="#e2e8f0"
                                            _placeholder={{ fontSize: '16px' }}
                                            placeholder={PaymentScript?.Payment?.placeholderPostalCode}
                                            onChange={(e: any) => handleInputPostalCode(e)}
                                            value={postalCode}
                                            // isInvalid={!isInvalidInputPostalCodeValue}
                                            maxLength={globalState?.state?.isMaxis ? 5 : 6}
                                            style={{
                                                border: isValidPostalCode ? '1px solid red' : '1px solid #e2e8f0',
                                            }}
                                        />
                                        {isValidPostalCode &&
                                            <Text fontSize={'12px'} color={'red'}>
                                                <TextScript 
                                                    text={PaymentScript?.Payment?.InvalidInputPostalCode}
                                                    translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.InvalidInputPostalCode}`}
                                                />
                                            </Text>
                                        }
                                    </Flex>
                                </Flex>
                            </>
                        {/* } */}
                    </Box>
                }
                {/* {((globalState?.state?.selectedPaymentMethod === '' || globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.codOption) && !isRepair && !isScreenRepair(incType)) && !globalState?.state?.isMaxis && <PaymentOptions optn={PaymentScript?.Payment?.gpayOption} />} */}
                {globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.gpayOption &&
                    <Center my={5}>
                        <Button id='gpay-button' w={{ base: 'full', lg: '40%' }} margin={{ base: '0', lg: 'auto' }} marginTop='40px'></Button>
                    </Center>
                }
                {(globalState?.state?.selectedPaymentMethod === '' || globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.codOption) && !globalState?.state?.isMaxis && <PaymentOptions optn={PaymentScript?.Payment?.paypalOption} />}
                {globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.paypalOption &&
                    <Box my={5} id='btnPaypal' w={{ base: 'full', lg: '40%' }} margin={{ base: '0', lg: 'auto' }} marginTop='40px'></Box>
                }
                {window.ApplePaySession && window.ApplePaySession.supportsVersion(3) && (
                <div>
                    {/* {(globalState?.state?.selectedPaymentMethod === '' && !isRepair && !isScreenRepair(incType)) && !globalState?.state?.isMaxis && <PaymentOptions optn={PaymentScript?.Payment?.applePayOption} />} */}
                    {globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.applePayOption && (
                    <Center my={5} w={{ base: 'full', lg: '40%' }} margin={{ base: '0', lg: 'auto' }} marginTop='40px'>
                        <div className="apple-pay-button apple-pay-button-black" id="appleButton"></div>
                    </Center>
                    )}
                    {/* <Flex direction="column">
                        <Center mt={5}>Apple Pay Response</Center>
                        <Center mt={5} margin={5}>
                            <Text>{applePayResp}</Text>
                        </Center>
                    </Flex> */}
                </div>
                )}
                {globalState?.state?.isMaxis && (globalState?.state?.selectedPaymentMethod === '' || globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.codOption) && <PaymentOptions optn={PaymentScript?.Payment?.codOption} />}
                {globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.codOption && 
                <PaymentOptionPopup onYesClick={() => handleYesClick()} openPopup={isOpen} closePopup={() => setIsOpen(false)}/>
                }
            </RadioGroup>
            <Center>
                <Text color={'red'}>
                    {globalState?.state?.paymentError}
                </Text>
            </Center>
        </Flex>
        <Box mt={3} py={4} position='fixed' bottom='0' w={'100%'} bg={'#fff'} style={{ boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.08)', transform: 'translate(-50%, 0%)'}} left={'50%'}>
            <Flex direction="column" w={{ base: '100%', lg: '69%'}} paddingX={'10px'} marginX={{base: '0', lg: 'auto'}}>
                <Flex direction='row' justifyContent="space-between">
                    {globalState?.state?.selectedPaymentMethod !== '' &&
                        <Box>
                            <Box display={'block'}>
                                <Text fontWeight={700} fontSize={'14px'}>
                                    <TextScript 
                                        text={`${PaymentScript?.Payment?.totalFee} ${PaymentScript?.Payment?.incGST}`}
                                        translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.TotalFeeincGST}`}
                                    />
                                </Text>
                            </Box>
                            <Flex>
                                <Text fontSize={'24px'} fontWeight={700}>{currencySymbol+totalSRFee}</Text>
                            
                                {globalState?.state?.selectedPaymentMethod !== '' &&
                                    <Link marginY={'auto'} marginLeft={'10px'} fontSize={'14px'} color={bodyTextColor} textDecoration={"underline"} onClick={() => setOpenPayment(!isOpenPayment)}>
                                        {isOpenPayment && 
                                            <Text>
                                                <TextScript 
                                                    text={PaymentScript?.Payment?.lblBreakdown}
                                                    translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.breakdown}`}
                                                    dynamicValues={[PaymentScript?.Payment?.closeBreakdown]}
                                                />
                                            </Text>
                                        }
                                        {!isOpenPayment && 
                                            <Text>
                                                <TextScript 
                                                    text={PaymentScript?.Payment?.lblBreakdown}
                                                    translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.breakdown}`}
                                                    dynamicValues={[PaymentScript?.Payment?.viewBreakdown]}
                                                />
                                            </Text>
                                        }
                                    </Link>
                                }
                            </Flex>
                        </Box>
                    }
                    
                    {globalState?.state?.selectedPaymentMethod === PaymentScript?.Payment?.ccOption && 
                            <Button
                                variant="outline"
                                h={'auto'}
                                paddingX={'30px'}
                                _hover={{ bg: 'green.400', color: 'white' }}
                                onClick={() => enableButton ? submit() : undefined}
                                style={{
                                    cursor: enableButton
                                        ? ''
                                        : 'not-allowed',
                                    background: enableButton
                                        ? primary
                                        : 'gray',
                                    color: enableButton
                                        ? 'white'
                                        : 'lightGray'
                                }}
                            >
                                <TextScript 
                                    text={PaymentScript?.Global?.Submit}
                                    translationKey={`${PaymentKeys.Global.PageName}/${PaymentKeys.Global.Submit}`}
                                />
                            </Button>
                        }
                </Flex>
                {/* <Flex direction='row' justifyContent="space-between" my={4}>
                    {globalState?.state?.selectedPaymentMethod !== '' &&
                        <Link color="#1ED760" onClick={() => setOpenPayment(!isOpenPayment)}>
                            {!isOpenPayment ? PaymentScript?.Payment?.viewBreakdown : PaymentScript?.Payment?.closeBreakdown}
                        </Link>
                    }
                    {globalState?.state?.selectedPaymentMethod !== '' &&
                        <Link color="#1ED760" onClick={() => clearPaymentDetails()}>
                            {PaymentScript?.Payment?.changeMethod}
                        </Link>
                    }
                </Flex> */}
                {isOpenPayment &&
                    <PaymentTotal />
                }
            </Flex>
        </Box>
    </>
    )
}

export default Payment;