import MainStackLayout from '../../layout/MainStackLayout';
import { ResumeFlowDataKey, assetDataKey, fmipEligibilityKey, processIncidentKey } from '../../modules/config/constants';
import { fmipCheckEligibility } from '../../services/api';
import { SubHeader, useSessionStorage,TextScript, useConfig } from '@MGPD/myasurion-shared';
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import translationsKeys from '../../Scripts/translationsKeys.json'
import { isInWarranty, saveError, showErrorPage } from '../../utils/utills';

import Scripts from '../../Scripts/asurion-script.json';
import ActionTypes from '../../store/actionTypes';
import { useCentralStore } from '../../store/appContext';
import CancelSrPopup from '../myclaims/CancelSrPopup';
import { showFMIPScript } from '../../utils/featuresUtills';
// import features from '../../utils/features.json';

function UnableToTurnOffFmip() {
  const globalState = useCentralStore();
  const FmipUnablePageKeys = translationsKeys.FmipUnablePage
  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
  const R_IMEI = R_ServiceRequests?.EnrolledDevice?.IMEI;

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };
  let [count, setCount] = useState(0);

  const [openCancelPopup, setOpenCancelPopup] = useState(false);

  const [, setFmipResponse] = useSessionStorage<any>(fmipEligibilityKey, null);
  const [assetData] = useSessionStorage<any>(assetDataKey, null);
  const [processIncidentData] = useSessionStorage<any>(processIncidentKey, null);

  const imei = (assetData && assetData?.IMEI) || R_IMEI;
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const agreementData1: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  
  // const incidentTypeFromState =
  // globalState?.state?.IncidentType?.toUpperCase() === 'REFRESH'
  //   ? 'Device Refresh'
  //   : globalState?.state?.IncidentType?.toUpperCase();

  const IsWarranty = isInWarranty(processIncidentData);
 
  // const IncidentType =
  //   processIncidentData?.ServiceRequest?.IncidentType ||
  //   R_ServiceRequests?.IncidentType ||
  //   incidentTypeFromState;

  const { refetch: fmipCheckOn } = useQuery(
    ['fmipCheckEligibility'],
    async () => {
      setLoading(true);      
      return await fmipCheckEligibility(imei, interactionData?.InteractionLine.InteractionLineId, agreementData1?.AgreementId);
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        setFmipResponse(data);
        setLoading(false);
        console.log(count, 'Count');
        //START: added to check and navigation for isWarranty        
        if (IsWarranty && (data.fmip === false || count > 1)) {
          navigate('/deliveryoptions', { replace: true });
        } else if (data.fmip === false || count > 1) {
          navigate('/terms', { replace: true });
        } else {
          navigate('/unabletoturnofffmip');
        }
        //end

        // OLD Code for navigation
        // if (data.fmip === false || count > 1) {
        //   navigate('/terms', { replace: true });
        // } else {
        //   navigate('/unabletoturnofffmip', { replace: true });
        // }
      },
      onError: (error: any) => {
        setLoading(false);
        console.log('Fmip error ===> ', error);
        saveError(error, globalState);
        showErrorPage(navigate);
      },
    }
  );

  const retry = () => {
    setCount((count += 1));
    return fmipCheckOn();
  };
  const navigate = useNavigate();
  const fmipinstructions = () => {
    navigate('/fmipinstructions', { replace: true });
  };

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  };

  const onBackClick = () => {
    window.history.back();
  };

  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary, accent } = colors;

  return (
    <Box id={'myasurion-profile-dashboard'} paddingTop={'54px'}>
      <MainStackLayout gap={0} spacing={0}>
        <Flex
          id={'myasurion-profile-avatar'}
          direction="row"
          justifyContent="space-between"
          h={'auto'}
          marginX={{ base: '0', lg: '40' }}
          marginBottom={{ base: '0', lg: '5' }}
          marginTop={'16px'}
        >
          <SubHeader
            backText={'Back'}
            onClick={onBackClick}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={true}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}
          ></SubHeader>
        </Flex>
        <Box
          id={'myasurion-profile-avatar'}
          w={'full'}
          h={'auto'}
          p={'0px 16px'}
          maxWidth={{ base: 'full', lg: '45%' }}
          marginX={'auto'}
        >
          <Text fontSize={'24px'} font-weight={'300'} line-height={'31.68px'} mb="10px">
          <TextScript
                  text={Scripts.FMIP.HeaderText}
                  translationKey={`${FmipUnablePageKeys.PageName}/${FmipUnablePageKeys.FindMyIphoneText}`}/>
          </Text>
          <Text mb="24px">
          <TextScript
                  text={Scripts.FMIP.InfoText}
                  translationKey={`${FmipUnablePageKeys.PageName}/${FmipUnablePageKeys.InfoText}`}/>
          </Text>
          {showFMIPScript(agreementData1?.ClientOffer?.ClientOfferName) && count == 1 &&<Text mb="24px">
          <TextScript
                  text={Scripts.FMIP.InfoText2}
                  translationKey={`${FmipUnablePageKeys.PageName}/${FmipUnablePageKeys.InfoText2}`}/>
          </Text>}
          <Stack minWidth="max-content" alignItems="center" gap="2">
            <Button
                variant={'outline'}
                width={{ base: 'full', lg: '45%' }}
                borderColor={primary}
                colorScheme={'accent'}
                color={accent}
                height="40px"
                onClick={retry}
                cursor='pointer'
                display={{ base: 'block', lg: 'none' }}
              >
                <TextScript
                  text={`Retry`}
                  translationKey={`${FmipUnablePageKeys.PageName}/${FmipUnablePageKeys.RetryBtnLbl}`}/>
              </Button>
            <Button
              colorScheme={'accent'}
              color={'accent'}
              variant="link"
              height="40px"
              as={'u'}
              onClick={fmipinstructions}
              cursor="pointer"
            >
              <TextScript
                  text={`How to turn off Find my iPhone`}
                  translationKey={`${FmipUnablePageKeys.PageName}/${FmipUnablePageKeys.HowToTurnOffBtnLbl}`}/>
            </Button>
            {/* <Button
                colorScheme={'accent'}
                color={'accent'}
                variant="link"
                height="40px"
                as={'u'}
                cursor='pointer'
                onClick={() => navigate('/fmip')}
                display={{ base: 'block', lg: 'none' }}
              >
                Cancel
              </Button> */}
          </Stack>
        </Box>
        <Box position="fixed" bottom={0} w="full" display={{ base: 'none', lg: 'block' }}>
          <hr
            style={{
              border: 'none',
              borderTop: '2px solid #adaaaa',
            }}
          />
          <Flex
            minWidth="max-content"
            alignItems="center"
            gap="2"
            direction="row"
            py={5}
            justifyContent="center"
          >
            {/* <Button
                colorScheme={primary}
                color={primary}
                variant="link"
                height="40px"
                as={'u'}
                cursor='pointer'
                onClick={() => navigate('/fmip')}
              >
                Cancel
              </Button> */}
              <Button
                variant={'outline'}
                colorScheme={primary}
                color={primary}
                width={{ base: 'full', lg: '10%' }}
                height="40px"
                onClick={retry}
                cursor='pointer'
              >
              <TextScript
                  text={`Retry`}
                  translationKey={`${FmipUnablePageKeys.PageName}/${FmipUnablePageKeys.RetryBtnLbl}`}/>
              </Button>
              <Box></Box>
            </Flex>
          </Box>
      </MainStackLayout>
      <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
    </Box>
  );
}

export default UnableToTurnOffFmip;
